import { createApi } from '@reduxjs/toolkit/query/react';
import { DeletePhotoData, InfoOrdersData, UploadPhotoData } from './types';
import { IImportantInfo } from '../../../components/modules/ScriptPage/ScriptBlock/types';
import { scriptUpdatesApi } from '../scriptUpdates/scriptUpdatesApi';
import { configBaseQuery } from '../config';
import { loggingApi } from '../logging/loggingApi';

export const importantInfoApi = createApi({
  reducerPath: 'importantInfoApi',
  tagTypes: ['ImportantInfo'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getImportantInfo: build.query<{ status: number; data: IImportantInfo[] }, number>({
      query: (script_id) => `/api/script/${script_id}/importants`,
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'ImportantInfo', id })) : []
    }),
    addImportantInfo: build.mutation<
      { status: number; data: IImportantInfo },
      Omit<IImportantInfo, 'id' | 'order_id'>
    >({
      query: (body) => ({
        url: '/api/important/add',
        method: 'POST',
        body
      }),
      invalidatesTags: ['ImportantInfo'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(loggingApi.util.invalidateTags(['Logging']));
      }
    }),
    editImportantInfo: build.mutation<
      { status: number; data: IImportantInfo },
      Omit<IImportantInfo, 'photos' | 'photos_in_text'>
    >({
      query: (body) => ({
        url: `/api/important/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['ImportantInfo'],
      onCacheEntryAdded: async (_, { dispatch, cacheDataLoaded }) => {
        (await cacheDataLoaded).data.status === 200 &&
          dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
        dispatch(loggingApi.util.invalidateTags(['Logging']));
      }
    }),
    deleteImportantInfo: build.mutation<{ status: number; message: string }, number>({
      query: (id) => ({
        url: `/api/important/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ImportantInfo'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(loggingApi.util.invalidateTags(['Logging']));
      }
    }),
    addImportantInfoPhoto: build.mutation<
      { status: number; data: IImportantInfo },
      UploadPhotoData
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('id', data.data.id.toString());
        formData.append('script_id', data.data.script_id.toString());
        data.files.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `api/important/${data.data.id}/add_photos`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['ImportantInfo'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    deleteImportantInfoPhoto: build.mutation<{ status: number; message: string }, DeletePhotoData>({
      query: (data) => ({
        url: `/api/important/${data.data.id}/remove_photo`,
        method: 'POST',
        body: {
          script_id: data.data.script_id,
          photo_id: data.photo_id
        }
      }),
      invalidatesTags: ['ImportantInfo'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    addPhotoToNewInfoInEditor: build.mutation<
      { status: number; data: { url: string }[] },
      { script_id: number; photos: Blob[] }
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('script_id', data.script_id.toString());
        data.photos.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/important/add_photos_in_editor`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['ImportantInfo']
    }),
    addPhotoToExistingInfoInEditor: build.mutation<
      { status: number; data: { url: string }[] },
      UploadPhotoData
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('id', data.data.id.toString());
        formData.append('script_id', data.data.script_id.toString());
        data.files.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/important/${data.data.id}/add_photos_in_editor`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['ImportantInfo'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    changeInfoOrders: build.mutation<
      { status: number; data: IImportantInfo[] },
      { orders: InfoOrdersData[]; script_id: number }
    >({
      query: (data) => {
        return {
          url: `/api/important/orders`,
          method: 'POST',
          body: {
            order: data.orders,
            script_id: data.script_id
          }
        };
      },
      async onQueryStarted(response, { dispatch, queryFulfilled }) {
        const { data: updatedCrossSales } = await queryFulfilled;
        dispatch(
          importantInfoApi.util.updateQueryData('getImportantInfo', response.script_id, (draft) => {
            return {
              ...draft,
              data: draft.data.map((item) => {
                const updatedItem = updatedCrossSales.data.find((ucItem) => ucItem.id === item.id);
                return updatedItem ? { ...item, order_id: updatedItem.order_id } : item;
              })
            };
          })
        );
      }
    })
  })
});
