import { createApi } from '@reduxjs/toolkit/query/react';
import { IUser, IUserFull } from '../../../components/modules/AccountsPage/AccountsItems/types';
import { AdminInactivateUserData, ChangeLangData, UploadPhotoData } from './types';
import { configBaseQuery } from '../config';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['Users', 'User', 'DaysCount'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getUsers: build.query<{ status: number; users: IUser[] }, void>({
      query: () => '/api/users'
    }),
    getUsersWithProjectsCount: build.query<{ status: number; users: IUser[] }, void>({
      query: () => '/api/users/project-count',
      providesTags: (result) =>
        result ? result.users.map(({ id }) => ({ type: 'Users', id })) : []
    }),
    getUser: build.query<{ status: number; user: IUser }, number | undefined>({
      query: (id) => `/api/user/${id}`
    }),
    getFullUser: build.query<{ status: number; user: IUserFull }, number | undefined>({
      query: (id) => `/api/user/${id}/full`,
      providesTags: (result) => (result ? [{ type: 'User', id: result.user.id }] : [])
    }),
    logoutUser: build.mutation<{ status: number }, number>({
      query: (id) => ({
        url: 'api/logout-user',
        method: 'POST',
        body: {
          id
        }
      }),
      invalidatesTags: ['Users']
    }),
    addUser: build.mutation<
      { status: number; user: IUser },
      Omit<IUser, 'id' | 'projects_count' | 'lang' | 'status'>
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('login', data.login);
        formData.append('password', data.password);
        formData.append('role', data.role.toString());
        data.first_name && formData.append('first_name', data.first_name);
        data.last_name && formData.append('last_name', data.last_name);
        data.middle_name && formData.append('middle_name', data.middle_name);
        data.photo && formData.append('photo', data.photo);

        return {
          url: 'api/user/add',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Users']
    }),
    editUser: build.mutation<
      { status: number; user: IUser },
      Omit<IUser, 'projects_count' | 'lang'>
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('id', data.id.toString());
        formData.append('login', data.login);
        formData.append('password', data.password);
        formData.append('role', data.role.toString());
        data.first_name && formData.append('first_name', data.first_name);
        data.last_name && formData.append('last_name', data.last_name);
        data.middle_name && formData.append('middle_name', data.middle_name);
        data.photo && data.photo instanceof Blob && formData.append('photo', data.photo);

        return {
          url: `api/user/${data.id}/edit`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Users', 'User']
    }),
    deleteUser: build.mutation<{ status: number }, number>({
      query: (id) => ({
        url: `/api/user/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Users']
    }),
    uploadUserPhoto: build.mutation<{ status: number }, UploadPhotoData>({
      query: (data) => {
        const formData = new FormData();
        formData.append('photo', data.photo);
        return {
          url: `/api/user/${data.id}`,
          method: 'PUT',
          formData
        };
      },
      invalidatesTags: ['Users']
    }),
    deleteMac: build.mutation<{ status: number }, number>({
      query: (id) => ({
        url: `/api/user/${id}/mac`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Users', 'User']
    }),
    changeLang: build.mutation<{ status: number }, ChangeLangData>({
      query: (data) => ({
        url: `/api/user/${data.id}/language`,
        method: 'POST',
        body: {
          lang: data.lang
        }
      })
    }),
    activateUser: build.mutation<{ status: number }, number>({
      query: (id) => ({
        url: `/api/user/${id}/activated`,
        method: 'POST'
      }),
      invalidatesTags: ['Users']
    }),
    inactivateUser: build.mutation<{ status: number }, number>({
      query: (id) => ({
        url: `/api/user/${id}/inactivated`,
        method: 'POST'
      }),
      invalidatesTags: ['Users']
    }),
    adminInactivateUser: build.mutation<{ status: number }, AdminInactivateUserData>({
      query: (data) => ({
        url: `/api/user/${data.id}/admin-block`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Users']
    }),
    getMaxDays: build.query<{ data: { days: number } }, void>({
      query: () => '/api/settings/show-max-days',
      providesTags: (result) => (result ? ['DaysCount'] : [])
    }),
    changeMaxDays: build.mutation<{ status: number }, number>({
      query: (days) => ({
        url: '/api/settings/changes-max-days',
        method: 'POST',
        body: { days }
      }),
      invalidatesTags: ['DaysCount']
    })
  })
});
