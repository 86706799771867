import { TFunction } from 'i18next';
import { RouteNames } from './path';

export const projectTabs = (t: TFunction<string[], undefined, string[]>, project_id: number) => {
  return [
    { title: t('scriptsPage.scripts'), link: `${RouteNames.SCRIPTS}?project_id=${project_id}` },
    {
      title: t('crossSalesPage.crossSales'),
      link: `${RouteNames.CROSS_SALES}?project_id=${project_id}`
    },
    {
      title: t('allFields'),
      link: `${RouteNames.UNIQUE_FIELDS}?project_id=${project_id}`
    }
  ];
};
