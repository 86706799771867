import * as yup from 'yup';
import { Schema } from 'yup';
import { LoginFormValues } from '../components/modules/LoginPage/LoginForm/types';
import { AddScriptValues } from '../components/modules/CreateScriptPage/AddScriptForm/types';

function defineFormSchema<T>(fields: Record<keyof T, Schema>) {
  return yup.object().shape(fields);
}

export const requireSchema = (names: string[]) => {
	let schema = {};
	names.map((e: string) => {
		schema = { ...schema, [e]: yup.string().required().notOneOf(['<p></p>\n'])}
	})
	return defineFormSchema(schema);
}

export const loginValidate = defineFormSchema<Omit<LoginFormValues, 'rememberMe'>>({
	login: yup.string().required(),
	password: yup.string().required().min(6),
})

export const addScriptValidate = defineFormSchema<AddScriptValues>({
	name: yup.string().required(),
  price: yup.string().required(),
  articul: yup.string(),
  internal_id: yup.string().required(),
})

export const addCrossSaleValidate = defineFormSchema({
	name: yup.string().required(),
  new_price: yup.string().required(),
  old_price: yup.string(),
  internal_id: yup.string().required(),
  description: yup.string(),
})