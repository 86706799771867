import React, { useState } from 'react';
import { Input } from '../../../ui/Inputs';
import s from './DaysBlocking.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../ui/Buttons';
import { DaysBlockingProps } from './types';
import { useAdminInactivateUserMutation, useInactivateUserMutation } from '../../../../store/api';
import cn from 'classnames';

const DaysBlocking: React.FC<DaysBlockingProps> = ({ userId, onClose }) => {
  const { t } = useTranslation();
  const [inactivateUser] = useInactivateUserMutation();
  const [adminInactivateUser] = useAdminInactivateUserMutation();
  const [daysCount, setDaysCount] = useState(7);
  const [foreverBlocking, setForeverBlocking] = useState(false);

  const handleChangeDaysCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value.replace(/[^0-9]/g, '');
    setDaysCount(value);
  };

  const handleChangeForeverBlocking = () => {
    setForeverBlocking((prev) => !prev);
  };

  const handleBlockAccount = () => {
    if (foreverBlocking) {
      inactivateUser(userId).then(() => onClose());
    } else {
      adminInactivateUser({ id: userId, days: daysCount === 0 ? null : daysCount }).then(() =>
        onClose()
      );
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.body}>
        <div className={s.block}>
          <p className={s.title}>{t('accountsPage.daysBlocking')}</p>
          <Input
            name="days-blocking"
            value={daysCount}
            onChange={handleChangeDaysCount}
            className={s.input}
            maxLength={3}
          />
        </div>
        <div className={s.block}>
          <p className={s.title}>{t('accountsPage.forever')}</p>
          <div className={s.checkbox__wrapper}>
            <input
              id="foreverBlocking"
              name="foreverBlocking"
              type="checkbox"
              checked={foreverBlocking}
              onChange={handleChangeForeverBlocking}
              className={cn(s.checkbox, { [s.isChecked]: foreverBlocking })}
            />
            <label htmlFor="foreverBlocking" className={s.checkbox__label}>
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <div className={s.buttons}>
        <Button onClick={handleBlockAccount} red>
          {t('buttons.yes')}
        </Button>
        <Button onClick={onClose}>{t('buttons.no')}</Button>
      </div>
    </div>
  );
};

export default DaysBlocking;
