import React from 'react';
import { Icon } from './types';

const SliderArrowIcon: React.FC<Icon> = ({ className, path }) => {
  return (
    <svg
      className={className ?? ''}
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 13L6.59317 8.47222C7.13561 7.9375 7.13561 7.0625 6.59317 6.52778L2 2"
        stroke="#2882B5"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...path}
      />
    </svg>
  );
};

export default SliderArrowIcon;
