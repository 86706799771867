import React, { useState } from 'react';
import s from './CopyScriptModal.module.scss';
import { Modal } from '../../../../ui/Modals';
import { CopyScriptModalProps } from './types';
import { useGetProjectsQuery } from '../../../../../store/api';
import { Select } from '../../../../ui/Select';
import { SingleValue } from 'react-select';
import { ISelectOption } from '../../../../ui/Select/DefaultSelect/types';
import { Button } from '../../../../ui/Buttons';
import { useTranslation } from 'react-i18next';

const CopyScriptModal: React.FC<CopyScriptModalProps> = ({ open, onClose, existingProjectId, onCopy }) => {
	const { t } = useTranslation();
  const { data: projects } = useGetProjectsQuery();
  const options = projects?.data.filter(project => project.id !== existingProjectId).map((project) => ({ label: project.name, value: project.id }));
  const [project, setProject] = useState<ISelectOption | null>(null);

  const handleChangeProject = (newProject: SingleValue<ISelectOption>) => {
    setProject(newProject);
  };

	const handleCopy = () => {
		project && onCopy(project?.value);
		onClose();
	}

  return (
    <Modal title={t('scriptsPage.copyingToOtherProject')} open={open} onClose={onClose}>
      {options && (
        <div className={s.select}>
          <Select
            name="projects"
            options={options}
            value={project}
            onChange={handleChangeProject}
						placeholder={`${t('scriptsPage.chooseProject')}`}
          />
        </div>
      )}
			<div className={s.btn}>
      	<Button onClick={handleCopy} >{t('buttons.copy')}</Button>
			</div>
    </Modal>
  );
};

export default CopyScriptModal;
