import { createApi } from '@reduxjs/toolkit/query/react';
import { configBaseQuery } from '../config';
import {
  IDetailedLoggingData,
  ILoggingData,
  ILoggingPagination
} from '../../../components/modules/DataLoggingPage/LoggingTable/types';
import { GetLoggingData } from './types';

export const loggingApi = createApi({
  reducerPath: 'loggingApi',
  tagTypes: ['Logging'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getAllLogging: build.query<
      { status: number; data: ILoggingData[]; pagination: ILoggingPagination },
      GetLoggingData
    >({
      query: (data) => ({
        url: `/api/changes-logs?project_id=${data.project_id}${
          data.script_id ? `&script_id=${data.script_id}` : ''
        }${data.query ? `&query=${data.query}` : ''}${
          data.change_type ? `&change_type=${data.change_type}` : ''
        }${data.entity_type ? `&entity_type=${data.entity_type}` : ''}${
          data.date_from ? `&date_from=${data.date_from}` : ''
        }${data.date_to ? `&date_to=${data.date_to}` : ''}${data.page ? `&page=${data.page}` : ''}${
          data.per_page ? `&per_page=${data.per_page}` : ''
        }${data.sort_script_name ? `&sort_script_name=${data.sort_script_name}` : ''}${
          data.sort_date ? `&sort_date=${data.sort_date}` : ''
        }`
      }),
      providesTags: (result) =>
        result ? result.data.map(({ change_id }) => ({ type: 'Logging', change_id })) : []
    }),
    getDetailedLogging: build.query<{ status: number; data: IDetailedLoggingData }, number>({
      query: (updata_id) => `/api/changes-log/${updata_id}`
    })
  })
});
