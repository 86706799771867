import React, { useEffect, useState } from 'react';
import { Button } from '../../../ui/Buttons';
import { useFormik } from 'formik';
import { AddObjectionFormProps, AddObjectionValues } from './types';
import { requireSchema } from '../../../../utils/validate';
import s from './AddObjectionForm.module.scss';
import { Input, TextArea } from '../../../ui/Inputs';
import { LanguageSelect } from '../../../ui/Select';
import { useTranslation } from 'react-i18next';
import { Languages } from '../../../../constants';

const AddObjectionForm: React.FC<AddObjectionFormProps> = ({
  addObjection,
  editObjection,
  onClose,
  editedObjection
}) => {
  const { t } = useTranslation();
  const [isAddRuLang, setIsAddRuLang] = useState(false);

  const handleSetIsAddRuLang = () => {
    setIsAddRuLang(true);
  }

  const formik = useFormik({
    initialValues: {
      title_ua: '',
      text_ua: '',
      title_ru: '',
      text_ru: '',
    },
    validationSchema: requireSchema(['title_ua', 'text_ua']),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: AddObjectionValues) => {
      if (editedObjection) {
        if(values.title_ru && values.text_ru) {
          editObjection({ ...editedObjection, content: [{title: values.title_ua, text: values.text_ua, lang: 'ua'}, {title: values.title_ru, text: values.text_ru, lang: 'ru'}]});
        } else {
          editObjection({ ...editedObjection, content: [{title: values.title_ua, text: values.text_ua, lang: 'ua'}]});
        }
      } else {
        if(values.title_ru && values.text_ru) {
          addObjection([{title: values.title_ua, text: values.text_ua, lang: Languages.UA.toLowerCase()}, {title: values.title_ru, text: values.text_ru, lang: Languages.RU.toLowerCase()}]);
        } else {
          addObjection([{title: values.title_ua, text: values.text_ua, lang: Languages.UA.toLowerCase()}]);
        }
      }
      onClose();
    }
  });

  useEffect(() => {
    if (editedObjection) {
      formik.setValues({
        title_ua: editedObjection.content[0].title ? editedObjection.content[0].title : '',
        text_ua: editedObjection.content[0].text ? editedObjection.content[0].text : '',
        title_ru: editedObjection.content[1]?.title ? editedObjection.content[1].title : '',
        text_ru: editedObjection.content[1]?.text ? editedObjection.content[1].text : ''
      });
      if(editedObjection.content[1]) {
        setIsAddRuLang(true);
      }
    }
  }, [editedObjection]);

  return (
    <form className={s.form} onSubmit={formik.handleSubmit}>
      <div className={s.fields}>
        <div className={s.ua}>
          <div className={s.field}>
            <Input
              name="title_ua"
              error={!!formik.errors.title_ua}
              onChange={formik.handleChange}
              value={formik.values.title_ua}
              placeholder={`${t('objectionPage.header')}`}
              className={s.title}
            />
            <LanguageSelect
              isTextLangSelect
              value={{label: Languages.UA, value: 1}}
              callBack={handleSetIsAddRuLang}
              className={s.field__language}
              disabled={isAddRuLang}
            />
          </div>
          <div className={s.info}>
            <div className={s.field}>
              <TextArea
                name="text_ua"
                value={formik.values.text_ua}
                onChange={formik.handleChange}
                error={!!formik.errors.text_ua}
                placeholder={`${t('description')}`}
              />
            </div>
          </div>
        </div>
        {isAddRuLang && (
          <div className={s.ru}>
            <div className={s.field}>
              <Input
                name="title_ru"
                error={!!formik.errors.title_ru}
                onChange={formik.handleChange}
                value={formik.values.title_ru}
                placeholder={`${t('objectionPage.header')}`}
                className={s.title}
              />
              <LanguageSelect
                isTextLangSelect
                value={{label: Languages.RU, value: 2}}
                callBack={handleSetIsAddRuLang}
                className={s.field__language}
                disabled={isAddRuLang}
              />
            </div>
            <div className={s.info}>
              <div className={s.field}>
                <TextArea
                  name="text_ru"
                  value={formik.values.text_ru}
                  onChange={formik.handleChange}
                  error={!!formik.errors.text_ru}
                  placeholder={`${t('description')}`}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={s.btns}>
        <Button type="submit">{editedObjection ? t('buttons.edit') : t('buttons.save')}</Button>
        <Button onClick={onClose} red>
          {t('buttons.cancel')}
        </Button>
      </div>
    </form>
  );
};

export default AddObjectionForm;
