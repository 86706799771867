import { useState } from 'react';
import {
  ContentState,
  EditorState,
  convertToRaw
} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


export const useEditorState = (value: string, setValue: (newValue: string) => void) => {
	const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = useState(prepareDraft(value));

  const onEditorStateChange = (newEditorState: EditorState) => {
    const newValue = draftToHtml(convertToRaw(newEditorState.getCurrentContent())).replace('\n', '');
    setValue(newValue);
    setEditorState(newEditorState);
  };

	return { editorState, onEditorStateChange, prepareDraft }
}