import React, { useState } from 'react';
import { LanguageSelectProps } from './types';
import { Languages } from '../../../../constants';
import Select from '../DefaultSelect/Select';
import { ISelectOption } from '../DefaultSelect/types';
import { customStyles } from './styles';
import { SingleValue } from 'react-select';
import i18n from '../../../../i18n';

const LanguageSelect: React.FC<LanguageSelectProps> = ({ value, isTextLangSelect, isSiteLangSelect, callBack, disabled, className }) => {
  const options = [
    { label: Languages.UA, value: 1 },
    { label: Languages.RU, value: 2 }
  ];

  const lang = localStorage.getItem('i18nextLng');
  const defaultValue = lang ? options.filter(item => item.label.toLowerCase() === lang)[0] : options[0];

  const selectValue = isTextLangSelect && value ? value : defaultValue;
  
  const [language, setLanguage] = useState<ISelectOption | null>(selectValue);

  const handleChangeLanguage = (language: SingleValue<ISelectOption>) => {
    if(isTextLangSelect && callBack) callBack();
    else {
      setLanguage(language);
      isSiteLangSelect && i18n.changeLanguage(language?.label.toString().toLowerCase());
    }
  };

  return (
    <div className={className ?? ''}>
      <Select
        name="language"
        options={options}
        value={language}
        onChange={handleChangeLanguage}
        styles={customStyles}
        disabled={disabled}
      />
    </div>
  );
};

export default LanguageSelect;
