import { createContext } from 'react';

interface IThemeContext {
  theme: string | null;
  setTheme: (theme: string) => void;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<IThemeContext>({
  theme: null,
  setTheme: () => undefined,
  toggleTheme: () => undefined
});