import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import s from './CrossSaleView.module.scss';
import { Title } from '../../../ui/Title';
import { CrossSaleViewProps, } from './types';
import { AddPhotoButton } from '../../../ui/Buttons';
import { getImage } from '../../../../utils/getImage';
import { useTranslation } from 'react-i18next';

const CrossSaleView: React.FC<CrossSaleViewProps> = ({ name, new_price, old_price, internal_id, photo, emptyPhoto, changePhoto }) => {
  const { t } = useTranslation();
  const [blobUrl, setBlobUrl] = useState('');
  const currencyNew = !new_price?.includes('$') ? " Грн." : "";
  const currencyOld = !old_price?.includes('$') ? " Грн." : "";

  useEffect(() => {
    const url = photo && (photo instanceof Blob) && URL.createObjectURL(photo);
    url && setBlobUrl(url);
    return () => {
      url && URL.revokeObjectURL(url);
    };
  }, [photo]);

  return (
    <div className={s.block}>
			<div className={cn(s.photo, {[s.emptyPhoto]: emptyPhoto})}>
        { photo ? <>
            <img src={blobUrl ? blobUrl : (typeof photo === 'string' ? getImage(photo) : '')} alt={'account-img'} />
            <input key={blobUrl ?? photo} type="file" accept="image/jpeg, image/jpg, image/png, image/webp, image/svg, image/gif" onChange={changePhoto} />
          </> : <AddPhotoButton changePhoto={changePhoto} />
        }  
			</div>
      <div className={s.info}>
        <Title title={name ? name : '-'} variant="h3" className={s.title} />
        <ul className={s.list}>
          <li className={s.list__item}>
            { new_price ? <><span className={s.header}>{t('price')}:</span> <span>{new_price + currencyNew}</span></> : '-'}
          </li>
          {old_price && <li className={s.list__item}>
            <span className={s.header}>{t('crossSalePage.oldPrice')}:</span> <span>{old_price + currencyOld}</span>
          </li>}
          <li className={s.list__item}>
            { internal_id ? <><span className={s.header}>ID:</span> <span>{internal_id}</span></> : '-'}
          </li>
        </ul>
      </div>
    </div>
  );
};

const MemoizedCrossSaleView = React.memo(CrossSaleView);

export default MemoizedCrossSaleView;