import React from 'react';
import cn from 'classnames';
import s from './AddPhotoButton.module.scss';
import { AddPhotoButtonProps } from './types';

const AddPhotoButton: React.FC<AddPhotoButtonProps> = ({ changePhoto, multi = false, inputDisabled = false, className, ...props }) => {
  return (
    <button className={cn(s.button, className)} {...props}>
      <input
        onChange={changePhoto}
        type="file"
        accept="image/jpeg, image/jpg, image/png, image/webp, image/svg, image/gif"
        multiple={multi}
        disabled={inputDisabled}
        className={inputDisabled ? s.inputDisabled : ''}
      />
      <span>+</span>
    </button>
  );
};

export default AddPhotoButton;
