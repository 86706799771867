import React from 'react';
import { Icon } from './types';

const ExclamationIcon: React.FC<Icon> = ({ className }) => {
  return (
    <svg
      className={className ?? ''}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9C0 4.03111 4.03111 0 9 0C13.9689 0 18 4.03111 18 9C18 13.9689 13.9689 18 9 18C4.03111 18 0 13.9689 0 9ZM9 1.38462C4.79581 1.38462 1.38462 4.79581 1.38462 9C1.38462 13.2042 4.79581 16.6154 9 16.6154C13.2042 16.6154 16.6154 13.2042 16.6154 9C16.6154 4.79581 13.2042 1.38462 9 1.38462Z"
        fill="#FF6B6B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99109 4.50004C9.11337 4.49893 9.2346 4.52155 9.3474 4.56651C9.46026 4.6115 9.56229 4.67791 9.64725 4.76168C9.73222 4.84545 9.79833 4.94483 9.84156 5.05374C9.88479 5.16264 9.90423 5.2788 9.89868 5.39511L9.66143 10.1994C9.64483 10.5355 9.35355 10.8 8.99984 10.8C8.64614 10.8 8.35483 10.5356 8.3382 10.1994L8.10053 5.3951C8.09505 5.28018 8.11397 5.16539 8.15614 5.05758C8.19832 4.94977 8.26289 4.85115 8.34601 4.76761C8.42913 4.68407 8.52909 4.61732 8.63993 4.57135C8.7507 4.5254 8.87006 4.50115 8.99088 4.50004"
        fill="#FF6B6B"
      />
      <path
        d="M8.99961 13.5002C8.82161 13.5002 8.6476 13.4474 8.4996 13.3485C8.35159 13.2496 8.23624 13.1091 8.16812 12.9446C8.1 12.7802 8.08218 12.5992 8.1169 12.4246C8.15163 12.25 8.23735 12.0897 8.36321 11.9638C8.48908 11.8379 8.64945 11.7522 8.82403 11.7175C8.99861 11.6828 9.17957 11.7006 9.34402 11.7687C9.50848 11.8368 9.64904 11.9522 9.74793 12.1002C9.84683 12.2482 9.89961 12.4222 9.89961 12.6002C9.89961 12.8389 9.80479 13.0678 9.63601 13.2366C9.46722 13.4054 9.2383 13.5002 8.99961 13.5002Z"
        fill="#FF6B6B"
      />
    </svg>
  );
};

export default ExclamationIcon;
