import React from 'react';
import { useAuth, useRouterConfig } from './hooks';
import { ThemeProvider } from './context/Theme/ThemeProvider';
import { SearchProvider } from './context/Search/SearchProvider';
import { AuthContext } from './context/Auth/AuthContext';

const App: React.FC = () => {
  const router = useRouterConfig();
  const { user, login, logout } = useAuth();

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      <ThemeProvider>
        <SearchProvider>
          <div id="app">{router}</div>
        </SearchProvider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export default App;
