import React from 'react';
import { Icon } from './types';

const LogoIconMini: React.FC<Icon> = ({ className }) => {
  return (
    <svg className={className ?? ''} width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path
          d="M34.1773 68.7189C53.053 68.7189 68.3547 53.4172 68.3547 34.5416C68.3547 15.666 53.053 0.364258 34.1773 0.364258C15.3017 0.364258 0 15.666 0 34.5416C0 53.4172 15.3017 68.7189 34.1773 68.7189Z"
          fill="url(#paint0_radial_7_3539)"
        />
      </g>
      <path
        d="M47.7517 21.6318H19.6553L15.4434 28.56L33.6774 53.6277L51.9821 28.5591L47.7517 21.6318Z"
        fill="url(#paint1_linear_7_3539)"
      />
      <path
        d="M51.9821 28.56H15.4434L19.6553 21.6318H47.7525L51.9821 28.56Z"
        fill="url(#paint2_linear_7_3539)"
      />
      <path
        d="M33.7083 28.56H15.4434L19.6553 21.6318L33.7127 28.56H33.7083Z"
        fill="url(#paint3_linear_7_3539)"
      />
      <path
        d="M40.6848 28.56H51.9599L47.7489 21.6318L33.6914 28.56H40.6848Z"
        fill="url(#paint4_linear_7_3539)"
      />
      <path
        d="M51.9821 28.5674H15.4434L33.6774 53.6343L51.9821 28.5674Z"
        fill="url(#paint5_linear_7_3539)"
      />
      <path
        d="M26.7017 28.5674H15.4434L33.6774 53.6343L26.7017 28.5674Z"
        fill="url(#paint6_linear_7_3539)"
      />
      <path
        d="M40.6848 28.5674H51.9431L33.709 53.6369L40.6848 28.5674Z"
        fill="url(#paint7_linear_7_3539)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_7_3539"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.1773 34.5416) scale(34.1773)">
          <stop stopColor="#0300AA" stopOpacity="0.4" />
          <stop offset="0.39" stopColor="#5478D3" stopOpacity="0.2" />
          <stop offset="0.77" stopColor="#92D3F3" stopOpacity="0.06" />
          <stop offset="1" stopColor="#AAF6FF" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_7_3539"
          x1="33.7101"
          y1="21.6318"
          x2="33.7101"
          y2="53.6295"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AAF6FF" stopOpacity="0.3" />
          <stop offset="0.28" stopColor="#A2EBFB" stopOpacity="0.31" />
          <stop offset="0.5" stopColor="#8CCBF0" stopOpacity="0.32" />
          <stop offset="0.69" stopColor="#6794DD" stopOpacity="0.34" />
          <stop offset="0.87" stopColor="#3347C2" stopOpacity="0.37" />
          <stop offset="1" stopColor="#0300AA" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7_3539"
          x1="33.7101"
          y1="21.6318"
          x2="33.7101"
          y2="28.5679"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AAF6FF" stopOpacity="0.3" />
          <stop offset="0.28" stopColor="#A2EBFB" stopOpacity="0.31" />
          <stop offset="0.5" stopColor="#8CCBF0" stopOpacity="0.32" />
          <stop offset="0.69" stopColor="#6794DD" stopOpacity="0.34" />
          <stop offset="0.87" stopColor="#3347C2" stopOpacity="0.37" />
          <stop offset="1" stopColor="#0300AA" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7_3539"
          x1="19.335"
          y1="76.2644"
          x2="28.4772"
          y2="12.7103"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AAF6FF" stopOpacity="0.3" />
          <stop offset="0.28" stopColor="#A2EBFB" stopOpacity="0.31" />
          <stop offset="0.5" stopColor="#8CCBF0" stopOpacity="0.32" />
          <stop offset="0.69" stopColor="#6794DD" stopOpacity="0.34" />
          <stop offset="0.87" stopColor="#3347C2" stopOpacity="0.37" />
          <stop offset="1" stopColor="#0300AA" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7_3539"
          x1="48.746"
          y1="7.98032"
          x2="38.2625"
          y2="80.9855"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AAF6FF" stopOpacity="0.3" />
          <stop offset="0.28" stopColor="#A2EBFB" stopOpacity="0.31" />
          <stop offset="0.5" stopColor="#8CCBF0" stopOpacity="0.32" />
          <stop offset="0.69" stopColor="#6794DD" stopOpacity="0.34" />
          <stop offset="0.87" stopColor="#3347C2" stopOpacity="0.37" />
          <stop offset="1" stopColor="#0300AA" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_7_3539"
          x1="38.2724"
          y1="-2.3537"
          x2="22.8559"
          y2="54.4673"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AAF6FF" stopOpacity="0.3" />
          <stop offset="0.28" stopColor="#A2EBFB" stopOpacity="0.31" />
          <stop offset="0.5" stopColor="#8CCBF0" stopOpacity="0.32" />
          <stop offset="0.69" stopColor="#6794DD" stopOpacity="0.34" />
          <stop offset="0.87" stopColor="#3347C2" stopOpacity="0.37" />
          <stop offset="1" stopColor="#0300AA" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_7_3539"
          x1="38.4136"
          y1="33.7562"
          x2="21.3205"
          y2="42.8013"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AAF6FF" stopOpacity="0.3" />
          <stop offset="0.28" stopColor="#A2EBFB" stopOpacity="0.31" />
          <stop offset="0.5" stopColor="#8CCBF0" stopOpacity="0.32" />
          <stop offset="0.69" stopColor="#6794DD" stopOpacity="0.34" />
          <stop offset="0.87" stopColor="#3347C2" stopOpacity="0.37" />
          <stop offset="1" stopColor="#0300AA" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_7_3539"
          x1="17.0483"
          y1="49.1197"
          x2="57.9499"
          y2="27.4643"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AAF6FF" stopOpacity="0.3" />
          <stop offset="0.28" stopColor="#A2EBFB" stopOpacity="0.31" />
          <stop offset="0.5" stopColor="#8CCBF0" stopOpacity="0.32" />
          <stop offset="0.69" stopColor="#6794DD" stopOpacity="0.34" />
          <stop offset="0.87" stopColor="#3347C2" stopOpacity="0.37" />
          <stop offset="1" stopColor="#0300AA" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoIconMini;
