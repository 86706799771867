import React from 'react';
import s from './styles.module.scss';
import { Title } from '../../../ui/Title';
import cn from 'classnames';
import { CheckIcon } from '../../../ui/Icons';
import { useTranslation } from 'react-i18next';
import { getImage } from '../../../../utils/getImage';
import defaultPhoto from '../../../../assets/images/default.jpg';
import { CrossSaleBlockProps } from './types';

const CrossSaleBlock: React.FC<CrossSaleBlockProps> = ({cross_sale, onClick, className}) => {
	const { t } = useTranslation();
	const { id, name, new_price, photo } = cross_sale;
	const currency = !new_price?.includes('$') ? " Грн." : "";

	const hanldeClick = () =>{
		onClick && onClick(id)
	}

  return <div className={cn(s.block, className)} onClick={hanldeClick}>
		<div className={s.wrapper}>
			<div className={s.selectedBg}>
				<CheckIcon />
			</div>
			<img src={typeof photo === 'string' ? getImage(photo) : defaultPhoto} alt="cross-sale-img" />
		</div>
		<div className={s.info}>
			<div className={s.name__wrapper}>
				<Title title={name} variant='h3' className={s.name} />
				{name.length > 36 && <div className={s.fullName}>{name}</div>}
			</div>
			<div className={s.price}>
				<span>{t('price')}:</span>{new_price + currency}
			</div>
		</div>
	</div>;
}

export default CrossSaleBlock;
