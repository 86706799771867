import React from 'react';
import { Modal } from '../../../../ui/Modals';
import { useTranslation } from 'react-i18next';
import { ArchiveModalProps } from './types';
import ScriptItems from '../../ScriptItems/ScriptItems';

const ArchiveModal: React.FC<ArchiveModalProps> = ({ scripts, open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal title={t('archive')} open={open} onClose={onClose}>
      <ScriptItems scripts={scripts} isArchivePage />
    </Modal>
  );
};

export default ArchiveModal;
