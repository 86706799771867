import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../ui/Inputs';
import s from './AddScriptForm.module.scss';
import { useFormik } from 'formik';
import { AddScriptFormProps, AddScriptValues } from './types';
import { addScriptValidate } from '../../../../utils/validate';
import ScriptView from '../ScriptView/ScriptView';
import { useAddScriptMutation, useEditScriptMutation } from '../../../../store/api';
import { PhotoModal } from '../../ScriptPage';
import { IScriptVideo } from '../../ScriptPage/ScriptBlock/types';
import { Notification } from '../../../ui/Modals';

const AddScriptForm: React.FC<AddScriptFormProps> = ({
  project_id,
  script,
  setScriptId,
  isCrossSalesChanged
}) => {
  const { t } = useTranslation();
  const [addScript] = useAddScriptMutation();
  const [editScript] = useEditScriptMutation();
  const [photoModal, setPhotoModal] = useState(false);
  const [newPhotos, setNewPhotos] = useState<{ id: number; photo: Blob }[]>([]);
  const [newVideos, setNewVideos] = useState<Omit<IScriptVideo, 'order'>[]>([]);
  const [scriptInitialValues, setScriptInitialValues] = useState({
    name: '',
    price: '',
    articul: '',
    internal_id: ''
  });

  const previewPhoto = script?.photos?.[0]?.url ?? newPhotos?.[0]?.photo ?? undefined;

  const [notificationMessage, setNotitificationMessage] = useState('');
  const [notification, setNotitification] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleOpenPhotoModal = () => {
    setPhotoModal(true);
  };

  const handleClosePhotoModal = () => {
    setPhotoModal(false);
  };

  const handleCloseNotification = () => {
    setNotitification(false);
    setIsError(false);
  };

  const formik = useFormik({
    initialValues: scriptInitialValues,
    validationSchema: addScriptValidate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: AddScriptValues) => {
      try {
        if (script) {
          setIsError(false);
          const newValues = Object.values(values).map(String);
          const oldValues = Object.values(scriptInitialValues).map(String);
          const isNotChanged = newValues.every((value, index) => value === oldValues[index]);
          !isNotChanged &&
            (await editScript({
              id: script.id,
              order_id: script.order_id,
              project_id,
              ...values
            }).unwrap());
          setNotitificationMessage(
            !isNotChanged || isCrossSalesChanged ? `${t('changesSaved')}` : `${t('withoutChanges')}`
          );
          setNotitification(true);
        } else {
          setIsError(false);
          setNotitificationMessage('');
          const photos = newPhotos.map((item) => item.photo);
          const videos = newVideos.map(({ id, ...item }) => item);
          const response = await addScript({ project_id, photos, videos, ...values }).unwrap();
          if (response?.data?.id && setScriptId) {
            setScriptId(response.data.id);
            setNotitificationMessage(`${t('script.scriptIsCreated')}`);
            setNotitification(true);
          }
        }
      } catch (e) {
        const error = (e as { data: { error: { errors: { name: string[] } } } }).data.error.errors
          .name;
        setIsError(true);
        setNotitification(true);
        if (error[0] === 'The name has already been taken.') {
          setNotitificationMessage(`${t('alreadyExist')}`);
        } else {
          setNotitificationMessage('Something occured!');
        }
      }
    }
  });

  useEffect(() => {
    if (script) {
      const values = {
        name: script.name,
        price: script.price,
        articul: script.articul ?? '',
        internal_id: script.internal_id
      };
      formik.setValues(values);
      setScriptInitialValues(values);
    }
  }, [script]);

  return (
    <div className={s.body}>
      <div className={s.view}>
        <ScriptView
          name={formik.values.name}
          price={formik.values.price}
          articul={formik.values.articul}
          internal_id={formik.values.internal_id}
          photo={previewPhoto}
          openPhotoModal={handleOpenPhotoModal}
        />
      </div>
      <form className={s.form} onSubmit={formik.handleSubmit} id="script-form">
        <div className={s.fields}>
          <div className={s.field}>
            <Input
              name="name"
              error={!!formik.errors.name || isError}
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder={`${t('script.name')}`}
              className={s.name}
            />
          </div>
          <div className={s.info}>
            <div className={s.field}>
              <Input
                name="price"
                error={!!formik.errors.price}
                onChange={formik.handleChange}
                value={formik.values.price}
                placeholder={`${t('price')}`}
              />
            </div>
            <div className={s.field}>
              <Input
                name="articul"
                error={!!formik.errors.articul}
                onChange={formik.handleChange}
                value={formik.values.articul}
                placeholder={`${t('articul')}`}
              />
            </div>
            <div className={s.field}>
              <Input
                name="internal_id"
                error={!!formik.errors.internal_id}
                onChange={formik.handleChange}
                value={formik.values.internal_id}
                placeholder="ID"
              />
            </div>
          </div>
        </div>
      </form>
      <PhotoModal
        isScriptSettings
        open={photoModal}
        onClose={handleClosePhotoModal}
        script_id={script?.id}
        photos={script?.photos}
        videos={script?.videos}
        newPhotos={newPhotos}
        newVideos={newVideos}
        setNewPhotos={setNewPhotos}
        setNewVideos={setNewVideos}
      />
      <Notification
        title={notificationMessage}
        open={notification}
        onClose={handleCloseNotification}
        withExclamationIcon={isError}
        red={isError}
      />
    </div>
  );
};

export default AddScriptForm;
