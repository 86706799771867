import React, { FC } from 'react';
import { Reorder, useDragControls } from 'framer-motion';
import s from './InfoBlock.module.scss';
import { ReorderInfoBlockProps } from './types';
import InfoBlock from './InfoBlock';
import { DropDownIcon } from '../../Icons';

const RegulationsReorderBlock: FC<ReorderInfoBlockProps> = ({
  block,
  viewedBlock,
  changeOrders,
  ...props
}) => {
  const dragControls = useDragControls();

  const handleDragControls = (e: React.PointerEvent<HTMLLIElement>) => {
    dragControls.start(e);
  };

  return (
    <Reorder.Item
      value={block}
      dragListener={false}
      dragControls={dragControls}
      onDragEnd={changeOrders}
    >
      <InfoBlock
        block={viewedBlock}
        additionalFunction={
          <li className={s.function} onPointerDown={handleDragControls}>
            <DropDownIcon />
          </li>
        }
        {...props}
      />
    </Reorder.Item>
  );
};
export default RegulationsReorderBlock;
