import React from 'react';
import { Icon } from './types';

const VideoIcon: React.FC<Icon> = ({ className }) => {
  return (
    <svg className={className ?? ''} width="66" height="47" viewBox="0 0 66 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.8" d="M65.431 9.85714C65.431 7.09524 64.4828 4.7619 62.5862 2.85714C60.6897 0.952381 58.4138 0 55.7586 0H9.67241C7.01724 0 4.74138 0.952381 2.84483 2.85714C0.948276 4.7619 0 7.09524 0 9.85714V36.2857C0 39.0476 0.948276 41.381 2.84483 43.2857C4.74138 45.1905 7.01724 46.1429 9.67241 46.1429H55.7586C58.4138 46.1429 60.6897 45.1905 62.5862 43.2857C64.4828 41.381 65.431 39.0476 65.431 36.2857V9.85714ZM26.1724 34.2857V9.42857L44.9483 21.8571L26.1724 34.2857Z" fill="white"/>
    </svg>
  );
};

export default VideoIcon;
