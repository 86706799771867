import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import s from './styles.module.scss';
import { Title } from '../../../ui/Title';
import { CopyIcon, DetachedIcon, DropDownIcon, EditIcon, TrashCanIcon } from '../../../ui/Icons';
import { useTranslation } from 'react-i18next';
import { CrossSaleItemProps } from './types';
import { YesNoModal } from '../../../ui/Modals';
import defaultPhoto from '../../../../assets/images/default.jpg';
import { getImage } from '../../../../utils/getImage';
import { RoleBasedComponent } from '../../../roles';
import { Roles, RouteNames, Status } from '../../../../constants';
import CrossSaleModal from '../CrossSaleModal/CrossSaleModal';
import { Reorder, useDragControls } from 'framer-motion';

const CrossSaleItem: React.FC<CrossSaleItemProps> = ({
  crossSale,
  changeOrders,
  deleteCrossSale,
  copyCrossSale,
  blockCrossSale
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dragControls = useDragControls();
  const { id, project_id, name, new_price, old_price, internal_id, status, photo } = crossSale;
  const currencyNew = !new_price?.includes('$') ? ' Грн.' : '';
  const currencyOld = !old_price?.includes('$') ? ' Грн.' : '';
  const [yesNoModal, setYesNoModal] = useState(false);
  const [yesNoModalTitle, setYesNoModalTitle] = useState('');
  const [yesNoModalFunc, setYesNoModalFunc] = useState<(() => void) | null>(null);
  const [crossSaleModal, setCrossSaleModal] = useState(false);

  const handleDragControls = (e: React.PointerEvent<HTMLLIElement>) => {
    dragControls.start(e);
  };

  const handleCloseCrossSaleModal = () => {
    setCrossSaleModal(false);
  };

  const handleOpenCrossSaleModal = () => {
    setCrossSaleModal(true);
  };

  const handleEditCrosSale = () => {
    navigate(`${RouteNames.CROSS_SALE}/edit/${id}?project_id=${project_id}`);
  };

  const handleOpenYesNoModal = (title: string, func: () => void) => {
    setYesNoModal(true);
    setYesNoModalTitle(title);
    setYesNoModalFunc(func);
  };

  const handleCloseYesNoModal = () => {
    setYesNoModal(false);
    setYesNoModalTitle('');
    setYesNoModalFunc(null);
  };

  const handleCopyModal = () => {
    handleOpenYesNoModal(`${t('areYouShureToCopy')} “${name}”?`, () => functions.handleCopy);
  };

  const handleDeleteModal = () => {
    handleOpenYesNoModal(`${t('areYouShureToDelete')} “${name}”?`, () => functions.handleDelete);
  };

  const handleBlockModal = () => {
    if (status === Status.BLOCKED) {
      handleOpenYesNoModal(`${t('areYouShureToUnBlock')} “${name}”?`, () => functions.handleBlock);
    } else {
      handleOpenYesNoModal(`${t('areYouShureToBlock')} “${name}”?`, () => functions.handleBlock);
    }
  };

  // Functions
  const functions = {
    handleBlock: () => {
      blockCrossSale(id);
      handleCloseYesNoModal();
    },
    handleCopy: () => {
      copyCrossSale(id);
      handleCloseYesNoModal();
    },
    handleDelete: () => {
      deleteCrossSale(id);
      handleCloseYesNoModal();
    }
  };

  return (
    <>
      <Reorder.Item
        value={crossSale}
        dragListener={false}
        dragControls={dragControls}
        onDragEnd={changeOrders}
      >
        <div
          className={cn(s.item, { [s.blocked]: status === Status.BLOCKED })}
          onClick={handleOpenCrossSaleModal}
        >
          <div className={s.wrapper}>
            <img
              src={typeof photo === 'string' ? getImage(photo) : defaultPhoto}
              alt={'crossSale-img'}
            />
          </div>
          <div className={s.info}>
            <Title title={name} variant="h3" className={s.title} />
            <ul className={s.list}>
              <li className={s.list__item}>
                <span className={s.header}>{t('price')}:</span>{' '}
                <span>{new_price + currencyNew}</span>
              </li>
              {old_price && (
                <li className={s.list__item}>
                  <span className={s.header}>{t('crossSalePage.oldPrice')}:</span>{' '}
                  <span>{old_price + currencyOld}</span>
                </li>
              )}
              <li className={s.list__item}>
                <span className={s.header}>ID:</span> <span>{internal_id}</span>
              </li>
            </ul>
            <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
              <ul className={s.functions} onClick={(e) => e.stopPropagation()}>
                <li className={s.function} onClick={handleEditCrosSale}>
                  <EditIcon className={s.blueIcon} />
                </li>
                <li className={s.function} onClick={handleBlockModal}>
                  <DetachedIcon
                    className={cn(s.detachedIcon, { [s.blocked]: status === Status.BLOCKED })}
                  />
                </li>
                {status === Status.ACTIVE && (
                  <li className={s.function} onPointerDown={handleDragControls}>
                    <DropDownIcon className={s.dropDownIcon} />
                  </li>
                )}
                <li className={s.function} onClick={handleCopyModal}>
                  <CopyIcon className={s.blueIcon} />
                </li>
                <li className={s.function} onClick={handleDeleteModal}>
                  <TrashCanIcon className={s.redIcon} />
                </li>
              </ul>
            </RoleBasedComponent>
          </div>
        </div>
      </Reorder.Item>
      <YesNoModal
        title={yesNoModalTitle}
        open={yesNoModal}
        onClose={handleCloseYesNoModal}
        onAgree={yesNoModalFunc ? yesNoModalFunc : () => null}
      />
      <CrossSaleModal
        crossSale={crossSale}
        open={crossSaleModal}
        onClose={handleCloseCrossSaleModal}
      />
    </>
  );
};

export default CrossSaleItem;
