import React, { useContext, useEffect, useState } from 'react';
import { Modal, YouTubeModal } from '../../../../ui/Modals';
import { useTranslation } from 'react-i18next';
import { PhotoModalProps } from './types';
import { Title } from '../../../../ui/Title';
import s from './PhotoModal.module.scss';
import { Photo } from '../../../../ui/Photo';
import { RoleBasedComponent } from '../../../../roles';
import { Roles } from '../../../../../constants';
import { AddPhotoButton, Button } from '../../../../ui/Buttons';
import { Input } from '../../../../ui/Inputs';
import {
  useDeleteScriptPhotoMutation,
  useDeleteScriptVideoMutation,
  useUploadScriptPhotoMutation,
  useUploadScriptVideoMutation
} from '../../../../../store/api';
import { getImage } from '../../../../../utils/getImage';
import defaultPhoto from '../../../../../assets/images/default.jpg';
import { AuthContext } from '../../../../../context/Auth/AuthContext';

const PhotoModal: React.FC<PhotoModalProps> = ({
  open,
  onClose,
  isScriptSettings,
  script_id,
  photos,
  videos,
  newPhotos,
  newVideos,
  setNewPhotos,
  setNewVideos
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const isAdmin = user?.role === Roles.ADMIN || user?.role === Roles.SUPERADMIN;
  const [addPhotos] = useUploadScriptPhotoMutation();
  const [deletePhoto] = useDeleteScriptPhotoMutation();
  const [addVideo] = useUploadScriptVideoMutation();
  const [deleteVideo] = useDeleteScriptVideoMutation();
  const [blobUrls, setBlobUrls] = useState<{ id: number; photo: string }[]>([]);
  const [addVideoModal, setAddVideoModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [videoId, setVideoId] = useState('');

  const showEmptyPhotoText = !photos?.length && !isScriptSettings;
  const showEmptyVideoText = !videos?.length && !isScriptSettings;

  const handleOpenAddVideoModal = () => {
    setAddVideoModal(true);
  };

  const handleCloseAddVideoModal = () => {
    setAddVideoModal(false);
  };

  const handleOpenVideoModal = (id: string) => {
    setVideoId(id);
    setVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setVideoId('');
    setVideoModal(false);
  };

  const handleAddNewVideo = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const videoId = value.split('v=')[1];
    const imageUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

    fetch(
      `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=snippet`
    )
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        handleCloseAddVideoModal();
      });

    if (!script_id) {
      newVideos &&
        setNewVideos &&
        setNewVideos([
          ...newVideos,
          { id: Date.now(), image_url: imageUrl, video_url: value, video_id: videoId }
        ]);
    } else {
      addVideo({ script_id, image_url: imageUrl, video_url: value, video_id: videoId });
    }
  };

  const handleAddNewPhotos = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const photos = Array.from(target.files || []);
    if (!script_id) {
      const files = photos.map((item) => {
        return {
          id: Date.now() + Math.random(),
          photo: item
        };
      });
      newPhotos && setNewPhotos && setNewPhotos([...newPhotos, ...files]);
    } else {
      addPhotos({ script_id, files: photos });
    }
  };

  const handleDeleteVideo = (idDelete: number) => {
    if (!script_id) {
      const videos = newVideos?.filter((video) => video.id !== idDelete);
      videos && setNewVideos && setNewVideos(videos);
    } else {
      deleteVideo({ script_id, video_id: idDelete });
    }
  };

  const handleDeletePhoto = (idDelete: number) => {
    if (!script_id) {
      const photos = newPhotos?.filter((photo) => photo.id !== idDelete);
      const newBlobUrls = blobUrls.filter((photo) => photo.id !== idDelete);
      photos && setNewPhotos && setNewPhotos(photos);
      setBlobUrls(newBlobUrls);
    } else {
      deletePhoto({ script_id, photo_id: idDelete });
    }
  };

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = defaultPhoto;
  };

  useEffect(() => {
    const newUrls = newPhotos?.map((photo) =>
      photo.photo instanceof Blob
        ? { id: photo.id, photo: URL.createObjectURL(photo.photo) }
        : { id: 0, photo: '' }
    );
    newUrls && setBlobUrls((prev) => [...prev, ...newUrls]);

    return () => {
      newUrls?.forEach((photo) => URL.revokeObjectURL(photo.photo));
      setBlobUrls((prev) => prev.filter((url) => !newUrls?.includes(url)));
    };
  }, [newPhotos]);

  return (
    <>
      <Modal title={t('photo')} open={open} onClose={onClose}>
        <div className={s.photo}>
          <div className={s.photo__content}>
            {!!photos?.length &&
              photos.map((photo) => {
                return (
                  <div className={s.imageWithFilter__wrapper} key={photo.id}>
                    <Photo
                      img={getImage(photo.url)}
                      className={s.imageWithFilter}
                      isAdmin={isAdmin}
                      onDelete={() => handleDeletePhoto(photo.id)}
                    />
                  </div>
                );
              })}
            {!!blobUrls?.length &&
              blobUrls.map((img) => {
                return (
                  <div className={s.imageWithFilter__wrapper} key={img.id}>
                    <Photo
                      img={img.photo}
                      className={s.imageWithFilter}
                      isAdmin={isAdmin}
                      onDelete={() => handleDeletePhoto(img.id)}
                    />
                  </div>
                );
              })}
            {isScriptSettings && (
              <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
                <AddPhotoButton
                  className={s.addPhoto__btn}
                  multi
                  changePhoto={handleAddNewPhotos}
                />
              </RoleBasedComponent>
            )}
            {showEmptyPhotoText && <p>{t('emptyPhoto')}</p>}
          </div>
        </div>
        <div className={s.video}>
          <Title title={t('video')} variant={'h1'} className={s.video__title} />
          <div className={s.video__content}>
            {!!videos?.length &&
              videos.map((video) => {
                return (
                  <div className={s.imageWithFilter__wrapper} key={video.id}>
                    <RoleBasedComponent roles={[Roles.MANAGER]}>
                      <div
                        className={s.imageWithFilter}
                        onClick={() => handleOpenVideoModal(video.video_id)}
                      >
                        <img src={video.image_url} alt="video-photo" onError={handleImageError} />
                      </div>
                    </RoleBasedComponent>
                    <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
                      <div className={s.admin__videoPhoto}>
                        <Photo
                          img={video.image_url}
                          className={s.imageWithFilter}
                          videoName={video.video_url}
                          isAdmin
                          onDelete={() => handleDeleteVideo(video.id)}
                        />
                        <Button onClick={() => handleOpenVideoModal(video.video_id)} outlined>
                          {t('buttons.checkVideo')}
                        </Button>
                      </div>
                    </RoleBasedComponent>
                  </div>
                );
              })}
            {!!newVideos?.length &&
              newVideos.map((video) => {
                return (
                  <div className={s.imageWithFilter__wrapper} key={video.id}>
                    <RoleBasedComponent roles={[Roles.MANAGER]}>
                      <div
                        className={s.imageWithFilter}
                        onClick={() => handleOpenVideoModal(video.video_id)}
                      >
                        <img src={video.image_url} alt="video-photo" onError={handleImageError} />
                      </div>
                    </RoleBasedComponent>
                    <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
                      <div className={s.admin__videoPhoto}>
                        <Photo
                          img={video.image_url}
                          className={s.imageWithFilter}
                          videoName={video.video_url}
                          isAdmin
                          onDelete={() => handleDeleteVideo(video.id)}
                        />
                        <Button onClick={() => handleOpenVideoModal(video.video_id)} outlined>
                          {t('buttons.checkVideo')}
                        </Button>
                      </div>
                    </RoleBasedComponent>
                  </div>
                );
              })}
            {isScriptSettings && (
              <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
                <AddPhotoButton
                  className={s.addPhoto__btn}
                  onClick={handleOpenAddVideoModal}
                  multi
                  inputDisabled
                />
              </RoleBasedComponent>
            )}
            {showEmptyVideoText && <p>{t('emptyVideo')}</p>}
          </div>
        </div>
      </Modal>
      <Modal title={t('enterLink')} open={addVideoModal} onClose={handleCloseAddVideoModal}>
        <Input
          name="add-video-link"
          placeholder={`${t('link')}`}
          onChange={handleAddNewVideo}
          className={s.addVideo__input}
        />
      </Modal>
      <YouTubeModal videoId={videoId} open={videoModal} onClose={handleCloseVideoModal} />
    </>
  );
};

export default PhotoModal;
