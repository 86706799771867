import React from 'react';
import { Icon } from './types';
import cn from 'classnames';
import s from './Icon.module.scss';

const DropDownIcon: React.FC<Icon> = ({ className }) => {
  return (
    <svg className={cn(s.icon_spec, className)} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6314 7.3161V5.73715C13.6314 5.31839 13.465 4.91678 13.1689 4.62067C12.8728 4.32456 12.4712 4.1582 12.0525 4.1582H5.73666C5.3179 4.1582 4.91629 4.32456 4.62018 4.62067C4.32407 4.91678 4.15771 5.31839 4.15771 5.73715V12.0529C4.15771 12.4717 4.32407 12.8733 4.62018 13.1694C4.91629 13.4655 5.3179 13.6319 5.73666 13.6319H7.31561" stroke="#707070" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1 1V1.00789M4.15789 1V1.00789M7.31579 1V1.00789M10.4737 1V1.00789M1 4.15789V4.16579M1 7.31579V7.32368M1 10.4737V10.4816M8.89474 8.89474L16 11.2632L12.8421 12.8421L11.2632 16L8.89474 8.89474Z" stroke="#707070" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  );
};

export default DropDownIcon;
