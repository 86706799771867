import React, { useState } from 'react';
import { Modal, YouTubeModal } from '../../../../ui/Modals';
import { LessonModalProps } from './types';
import { InfoBlock } from '../../../../ui/InfoBlock';
import {
  useAddPhotoToEducationMutation,
  useDeletePhotoFromEducationMutation
} from '../../../../../store/api';
import s from './LessonModal.module.scss';
import { VideoIcon } from '../../../../ui/Icons';

const LessonModal: React.FC<LessonModalProps> = ({ lesson, open, onClose }) => {
  const [addPhotos] = useAddPhotoToEducationMutation();
  const [deletePhoto] = useDeletePhotoFromEducationMutation();
  const [videoModal, setVideoModal] = useState(false);

  const handleCloseVideoModal = () => {
    setVideoModal(false);
  };

  const handleOpenVideoModal = () => {
    setVideoModal(true);
  };

  const handleAddPhotos = (id: number, files: Blob[]) => {
    addPhotos({ id, files });
  };

  const handleDeletePhoto = (id: number, photo_id: number) => {
    deletePhoto({ id, photo_id });
  };

  return (
    <>
      <Modal title={'Урок'} open={open} onClose={onClose}>
        {lesson.videos?.[0]?.image_url && (
          <div className={s.video} onClick={handleOpenVideoModal}>
            <img src={lesson.videos[0].image_url} alt="video-img" />
            <VideoIcon className={s.video__icon} />
          </div>
        )}
        <InfoBlock
          block={lesson}
          withoutEdit
          addPhoto={handleAddPhotos}
          deletePhoto={handleDeletePhoto}
          saveBlock={() => null}
          removeBlock={() => null}
          addPhotoInEditor={() => undefined}
        />
      </Modal>
      <YouTubeModal
        videoId={lesson.videos?.[0]?.video_id ?? ''}
        open={videoModal}
        onClose={handleCloseVideoModal}
      />
    </>
  );
};

export default LessonModal;
