import React, { useEffect, useMemo } from 'react';
import { YesNoModalProps } from './types';
import { createPortal } from 'react-dom';
import s from './YesNoModal.module.scss';
import { Title } from '../../Title';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../../Buttons';
import { useTranslation } from 'react-i18next';

const modalElement = document.querySelector('#modal');

const YesNoModal: React.FC<YesNoModalProps> = ({ title, open, isDelete, onClose, onAgree }) => {
  const element = useMemo(() => document.createElement('div'), []);
  const { t } = useTranslation();
  const text = isDelete ? `${t('areYouShureToDelete')} “${title}”?` : title;

  useEffect(() => {
    if (open) {
      modalElement?.appendChild(element);
      return () => {
        modalElement?.removeChild(element);
      };
    }
  });

  const modalBody = (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ overflow: 'hidden', position: 'absolute', zIndex: 9999 }}
        >
          <div className={s.modal} onClick={onClose}>
            <div className={s.content} onClick={(e) => e.stopPropagation()}>
              <div className={s.title}>
                <Title title={text} variant="h1" textAlign="center" />
              </div>
              <div className={s.buttons}>
                <Button onClick={onAgree} red>
                  {t('buttons.yes')}
                </Button>
                <Button onClick={onClose}>{t('buttons.no')}</Button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return createPortal(modalBody, element);
};

export default YesNoModal;
