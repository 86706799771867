export enum RouteNames {
  DASHBOARD = '/dashboard',
  LOGIN = '/login',
  PROJECTS = '/projects',
  SCRIPT = '/script',
  CROSS_SALE = '/cross-sale',
  SCRIPTS = '/scripts',
  CROSS_SALES = '/cross-sales',
  MANAGERS = '/managers',
  EDUCATION = '/education',
  OBJECTIONS = '/objections',
  ACCOUNTS = '/accounts',
  DATA_LOGGING = '/data-logging',
  ALL_SCRIPTS = '/all-scripts',
  UNIQUE_FIELDS = '/unique-fields',
  IDENTICAL_FIELDS = '/identical-fields',
  FORBIDDEN = '/forbidden'
}

export const linkNames = [
  { name: 'scripts', href: RouteNames.PROJECTS },
  { name: 'managerBook', href: '' },
  { name: 'education', href: RouteNames.EDUCATION },
  { name: 'objections', href: RouteNames.OBJECTIONS },
  { name: 'accounts', href: RouteNames.ACCOUNTS },
  { name: 'dataLogging', href: RouteNames.DATA_LOGGING + '/projects' }
];
