import React from 'react';
import s from './Photo.module.scss';
import { TrashCanIcon } from '../Icons';
import { PhotoProps } from './types';
import cn from 'classnames';
import { Modal, YesNoModal } from '../Modals';
import defaultPhoto from '../../../assets/images/default.jpg';
import { useBoolean } from '../../../hooks';

const Photo: React.FC<PhotoProps> = ({ img, videoName, className, isAdmin, onDelete }) => {
  const [imgModal, setImgModal] = useBoolean();
  const [deleteModal, setDeleteModal] = useBoolean();
  const imgName = img.split('/').pop();

  const handleDelete = () => {
    onDelete?.();
    setDeleteModal.off();
  };

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = defaultPhoto;
  };

  return (
    <div className={cn(s.image, className)}>
      <img src={img} alt="photo" onClick={setImgModal.on} onError={handleImageError} />
      <Modal
        title=""
        open={imgModal}
        onClose={setImgModal.off}
        contentClassName={s.modalContent}
        bodyClassName={s.modalBody}
      >
        <img src={img} alt="photo" onError={handleImageError} />
      </Modal>
      {isAdmin && (
        <>
          <div className={s.delete} onClick={setDeleteModal.on}>
            <TrashCanIcon className={s.deleteIcon} />
          </div>
          <YesNoModal
            title={videoName ?? imgName ?? ''}
            open={deleteModal}
            isDelete
            onClose={setDeleteModal.off}
            onAgree={handleDelete}
          />
        </>
      )}
    </div>
  );
};

export default Photo;
