import React, { useContext, useState } from 'react';
import cn from 'classnames';

// Import Swiper settings
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y } from 'swiper';

// Import Swiper styles
import 'swiper/css';

import s from './CrossSaleSlider.module.scss';

import { CrossSaleSliderProps } from './types';
import CrossSaleBlock from '../CrossSaleBlocks/CrossSaleBlock';
import { SliderNextArrow, SliderPrevArrow } from '../../../ui/Sliders';
import { ThemeContext } from '../../../../context/Theme/ThemeContext';
import CrossSaleModal from '../CrossSaleModal/CrossSaleModal';
import { ICrossSale } from '../CrossSaleItems/types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../../constants';

const CrossSaleSlider: React.FC<CrossSaleSliderProps> = ({ cross_sales }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [crossSale, setCrossSale] = useState<ICrossSale | null>(null);
  const [crossSaleModal, setCrossSaleModal] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const handleCloseCrossSaleModal = () => {
    setCrossSaleModal(false);
  };

  const handleCrossSaleClick = (crossSale: ICrossSale) => {
    setCrossSaleModal(true);
    setCrossSale(crossSale);
  };

  const handleSetLastSlide = () => {
    setIsLastSlide(true);
  };

  const handleUnsetLastSlide = () => {
    setIsLastSlide(false);
  };

  return (
    <div className={cn(s.slider, { [s.dark]: theme === 'dark' }, { [s.ending]: isLastSlide })}>
      <Swiper
        modules={[A11y]}
        slidesPerView={3.75}
        onReachEnd={handleSetLastSlide}
        onSlideChange={handleUnsetLastSlide}
      >
        {cross_sales.map((block) => {
          return (
            <SwiperSlide key={block.id} className={s.swiperSlide}>
              <CrossSaleBlock cross_sale={block} onClick={() => handleCrossSaleClick(block)} />
            </SwiperSlide>
          );
        })}
        {cross_sales.length > 3 && (
          <>
            <SliderPrevArrow className={s.arrow__prev} />
            <SliderNextArrow className={s.arrow__next} />
          </>
        )}
        <Link
          to={`${RouteNames.CROSS_SALES}?project_id=${cross_sales[0].project_id}`}
          className={s.checkAll}
        >
          {t('viewAll')}
        </Link>
      </Swiper>
      {crossSale && (
        <CrossSaleModal
          crossSale={crossSale}
          open={crossSaleModal}
          onClose={handleCloseCrossSaleModal}
        />
      )}
    </div>
  );
};

export default CrossSaleSlider;
