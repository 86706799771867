import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { loginValidate } from '../../../../utils/validate';
import { Button } from '../../../ui/Buttons';
import { Input } from '../../../ui/Inputs';
import { LoginFormValues } from './types';
import s from './LoginForm.module.scss'
import { useTranslation } from 'react-i18next';
import { useLoginMutation } from '../../../../store/api';
import { AuthContext } from '../../../../context/Auth/AuthContext';
import { Notification } from '../../../ui/Modals';

const LoginForm: React.FC = () => {
  const { t } = useTranslation()
  const [ loginReq ] = useLoginMutation();
  const { login } = useContext(AuthContext);
  const [error, setError] = useState('');
  const [errorNotification, setErrorNotification] = useState(false);

  const handleCloseErrorNotification = () => {
    setErrorNotification(false);
  }

	const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: loginValidate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: LoginFormValues) => {
      try {
        await loginReq(values).unwrap().then(response => {
          login(response.user);
          response.token && localStorage.setItem('token', response.token);
        });
      } catch (e) {
        const error = (e as {data: {error: {errors: string[]}}}).data.error.errors[0];
        setError(error);
        setErrorNotification(true);
      }
    }
  });

  return (
    <form className={s.form} onSubmit={formik.handleSubmit}>
      <div className={s.fields}>
        <div className={s.field}>
          <Input
            name="login"
            error={!!formik.errors.login}
            onChange={formik.handleChange}
            value={formik.values.login}
            placeholder={`${t('loginPage.name')}`}
            className={s.input}
            whithoutChangeTheme
          />
        </div>
        <div className={s.field}>
          <Input
            name="password"
            type='text'
            error={!!formik.errors.password}
            onChange={formik.handleChange}
            value={formik.values.password}
            placeholder={`${t('password')}`}
            className={s.input}
            whithoutChangeTheme
          />
        </div>
      </div>
      <div className={s.btn}>
        <Button type="submit">{t('login')}</Button>
      </div>
      <Notification title={error} open={errorNotification} onClose={handleCloseErrorNotification} position='bottom-right' red withExclamationIcon />
    </form>
  );
}

export default LoginForm;
