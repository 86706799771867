import React from 'react';
import { Modal } from '../../../ui/Modals';
import { CRMModalProps } from './types';
import s from './CRMModal.module.scss';
import CRMModalContent from './CRMModalContent';
import { useTranslation } from 'react-i18next';

const CRMModal: React.FC<CRMModalProps> = ({ project_id, url, open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal title={t('CRMUrl')} open={open} onClose={onClose} bodyClassName={s.crm}>
      <CRMModalContent project_id={project_id} url={url} />
    </Modal>
  );
};

export default CRMModal;
