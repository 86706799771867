import { useEffect, useState } from 'react';
import { IUser } from '../components/modules/AccountsPage/AccountsItems/types';
import { useLogoutMutation } from '../store/api';

export const useAuth = () => {
  const savedUser = localStorage.getItem('user');
  const [logoutReq] = useLogoutMutation();
  const [user, setUser] = useState<Omit<IUser, 'password'> | null>(null);

  useEffect(() => {
    const getAuthData = async () => {
      if (savedUser) {
        setUser(JSON.parse(savedUser));
      } else {
        logoutReq();
      }
    };
    getAuthData();
  }, [savedUser]);

  const login = (user: Omit<IUser, 'password'>) => {
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
  };
  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  return { user, login, logout };
};
