import React, { useState } from 'react';
import { BackButton, Button } from '../components/ui/Buttons';
import { useGetProjectQuery, useGetUniqAdditionalFieldsQuery } from '../store/api';
import { Link } from 'react-router-dom';
import { Tabs } from '../components/ui/Tabs';
import { RouteNames, projectTabs } from '../constants';
import { useTranslation } from 'react-i18next';
import { RegulationsModal } from '../components/modules/ScriptPage';
import { Loading } from '../components/ui/Loading';

const UniqueFields: React.FC = () => {
  const { t } = useTranslation();
  const project_id = Number(new URLSearchParams(location.search).get('project_id'));
  const { data: project } = useGetProjectQuery(project_id);
  const { data: fields, isLoading: isLoadingOnFields } =
    useGetUniqAdditionalFieldsQuery(project_id);
  const nothingFound = !fields?.data.length && !isLoadingOnFields;

  const [regulationModal, setRegulationModal] = useState(false);

  const handleOpenRegulationModal = () => {
    setRegulationModal(true);
  };

  const handleCloseRegulationModal = () => {
    setRegulationModal(false);
  };

  const tabs = projectTabs(t, project_id);

  return (
    <div className="unique-fields page-section" id="unique-fields">
      <div className="container">
        <div className="unique-fields__inner">
          <BackButton backUrl={'/projects'}>{project?.data.name}</BackButton>
          <Button onClick={handleOpenRegulationModal} className="regulations-btn" red>
            {t('projectsPage.regulations')}
          </Button>
          <Tabs tabs={tabs} defaultTab={2} />
          <ul className="unique-fields__list">
            {fields?.data.map((field) => {
              return (
                <li className="unique-fields__item" key={field.script_field_id}>
                  <Link
                    className="unique-fields__link"
                    to={
                      RouteNames.IDENTICAL_FIELDS +
                      `/${field.script_field_id}?project_id=${project_id}`
                    }
                  >
                    {field.name}
                  </Link>
                </li>
              );
            })}
          </ul>
          {isLoadingOnFields && <Loading />}
          {nothingFound && <p className="empty">{t('nothingFound')}</p>}
        </div>
      </div>
      <RegulationsModal
        project_id={project_id}
        open={regulationModal}
        onClose={handleCloseRegulationModal}
      />
    </div>
  );
};

export default UniqueFields;
