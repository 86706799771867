import React from 'react';
import { Icon } from './types';
import cn from 'classnames';
import s from './Icon.module.scss';

const TrashCanIcon: React.FC<Icon> = ({ className, path }) => {
  return (
    <svg
      className={cn(s.icon_spec, className)}
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.9 3.60026V2.30026C3.9 2.07041 3.99131 1.84997 4.15384 1.68743C4.31637 1.5249 4.53681 1.43359 4.76667 1.43359H8.23333C8.46319 1.43359 8.68363 1.5249 8.84616 1.68743C9.00869 1.84997 9.1 2.07041 9.1 2.30026V3.60026M0 4.03359H13M1.3 4.03359V12.7003C1.3 12.9301 1.39131 13.1506 1.55384 13.3131C1.71637 13.4756 1.93681 13.5669 2.16667 13.5669H10.8333C11.0632 13.5669 11.2836 13.4756 11.4462 13.3131C11.6087 13.1506 11.7 12.9301 11.7 12.7003V4.03359M6.5 7.06693V11.4003M3.9 8.80026V11.4003M9.1 8.80026V11.4003"
        stroke="#707070"
        {...path}
      />
    </svg>
  );
};

export default TrashCanIcon;
