import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Roles, RouteNames } from '../constants';
import {
  useAddProjectMutation,
  useGetProjectsQuery,
  useGetProjectsWithManagerSelectedQuery
} from '../store/api';
import { ProjectItems } from '../components/modules/ProjectsPage';
import { Title } from '../components/ui/Title';
import { RoleBasedComponent } from '../components/roles';
import { BackButton, Button, CreateButton } from '../components/ui/Buttons';
import { Input } from '../components/ui/Inputs';
import { Loading } from '../components/ui/Loading';
import { SearchContext } from '../context/Search/SearchContext';
import { Notification } from '../components/ui/Modals';

const Projects: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const manager_id = Number(new URLSearchParams(location.search).get('manager_id'));
  const { searchQuery } = useContext(SearchContext);

  const {
    data: data_all_projects,
    error: data_all_projects_error,
    refetch: refetchOnAllProjects,
    isLoading: isLoadingOnAllProjects
  } = useGetProjectsQuery();
  const {
    data: data_with_select,
    refetch: refetchOnProjectsWithSelect,
    isLoading: isLoadingOnWithSelect
  } = useGetProjectsWithManagerSelectedQuery(manager_id, { skip: !manager_id });

  const data_all_projects_with_search = data_all_projects?.data.filter((project) =>
    project.name.toLowerCase().includes(searchQuery.toLowerCase().trim())
  );

  const nothingFound = !data_all_projects_with_search?.length && !!searchQuery;

  const showLoading = isLoadingOnAllProjects || isLoadingOnWithSelect;
  const [addProject, { isLoading: isLoadingOnAdd }] = useAddProjectMutation();
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [createProject, setCreateProject] = useState(false);
  const [projectName, setProjectName] = useState('');

  const handleCloseErrorNotification = () => {
    setError(false);
  };

  const handleCreateProject = () => {
    setCreateProject(true);
  };

  const handleChangeProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
  };

  const handleAddProject = async () => {
    if (projectName) {
      try {
        await addProject(projectName)
          .unwrap()
          .then(() => {
            refetchOnAllProjects ? refetchOnAllProjects() : refetchOnProjectsWithSelect();
          });
        setCreateProject(false);
        setProjectName('');
      } catch (e) {
        const error = (e as { data: { error: { errors: { name: string[] } } } }).data.error.errors
          .name;
        setError(true);
        if (error[0] === 'The name has already been taken.') {
          setErrorText(`${t('alreadyExist')}`);
        } else {
          setErrorText('Something occured!');
        }
      }
    }
  };

  const handlePressEnter = (e: React.KeyboardEvent) => {
    if (!!projectName && e.code === 'Enter') {
      handleAddProject();
    }
  };

  const handleToAllScripts = () => {
    navigate(RouteNames.ALL_SCRIPTS);
  };

  useEffect(() => {
    if ((data_all_projects_error as { error: string; status: number })?.status === 403) {
      navigate(RouteNames.FORBIDDEN);
    }
  }, [data_all_projects_error]);

  return (
    <div className="projects page-section" id="projects">
      <div className="container">
        <div className="projects__inner">
          <RoleBasedComponent roles={[Roles.MANAGER]}>
            <Title
              title={t('projectsPage.allProjects')}
              variant="h1"
              textAlign="center"
              className="projects__title"
            />
          </RoleBasedComponent>
          <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
            <BackButton backUrl={manager_id ? '/accounts' : '/'}>
              {t('projectsPage.allProjects')}
            </BackButton>
            <Button onClick={handleToAllScripts} className="all-scripts-btn">
              {t('allScripts')}
            </Button>
            <CreateButton onClick={handleCreateProject}>
              {`${t('projectsPage.createProject')}`}
            </CreateButton>
            {createProject && (
              <Input
                name="name"
                onChange={handleChangeProjectName}
                value={projectName}
                forceFocus
                placeholder={`${t('projectsPage.projectName')}`}
                className="projects__input"
                onKeyDown={handlePressEnter}
                onBlur={!error ? handleAddProject : () => null}
                error={error}
              />
            )}
          </RoleBasedComponent>
          {!!data_all_projects_with_search?.length && !!manager_id && (
            <ProjectItems
              items={data_all_projects_with_search}
              selectedNums={data_with_select?.data.user_projects}
              manager_id={manager_id}
            />
          )}
          {!!data_all_projects_with_search?.length && !manager_id && (
            <ProjectItems items={data_all_projects_with_search} />
          )}
          {nothingFound && <p className="empty">{t('nothingFound')}</p>}
          {showLoading && <Loading />}
          {isLoadingOnAdd && (
            <div className="loading__wrapper">
              <Loading absolute />
            </div>
          )}
        </div>
      </div>
      <Notification
        title={errorText}
        open={error}
        onClose={handleCloseErrorNotification}
        red
        withExclamationIcon
      />
    </div>
  );
};

export default Projects;
