import React from 'react';
import { Icon } from './types';
import cn from 'classnames';
import s from './Icon.module.scss';

const CopyIcon: React.FC<Icon> = ({ className }) => {
  return (
    <svg className={cn(s.icon, className)} width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.725 5.89685L7.92917 3.0647C7.79167 2.87899 7.5625 2.78613 7.33333 2.78613H3.66667C3.1625 2.78613 2.75 3.20399 2.75 3.7147V12.0718C2.75 12.5826 3.1625 13.0004 3.66667 13.0004H10.0833C10.5875 13.0004 11 12.5826 11 12.0718V6.54685C11 6.3147 10.9083 6.08256 10.725 5.89685ZM7.33333 3.7147L10.0375 6.50042H7.33333V3.7147ZM3.66667 12.0718V3.7147H6.41667V6.50042C6.41667 7.01113 6.82917 7.42899 7.33333 7.42899H10.0833V12.0718H3.66667Z"
        fill="#707070"
      />
      <path
        d="M0.916667 7.42857H0V0.928571C0 0.417857 0.4125 0 0.916667 0H7.33333V0.928571H0.916667V7.42857Z"
        fill="#707070"
      />
    </svg>
  );
};

export default CopyIcon;
