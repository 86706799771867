import React, { useState } from 'react';
import s from './styles.module.scss';
import { Title } from '../../../ui/Title';
import { ArchiveIcon, BackIcon, EditIcon, ExclamationIcon, TrashCanIcon } from '../../../ui/Icons';
import { Button } from '../../../ui/Buttons';
import { ManagerContent } from '../../../roles';
import { useTranslation } from 'react-i18next';
import { AccountsItemProps } from './types';
import { YesNoModal } from '../../../ui/Modals';
import { useActivateUserMutation, useDeleteUserMutation } from '../../../../store/api';
import defaultPhoto from '../../../../assets/images/default.jpg';
import { getImage } from '../../../../utils/getImage';
import { useNavigate } from 'react-router-dom';
import { getRoleName } from '../../../../utils/gerRoleName';
import AccountBlockModal from '../AccountBlockModal/AccountBlockModal';
import { pluralizeDays } from '../../../../utils/pluralizeDays';

const AccountsItem: React.FC<AccountsItemProps> = ({
  account,
  editAccount,
  isArchivePage,
  isManager
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    id,
    first_name,
    last_name,
    middle_name,
    photo,
    role,
    login,
    password,
    projects_count,
    admin_block_at,
    visited_at
  } = account;

  const timingOutBlocking =
    admin_block_at &&
    visited_at &&
    new Date(admin_block_at).getTime() - new Date(visited_at).getTime();
  const daysOutBlocking =
    timingOutBlocking && Math.floor(timingOutBlocking / (1000 * 60 * 60 * 24));
  const daysEnding = daysOutBlocking && pluralizeDays(daysOutBlocking, t);
  const dateBlocking = isArchivePage ? (
    <p>
      {t('accountsPage.dateBlocking')} {visited_at}
    </p>
  ) : admin_block_at ? (
    <div>
      <p>
        {t('accountsPage.dateBlocking')} {visited_at}
      </p>
      <p>
        {t('accountsPage.stillLeft')} {daysOutBlocking} {daysEnding}
      </p>
    </div>
  ) : null;

  const [deleteUser] = useDeleteUserMutation();
  const [activateUser] = useActivateUserMutation();
  const [yesNoModal, setYesNoModal] = useState(false);
  const [yesNoModalTitle, setYesNoModalTitle] = useState('');
  const [yesNoModalFunc, setYesNoModalFunc] = useState<(() => void) | null>(null);
  const [accountBlockModal, setAccountBlockModal] = useState(false);
  const fullName = `${last_name ? last_name + ' ' : ''}${first_name ? first_name + ' ' : ''}${
    middle_name ? middle_name : ''
  }`;

  const handleOpenYesNoModal = (title: string, func: () => void) => {
    setYesNoModal(true);
    setYesNoModalTitle(title);
    setYesNoModalFunc(func);
  };

  const handleCloseYesNoModal = () => {
    setYesNoModal(false);
    setYesNoModalTitle('');
    setYesNoModalFunc(null);
  };

  const handleOpenAccountBlockModal = () => {
    setAccountBlockModal(true);
  };

  const handleCloseAccountBlockModal = () => {
    setAccountBlockModal(false);
  };

  const handleDeleteModal = () => {
    handleOpenYesNoModal(
      `${t('areYouShureToDelete')} “${fullName}”?`,
      () => functions.handleDeleteUser
    );
  };

  const handleActivateModal = () => {
    handleOpenYesNoModal(
      `${t('areYouShureToUnBlock')} “${fullName}”?`,
      () => functions.handleActivateUser
    );
  };

  // Functions
  const functions = {
    handleGoToManagerProjects: () => {
      navigate(`/projects?manager_id=${id}`);
    },
    handleEditUser: () => {
      editAccount(id);
    },
    handleDeleteUser: () => {
      deleteUser(id);
      handleCloseYesNoModal();
    },
    handleActivateUser: () => {
      activateUser(id);
      handleCloseYesNoModal();
    }
  };

  return (
    <div className={s.item}>
      <div className={s.wrapper}>
        <img src={typeof photo === 'string' ? getImage(photo) : defaultPhoto} alt={'account-img'} />
      </div>
      <div className={s.info}>
        <ul className={s.functions}>
          {isArchivePage ? (
            <li className={s.function} onClick={handleActivateModal}>
              <BackIcon className={s.blueIcon} />
            </li>
          ) : (
            <>
              <li className={s.function} onClick={functions.handleEditUser}>
                <EditIcon className={s.blueIcon} />
              </li>
              {isManager && (
                <li className={s.function} onClick={handleOpenAccountBlockModal}>
                  <ArchiveIcon className={s.blueIcon} />
                </li>
              )}
              <li className={s.function} onClick={handleDeleteModal}>
                <TrashCanIcon className={s.redIcon} />
              </li>
            </>
          )}
        </ul>
        <ManagerContent role={role}>
          {dateBlocking && (
            <div className={s.date}>
              <div className={s.date__title}>
                <p>{t('accountsPage.blocked')}</p>
                <ExclamationIcon />
              </div>
              <div className={s.date__text}>{dateBlocking}</div>
            </div>
          )}
          <Button className={s.addProject} onClick={functions.handleGoToManagerProjects} outlined>
            {!!projects_count && `(${projects_count})`} {t('accountsPage.addProject')}
          </Button>
        </ManagerContent>
        <Title title={fullName} variant="h3" className={s.title} />
        <ul className={s.list}>
          <li className={s.list__item}>{getRoleName(role, t)}</li>
          <li className={s.list__item}>{login}</li>
          <li className={s.list__item}>{password}</li>
        </ul>
      </div>
      <YesNoModal
        title={yesNoModalTitle}
        open={yesNoModal}
        onClose={handleCloseYesNoModal}
        onAgree={yesNoModalFunc ? yesNoModalFunc : () => null}
      />
      <AccountBlockModal
        userId={id}
        userName={fullName}
        open={accountBlockModal}
        onClose={handleCloseAccountBlockModal}
      />
    </div>
  );
};

export default AccountsItem;
