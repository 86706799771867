import React, { useContext } from 'react';
import { BackButton } from '../components/ui/Buttons';
import { useTranslation } from 'react-i18next';
import { useGetProjectsQuery } from '../store/api';
import { SearchContext } from '../context/Search/SearchContext';
import { Loading } from '../components/ui/Loading';
import { ProjectItems } from '../components/modules/ProjectsPage';

const DataLoggingProjects: React.FC = () => {
  const { t } = useTranslation();
  const { searchQuery } = useContext(SearchContext);
  const { data: projects, isLoading } = useGetProjectsQuery();
  const projects_with_search = projects?.data.filter((project) =>
    project.name.toLowerCase().includes(searchQuery.toLowerCase().trim())
  );

  const nothingFound = !projects_with_search?.length && !!searchQuery;

  return (
    <div className="data-logging-projects page-section" id="data-logging-projects">
      <div className="container">
        <div className="data-logging-projects__inner">
          <BackButton backUrl="/">{t('projectsPage.allProjects')}</BackButton>
          {!!projects_with_search?.length && (
            <ProjectItems items={projects_with_search} isLoggingPage />
          )}
          {nothingFound && <p className="empty">{t('nothingFound')}</p>}
          {isLoading && <Loading />}
        </div>
      </div>
    </div>
  );
};

export default DataLoggingProjects;
