import React from 'react';
import cn from 'classnames';
import s from './CreateButton.module.scss';
import { CreateButtonProps } from './types';

const CreateButton: React.FC<CreateButtonProps> = ({ className, children, ...props }) => {
  return (
    <button
      className={cn(s.button, className)}
      {...props}>
      <span>{children}</span>
      <span>+</span>
    </button>
  );
};

export default CreateButton;
