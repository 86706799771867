import React from 'react';
import cn from 'classnames';
import { ChangeModalProps } from './types';
import { Modal } from '../../../ui/Modals';
import s from './ChangeModal.module.scss';
import { useTranslation } from 'react-i18next';
import { ShortHorizontalArrowIcon } from '../../../ui/Icons';
import { useGetDetailedLoggingQuery } from '../../../../store/api';
import { Loading } from '../../../ui/Loading';
import diff from '../../../../libs/htmlDiff';

const ChangeModal: React.FC<ChangeModalProps> = ({ changeId, open, onClose }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetDetailedLoggingQuery(changeId);

  const withScriptChanges = data?.data.price_old && data?.data.internal_id_old;
  const withValueChanges = data?.data.title_old && data?.data.text_old;

  const changedScriptPrice = diff(data?.data.price_old, data?.data.price_new);
  const changedScriptArticle = diff(data?.data.article_old, data?.data.article_new);
  const changedScriptInternalId = diff(data?.data.internal_id_old, data?.data.internal_id_new);
  const changedValueTitle = diff(data?.data.title_old, data?.data.title_new);
  const changedValueText = diff(data?.data.text_old, data?.data.text_new);

  return (
    <Modal
      title={t('dataLoggingPage.change')}
      titleCentered
      open={open}
      onClose={onClose}
      contentClassName={s.content}
      bodyClassName={s.body}
    >
      <div className={s.changes__wrappper}>
        {!isLoading ? (
          <>
            {withScriptChanges && (
              <div className={s.changes}>
                <div className={s.block}>
                  <div className={s.value}>
                    <div className={s.item}>
                      <span className={s.header}>{t('price')}:</span>{' '}
                      <span>{data?.data.price_old}</span>
                    </div>
                    {data?.data.article_old && (
                      <div className={s.item}>
                        <span className={s.header}>{t('articul')}:</span>{' '}
                        <span>{data.data.article_old}</span>
                      </div>
                    )}
                    <div className={s.item}>
                      <span className={s.header}>ID:</span>{' '}
                      <span>{data?.data.internal_id_old}</span>
                    </div>
                  </div>
                  <div className={s.before}>{t('dataLoggingPage.before')}</div>
                </div>
                <ShortHorizontalArrowIcon className={s.arrow} />
                <div className={s.block}>
                  <div className={s.value}>
                    <div className={s.item}>
                      <span className={s.header}>{t('price')}:</span>{' '}
                      <span dangerouslySetInnerHTML={{ __html: changedScriptPrice }} />
                    </div>
                    {changedScriptArticle && (
                      <div className={s.item}>
                        <span className={s.header}>{t('articul')}:</span>{' '}
                        <span dangerouslySetInnerHTML={{ __html: changedScriptArticle }} />
                      </div>
                    )}
                    <div className={s.item}>
                      <span className={s.header}>ID:</span>{' '}
                      <span dangerouslySetInnerHTML={{ __html: changedScriptInternalId }} />
                    </div>
                  </div>
                  <div className={s.after}>{t('dataLoggingPage.after')}</div>
                </div>
              </div>
            )}
            {withScriptChanges && withValueChanges && <div className={s.separator} />}
            {withValueChanges && (
              <>
                <div className={s.changes}>
                  <div className={s.block}>
                    <div className={s.value}>
                      <div className={s.title}>{data.data.title_old}</div>
                      <div
                        className={s.text}
                        dangerouslySetInnerHTML={{ __html: data.data.text_old }}
                      />
                    </div>
                    <div className={s.before}>{t('dataLoggingPage.before')}</div>
                  </div>
                  <ShortHorizontalArrowIcon className={s.arrow} />
                  <div className={s.block}>
                    <div className={s.value}>
                      <div
                        className={s.title}
                        dangerouslySetInnerHTML={{ __html: changedValueTitle }}
                      />
                      <div
                        className={s.text}
                        dangerouslySetInnerHTML={{ __html: changedValueText }}
                      />
                    </div>
                    <div className={s.after}>{t('dataLoggingPage.after')}</div>
                  </div>
                </div>
              </>
            )}
            {!withScriptChanges && !withValueChanges && (
              <p className={cn(s.clear, 'empty')}>{t('withoutChanges')}</p>
            )}
          </>
        ) : (
          <Loading />
        )}
      </div>
    </Modal>
  );
};

export default ChangeModal;
