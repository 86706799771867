import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { YouTubeModalProps } from './types';
import s from './YouTubeModal.module.scss';
import YouTube, { YouTubePlayer } from 'react-youtube';

const modalElement = document.querySelector('#modal');

const YouTubeModal: React.FC<YouTubeModalProps> = ({
  videoId,
  open,
  onClose
}) => {
  const element = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    if (open) {
      modalElement?.appendChild(element);
      return () => {
        modalElement?.removeChild(element);
      };
    }
  });

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
    },
  };

  const onReady = (event: {target: YouTubePlayer }) => {
    event.target.playVideo();
  };

  const modalBody = (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ overflow: 'hidden', position: 'absolute', zIndex: 9999 }}>
          <div className={s.modal} onClick={onClose}>
            <div className={s.content} onClick={(e) => e.stopPropagation()}>
              <YouTube videoId={videoId} opts={opts} onReady={onReady} />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return createPortal(modalBody, element);
};

export default YouTubeModal;