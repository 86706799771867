import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ModalProps } from './types';
import { createPortal } from 'react-dom';
import s from './Modal.module.scss';
import { Title } from '../../Title';
import { CloseFillIcon } from '../../Icons';
import { motion, AnimatePresence } from 'framer-motion';
import { ThemeContext } from '../../../../context/Theme/ThemeContext';
import cn from 'classnames';

const modalElement = document.querySelector('#modal');

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  title,
  titleCentered,
  open,
  onClose,
  children,
  contentClassName,
  bodyClassName,
  withoutCloseIcon
}) => {
  const { theme } = useContext(ThemeContext);
  const element = useMemo(() => document.createElement('div'), []);
  const modalRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (open) {
      modalElement?.appendChild(element);
      if (modalRef.current) {
        modalRef.current.scrollTop = scrollPosition;
      }
      return () => {
        modalElement?.removeChild(element);
      };
    }
  }, [open, element, scrollPosition]);

  useEffect(() => {
    if (modalRef.current) {
      setScrollPosition(modalRef.current.scrollTop);
    }
  }, [children]);

  const modalBody = (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ overflow: 'hidden', position: 'absolute', zIndex: 9999 }}
        >
          <div className={s.modal} onClick={onClose}>
            <div
              className={cn(s.content, { [s.dark]: theme === 'dark' }, contentClassName)}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={s.header}>
                <Title title={title} variant="h1" textAlign={titleCentered ? 'center' : 'start'} />
                {!withoutCloseIcon && (
                  <button className={s.close} onClick={onClose}>
                    <CloseFillIcon className={s.close__icon} />
                  </button>
                )}
              </div>
              <div className={cn(s.body, bodyClassName)}>{children}</div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return createPortal(modalBody, element);
};

export default Modal;
