import React from 'react';
import { Icon } from './types';
import cn from 'classnames';
import s from './Icon.module.scss';

const CopyToOtherIcon: React.FC<Icon> = ({ className }) => {
  return (
    <svg
      className={cn(s.icon, className)}
      fill="#000000"
      height="18px"
      width="18px"
      version="1.1"
      viewBox="0 0 491.52 491.52">
      <g>
        <g>
          <polygon
            points="372.881,81.92 399.875,54.93 385.395,40.45 333.68,92.16 385.395,143.87 399.875,129.39 372.881,102.4 
       440.32,102.4 440.32,266.24 460.8,266.24 460.8,81.92 		"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            points="106.125,374.85 91.645,389.33 118.641,416.33 51.2,416.33 51.2,262.73 30.72,262.73 30.72,436.81 118.639,436.81 
       91.645,463.8 106.125,478.28 157.84,426.57 		"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M137.995,40.96L104.715,0H0v235.52h296.96V40.96H137.995z M276.48,215.04h-256V20.48h74.485l33.28,40.96H276.48V215.04z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M332.555,296.96L299.275,256H194.56v235.52h296.96V296.96H332.555z M471.04,471.04h-256V276.48h74.485l33.28,40.96H471.04
       V471.04z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CopyToOtherIcon;
