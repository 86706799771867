import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../ui/Inputs';
import s from './AddCrossSaleForm.module.scss';
import { useFormik } from 'formik';
import { Notification } from '../../../ui/Modals';
import { addCrossSaleValidate } from '../../../../utils/validate';
import { AddCrossSaleFormProps, AddCrossSaleValues } from './types';
import CrossSaleView from '../CrossSaleView/CrossSaleView';
import {
  useAddCrossSaleMutation,
  useAddPhotoToExistingCrossInEditorMutation,
  useAddPhotoToNewCrossInEditorMutation,
  useDeletePhotoFromCrossInEditorMutation,
  useEditCrossSaleMutation
} from '../../../../store/api';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../ui/Buttons';
import { RouteNames } from '../../../../constants';
import { TextEditor } from '../../../ui/TextEditor';
import { useEditorState } from '../../../../hooks';
import { Loading } from '../../../ui/Loading';

const AddCrossSaleForm: React.FC<AddCrossSaleFormProps> = ({ project_id, crossSale }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addCrossSale, { isLoading: isLoadingOnAdd }] = useAddCrossSaleMutation();
  const [editCrossSale, { isLoading: isLoadingOnEdit }] = useEditCrossSaleMutation();
  const [addPhotoToExistingInEditor] = useAddPhotoToExistingCrossInEditorMutation();
  const [addPhotoToNewInEditor] = useAddPhotoToNewCrossInEditorMutation();
  const [deletePhotoInEditor] = useDeletePhotoFromCrossInEditorMutation();
  const isLoading = isLoadingOnAdd || isLoadingOnEdit;
  const [newPhoto, setNewPhoto] = useState<Blob | string | undefined>(undefined);
  const [emptyPhoto, setEmptyPhoto] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [crossSaleInitialValues, setCrossSaleInitialValues] = useState({
    name: '',
    new_price: '',
    old_price: '',
    internal_id: '',
    description: ''
  });

  const handleCancelCreate = () => {
    navigate(`${RouteNames.CROSS_SALES}?project_id=${project_id}`);
  };

  const handleChangePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    setNewPhoto(file);
  };

  const handleCloseError = () => {
    setError(false);
  };

  const formik = useFormik({
    initialValues: crossSaleInitialValues,
    validationSchema: addCrossSaleValidate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: AddCrossSaleValues) => {
      if (!newPhoto) {
        setEmptyPhoto(true);
        return;
      }
      try {
        if (crossSale) {
          const newValues = Object.values(values).map(String);
          const oldValues = Object.values(crossSaleInitialValues).map(String);
          const isNotChanged =
            newValues.every((value, index) => value === oldValues[index]) &&
            !(newPhoto instanceof Blob);
          !isNotChanged &&
            (await editCrossSale({
              ...values,
              id: crossSale.id,
              order_id: crossSale.order_id,
              status: crossSale.status,
              project_id,
              photo: newPhoto
            }).unwrap());
          navigate(`${RouteNames.CROSS_SALES}?project_id=${project_id}`);
        } else {
          await addCrossSale({
            ...values,
            project_id,
            status: 1,
            photo: newPhoto
          }).unwrap();
          navigate(`${RouteNames.CROSS_SALES}?project_id=${project_id}`);
        }
      } catch (e) {
        const error = (e as { data: { error: { errors: { name: string[] } } } }).data.error.errors
          .name;
        setError(true);
        if (error[0] === 'The name has already been taken.') {
          setErrorText(`${t('alreadyExist')}`);
        } else {
          setErrorText('Something occured!');
        }
      }
    }
  });

  const { editorState, onEditorStateChange, prepareDraft } = useEditorState(
    formik.values.description,
    (value) => formik.setFieldValue('description', value)
  );

  const addPhotoToExistingInEditorHandle = async (photos: Blob[]) => {
    const response =
      crossSale &&
      (await addPhotoToExistingInEditor({ id: crossSale?.id, files: photos }).unwrap());
    if (response?.data?.[0]) {
      return response.data[0].url;
    }
  };
  const addPhotoToNewInEditorHandle = async (photos: Blob[]) => {
    const response = await addPhotoToNewInEditor(photos).unwrap();
    if (response?.data?.[0]) {
      return response.data[0];
    }
  };

  const deletePhotoFromEditorHandle = (url: string) => {
    const startIdx = url.indexOf('uploads/');
    const result = url.substring(startIdx);
    if (crossSale) {
      const deleteImgId = crossSale?.photos_in_text.find((img) => img.url === result)?.id;
      deleteImgId && deletePhotoInEditor({ id: crossSale.id, photo_id: deleteImgId });
    }
  };

  useEffect(() => {
    if (crossSale) {
      const values = {
        name: crossSale.name,
        new_price: crossSale.new_price,
        old_price: crossSale.old_price ?? '',
        internal_id: crossSale.internal_id,
        description: crossSale.description ?? ''
      };
      formik.setValues(values);
      setCrossSaleInitialValues(values);
      setNewPhoto(crossSale.photo);
      onEditorStateChange(prepareDraft(crossSale.description ?? ''));
    }
  }, [crossSale]);

  return (
    <div className={s.body}>
      <div className={s.view}>
        <CrossSaleView
          name={formik.values.name}
          new_price={formik.values.new_price}
          old_price={formik.values.old_price}
          internal_id={formik.values.internal_id}
          photo={newPhoto}
          changePhoto={handleChangePhoto}
          emptyPhoto={emptyPhoto}
        />
      </div>
      <form className={s.form} onSubmit={formik.handleSubmit} id="cross-sale-form">
        <div className={s.fields}>
          <div className={s.field}>
            <Input
              name="name"
              error={!!formik.errors.name}
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder={`${t('crossSalePage.name')}`}
              className={s.name}
            />
          </div>
          <div className={s.main}>
            <div className={s.field}>
              <Input
                name="new_price"
                error={!!formik.errors.new_price}
                onChange={formik.handleChange}
                value={formik.values.new_price}
                placeholder={`${t('price')}`}
              />
            </div>
            <div className={s.field}>
              <Input
                name="old_price"
                error={!!formik.errors.old_price}
                onChange={formik.handleChange}
                value={formik.values.old_price}
                placeholder={`${t('crossSalePage.oldPrice')}`}
              />
            </div>
            <div className={s.field}>
              <Input
                name="internal_id"
                error={!!formik.errors.internal_id}
                onChange={formik.handleChange}
                value={formik.values.internal_id}
                placeholder="ID"
              />
            </div>
          </div>
          <div className={s.description}>
            <TextEditor
              value={editorState}
              setValue={onEditorStateChange}
              error={!!formik.errors.description}
              placeholder={`${t('description')}`}
              addPhotos={crossSale ? addPhotoToExistingInEditorHandle : addPhotoToNewInEditorHandle}
              deletePhoto={deletePhotoFromEditorHandle}
            />
          </div>
        </div>
        <div className={s.btns}>
          <Button type="submit" disabled={isLoading}>
            {crossSale ? t('buttons.edit') : t('buttons.save')}
          </Button>
          <Button onClick={handleCancelCreate} red disabled={isLoading}>
            {t('buttons.cancel')}
          </Button>
        </div>
        {isLoading && <Loading absolute />}
      </form>
      <Notification
        title={errorText}
        open={error}
        onClose={handleCloseError}
        withExclamationIcon
        red
      />
    </div>
  );
};

export default AddCrossSaleForm;
