import React, { useState } from 'react';
import { BackButton } from '../components/ui/Buttons';
import {
  useDeleteIdenticalAdditionalFieldsMutation,
  useGetIdenticalAdditionalFieldsQuery,
  useGetProjectQuery
} from '../store/api';
import { useParams } from 'react-router-dom';
import { Loading } from '../components/ui/Loading';
import { MassBtns } from '../components/modules/IdenticalFieldsPage';
import { AdditionalFieldSpec } from '../components/modules/ScriptPage';
import { useTranslation } from 'react-i18next';

const IdenticalFields: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const project_id = Number(new URLSearchParams(location.search).get('project_id'));
  const { data: project } = useGetProjectQuery(project_id);
  const { data: fields, isLoading: isLoadingOnFields } = useGetIdenticalAdditionalFieldsQuery({
    project_id,
    field_id: Number(id)
  });
  const [deleteIdenticalAdditionalFields, { isLoading: isLoadingOnDelete }] =
    useDeleteIdenticalAdditionalFieldsMutation();
  const nothingFound = !fields?.data.length && !isLoadingOnFields;
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [disabledEditBtn, setDisabledEditBtn] = useState(true);
  const [editModeId, setEditModeId] = useState<number | null>(null);

  const handleSetAllSelectedIds = () => {
    const ids = fields?.data.map((field) => field.script_field_id);
    ids && setSelectedIds(ids);
  };

  const handleClearSelectedIds = () => {
    setSelectedIds([]);
  };

  const handleSelectId = (id: number) => {
    if (selectedIds.includes(id)) {
      const ids = selectedIds.filter((selectedId) => selectedId !== id);
      setSelectedIds(ids);
    } else {
      const ids = [...selectedIds, id];
      setSelectedIds(ids);
    }
  };

  const handleSetEditMode = (id: number) => {
    setEditModeId(id);
    setDisabledEditBtn(false);
  };

  const handleCancelEditMode = () => {
    setEditModeId(null);
    setDisabledEditBtn(true);
  };

  const handleDeleteFields = () => {
    deleteIdenticalAdditionalFields({ project_id, fields_id: selectedIds });
  };

  return (
    <div className="identical-fields page-section" id="identical-fields">
      <div className="container">
        <div className="identical-fields__inner">
          <BackButton backUrl={`/unique-fields?project_id=${project_id}`}>
            {project?.data.name}
          </BackButton>
          {!!fields?.data?.length && (
            <>
              <MassBtns
                onCheckAll={handleSetAllSelectedIds}
                onDeleteSelected={handleDeleteFields}
                onCancel={handleClearSelectedIds}
                isDisabledEditBtn={disabledEditBtn || !selectedIds.length}
                isDisabledDeleteBtn={!selectedIds.length}
              />
              {fields.data.map((field) => {
                return (
                  <AdditionalFieldSpec
                    key={field.script_field_id}
                    field={field}
                    selectedIds={selectedIds}
                    project_id={project_id}
                    onSelect={handleSelectId}
                    onClearSelectedIds={handleClearSelectedIds}
                    editModeId={editModeId}
                    onSetEditMode={handleSetEditMode}
                    onCancelEditMode={handleCancelEditMode}
                  />
                );
              })}
            </>
          )}
          {isLoadingOnFields && <Loading />}
          {isLoadingOnDelete && (
            <div className="loading__wrapper">
              <Loading absolute />
            </div>
          )}
          {nothingFound && <p className="empty">{t('nothingFound')}</p>}
        </div>
      </div>
    </div>
  );
};

export default IdenticalFields;
