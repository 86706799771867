import React from 'react';
import s from './styles.module.scss';
import cn from 'classnames';
import { ReorderCrossSaleBlockProps } from './types';
import { Reorder, useDragControls } from 'framer-motion';
import CrossSaleBlock from './CrossSaleBlock';
import { DropDownIcon } from '../../../ui/Icons';

const ReorderCrossSaleBlock: React.FC<ReorderCrossSaleBlockProps> = ({
  cross_sale,
  selected,
  onClick,
  handleDragEnd
}) => {
  const dragControls = useDragControls();

  const handleDragControls = (e: React.PointerEvent<HTMLLIElement>) => {
    dragControls.start(e);
  };

  return (
    <Reorder.Item
      value={cross_sale}
      drag
      dragListener={false}
      dragControls={dragControls}
      onDragEnd={handleDragEnd}
      className={s.block__wrapper}
    >
      <CrossSaleBlock
        cross_sale={cross_sale}
        onClick={onClick}
        className={cn(s.edit, { [s.selected]: selected })}
      />
      <span className={cn(s.function, { [s.active]: selected })} onPointerDown={handleDragControls}>
        <DropDownIcon />
      </span>
    </Reorder.Item>
  );
};

export default ReorderCrossSaleBlock;
