import React, { useEffect, useState } from 'react';
import { Input } from '../../../ui/Inputs';
import s from './DaysSetting.module.scss';
import { Title } from '../../../ui/Title';
import { useTranslation } from 'react-i18next';
import { useChangeMaxDaysMutation, useGetMaxDaysQuery } from '../../../../store/api';
import { Loading } from '../../../ui/Loading';

const DaysSetting: React.FC = () => {
  const { t } = useTranslation();
  const { data: maxDays } = useGetMaxDaysQuery();
  const [changeMaxDays, { isLoading: isLoadingOnChangeMaxDays }] = useChangeMaxDaysMutation();
  const [daysCount, setDaysCount] = useState(1);
  const [daysCountError, setDaysCountError] = useState(false);

  const handleChangeDaysCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value.replace(/[^0-9]/g, '');
    setDaysCount(value);
  };

  const handleSaveDaysCount = () => {
    if (!daysCountError && daysCount !== Number(maxDays?.data.days)) changeMaxDays(daysCount);
  };

  useEffect(() => {
    if (!daysCount) {
      setDaysCountError(true);
    } else {
      setDaysCountError(false);
    }
  }, [daysCount]);

  useEffect(() => {
    maxDays && setDaysCount(+maxDays.data.days);
  }, [maxDays]);

  return (
    <div className={s.settings}>
      <Title title={t('accountsPage.daysInactivate')} variant={'h4'} />
      {maxDays?.data.days && (
        <div className={s.days}>
          <Input
            className={s.input}
            name={'days-inactive'}
            value={daysCount}
            error={daysCountError}
            onChange={handleChangeDaysCount}
            onBlur={handleSaveDaysCount}
            maxLength={3}
          />
        </div>
      )}
      {isLoadingOnChangeMaxDays && <Loading absolute />}
    </div>
  );
};

export default DaysSetting;
