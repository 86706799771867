import React from 'react';
import { BreadcrumbsProps } from './types';
import s from './Breadcrumbs.module.scss';
import cn from 'classnames';
import { Link } from 'react-router-dom';

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ crumbs, className }) => {
  return (
    <ul className={cn(s.breadcrumbs, className)}>
      {crumbs.map((item, index) => {
        return (
          <li key={index} className={s.item}>
            <Link to={item.href}>
              {item.title}
              {index !== crumbs.length - 1 && <span className={s.separator}>/</span>}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumbs;
