import React, { useState } from 'react';
import s from './styles.module.scss';
import { Title } from '../../../ui/Title';
import { LessonItemProps } from './types';
import cn from 'classnames';
import { RoleBasedComponent } from '../../../roles';
import { EditIcon, TrashCanIcon, VideoIcon } from '../../../ui/Icons';
import { Roles } from '../../../../constants';
import { YesNoModal, YouTubeModal } from '../../../ui/Modals';
import LessonModal from '../Modals/LessonModal/LessonModal';
import { useDeleteEducationMutation } from '../../../../store/api';
import { getLimitedText } from '../../../../utils/getLimitedText';
import { getImage } from '../../../../utils/getImage';
import defaultPhoto from '../../../../assets/images/default.jpg';

const LessonItem: React.FC<LessonItemProps> = ({ lesson, editLesson }) => {
  const { id, title, text, photos, videos } = lesson;
  const [deleteLesson] = useDeleteEducationMutation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [lessonModal, setLessonModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const lessonImg = videos?.[0]?.image_url ?? (photos?.[0]?.url ? getImage(photos[0].url) : undefined);

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseLessonModal = () => {
    setLessonModal(false);
  };

  const handleOpenLessonModal = () => {
    setLessonModal(true);
  };

  const handleCloseVideoModal = () => {
    setVideoModal(false);
  };

  const handleOpenVideoModal = () => {
    setVideoModal(true);
  };

  const handleDeleteLesson = () => {
    deleteLesson(id)
    setDeleteModal(false);
  };

  const handleEditLesson = () => {
    editLesson(id);
  };

  return (
    <>
      <div className={s.item}>
        <div className={s.img__wrapper} onClick={handleOpenVideoModal}>
          <img src={lessonImg ?? defaultPhoto} alt="lesson-img" />
          {videos?.[0]?.image_url && <VideoIcon className={s.video__icon} />}
        </div>
        <div className={s.info} onClick={handleOpenLessonModal}>
          <Title title={title} variant="h3" className={s.title} />
          <div className={cn(s.text, 'htmlView')}>
            <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: getLimitedText(text) }} />
          </div>
        </div>
        <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
          <ul className={s.functions} onClick={(e) => e.stopPropagation()}>
            <li className={s.function} onClick={handleEditLesson}>
              <EditIcon className={s.blueIcon} />
            </li>
            <li className={s.function} onClick={handleOpenDeleteModal}>
              <TrashCanIcon className={s.redIcon} />
            </li>
          </ul>
        </RoleBasedComponent>
      </div>
      {videos?.[0]?.video_id && <YouTubeModal videoId={videos[0].video_id} open={videoModal} onClose={handleCloseVideoModal} />}
      <LessonModal lesson={lesson} open={lessonModal} onClose={handleCloseLessonModal} />
      <YesNoModal
        isDelete
        title={title}
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        onAgree={handleDeleteLesson}
      />
    </>
  );
};

export default LessonItem;
