import React from 'react';
import { PaginationProps } from './types';
import ReactPaginate from 'react-paginate';
import s from './Pagination.module.scss';

const Pagination: React.FC<PaginationProps> = ({ itemsCount, itemsPerPage, onPageChange }) => {
  const pageCount = Math.ceil(itemsCount / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    onPageChange(event.selected + 1);
  };

  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        pageRangeDisplayed={2}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        renderOnZeroPageCount={null}
        containerClassName={s.pagination}
        pageClassName={s.item}
        pageLinkClassName={s.link}
        disabledLinkClassName={s.disabled}
        previousClassName={s.previous}
        nextClassName={s.next}
        activeClassName={s.active}
        breakClassName={s.break}
      />
    </div>
  );
};

const MemoizedPagination = React.memo(Pagination);

export default MemoizedPagination;
