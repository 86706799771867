import React, { useState } from 'react';
import { DatePickProps } from './types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import s from './DatePick.module.scss';

const DatePick: React.FC<DatePickProps> = ({
  changeDate,
  startDay,
  endDay,
  defaultDate,
  className
}) => {
  const initialDate = defaultDate ? new Date(defaultDate) : new Date();
  const [startDate, setStartDate] = useState<Date | null>(initialDate);

  const handleChangeDate = (date: Date | null) => {
    if (date) {
      startDay && date.setHours(0, 0, 0, 0);
      endDay && date.setHours(23, 59, 59);
      setStartDate(date);
      const formattedDate = date.getTime() / 1000;
      changeDate(formattedDate);
    }
  };

  return (
    <div className={className}>
      <DatePicker
        dateFormat="dd MMM yyyy"
        selected={startDate}
        onChange={(date) => handleChangeDate(date)}
        className={s.datepicker}
      />
    </div>
  );
};

const MemoizedDatePick = React.memo(DatePick);

export default MemoizedDatePick;
