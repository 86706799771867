import React, { useState } from 'react';
import s from './MassBtns.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../ui/Buttons';
import { MassBtnsProps } from './types';
import { YesNoModal } from '../../../ui/Modals';

const MassBtns: React.FC<MassBtnsProps> = ({
  onCheckAll,
  onDeleteSelected,
  onCancel,
  isDisabledEditBtn,
  isDisabledDeleteBtn
}) => {
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteSelected = () => {
    onDeleteSelected();
    setDeleteModal(false);
  };

  return (
    <ul className={s.btns__list}>
      <li className={s.btns__item}>
        <Button onClick={onCheckAll}>{t('buttons.selectAll')}</Button>
      </li>
      <li className={s.btns__item}>
        <Button form="add-value-form-spec" type="submit" disabled={isDisabledEditBtn}>
          {t('buttons.saveSelected')}
        </Button>
      </li>
      <li className={s.btns__item}>
        <Button onClick={handleOpenDeleteModal} disabled={isDisabledDeleteBtn}>
          {t('buttons.deleteSelected')}
        </Button>
      </li>
      <li className={s.btns__item}>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
      </li>
      <YesNoModal
        title={'Вибрані поля'}
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        onAgree={handleDeleteSelected}
        isDelete
      />
    </ul>
  );
};

export default MassBtns;
