import React, { useState } from 'react';
import { BackButton, CreateButton } from '../components/ui/Buttons';
import { AddObjectionsForm, ObjectionsItems } from '../components/modules/ObjectionsPage';
import { useTranslation } from 'react-i18next';
import { useAddObjectionMutation, useEditObjectionMutation, useGetObjectionsQuery } from '../store/api';
import { IObjection, IObjectionContent } from '../components/modules/ObjectionsPage/ObjectionsItems/types';
import { Loading } from '../components/ui/Loading';

const Objections: React.FC = () => {
	const { t } = useTranslation();
	const {data, refetch, isLoading: isLoadingOnGet} = useGetObjectionsQuery();
	const [ addObjection, { isLoading: isLoadingOnAdd } ] = useAddObjectionMutation();
  const [ editObjection, { isLoading: isLoadingOnEdit } ] = useEditObjectionMutation();
  const showLoading = isLoadingOnAdd || isLoadingOnEdit;
	const [openObjectionForm, setOpenObjectionForm] = useState(false);
	const [ editedObjection, setEditedObjection ] = useState<IObjection | undefined>(undefined);

	const handleOpenObjectionsFormForm = () => {
    setOpenObjectionForm(true);
  };

	const handleCloseObjectionsForm = () => {
		setEditedObjection(undefined);
    setOpenObjectionForm(false);
  };

	const handleAddObjection = async (values: IObjectionContent[]) => {
		await addObjection(values);
		refetch();
	}

	const handleEditObjection = (values: IObjection) => {
		editObjection(values);
	}

	const handleSetEditObjection = (id: number) => {
    setOpenObjectionForm(true);
		const editedObjection = data?.data.find(objection => objection.id === id);
    setEditedObjection(editedObjection);
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  return <div className='objections page-section' id='objections'>
		<div className="container">
			<div className="objections__inner">
				<BackButton backUrl={'/'}>
					{t('objections')}
				</BackButton>
				{ !openObjectionForm && <CreateButton onClick={handleOpenObjectionsFormForm}>{t('objectionPage.addObjection')}</CreateButton>}
				{ openObjectionForm && <AddObjectionsForm addObjection={handleAddObjection} editObjection={handleEditObjection} onClose={handleCloseObjectionsForm} editedObjection={editedObjection} /> }
				{ !!data?.data?.length && <ObjectionsItems objections={data.data} editObjection={handleSetEditObjection} />}
				{ isLoadingOnGet && <Loading />}
        { showLoading && <div className='loading__wrapper'><Loading absolute /></div>}
			</div>
		</div>
	</div>;
}

export default Objections;
