import React, { useState, useEffect, useRef } from 'react';
import { Modal } from '../../../../ui/Modals';
import { useTranslation } from 'react-i18next';
import { RegulationsModalProps } from './types';
import { InfoBlock, ReorderInfoBlock } from '../../../../ui/InfoBlock';
import { RoleBasedComponent } from '../../../../roles';
import { Button } from '../../../../ui/Buttons';
import s from './RegulationsModal.module.scss';
import { IInfoBlock } from '../../../../ui/InfoBlock/Block/types';
import { Roles } from '../../../../../constants';
import {
  useAddPhotoToExistingRegulationsInEditorMutation,
  useAddPhotoToNewRegulationsInEditorMutation,
  useAddPhotoToRegulationsMutation,
  useAddRegulationsMutation,
  useChangeRegulationsOrdersMutation,
  useDeletePhotoFromRegulationsMutation,
  useDeleteRegulationsMutation,
  useEditRegulationsMutation,
  useGetRegulationsQuery
} from '../../../../../store/api';
import { Loading } from '../../../../ui/Loading';
import diff from '../../../../../libs/htmlDiff';
import { IRegulationsChanges } from '../../ScriptBlock/types';
import { Reorder } from 'framer-motion';
import { IRegulations } from '../../../ProjectsPage/ProjectItems/types';
import { RegulationsOrdersData } from '../../../../../store/api/regulations/types';

const RegulationsModal: React.FC<RegulationsModalProps> = ({
  open,
  onClose,
  project_id,
  changes
}) => {
  const { t } = useTranslation();
  const { data, refetch, isError } = useGetRegulationsQuery(project_id);
  const [addRegulations, { isLoading: isLoadingOnAdd }] = useAddRegulationsMutation();
  const [editRegulations, { isLoading: isLoadingOnEdit }] = useEditRegulationsMutation();
  const [deleteRegulations, { isLoading: isLoadingOnDelete }] = useDeleteRegulationsMutation();
  const [addPhoto, { isLoading: isLoadingOnAddPhoto }] = useAddPhotoToRegulationsMutation();
  const [deletePhoto, { isLoading: isLoadingOnDeletePhoto }] =
    useDeletePhotoFromRegulationsMutation();
  const [addPhotoInEditortoNew] = useAddPhotoToNewRegulationsInEditorMutation();
  const [addPhotoInEditorToExisting] = useAddPhotoToExistingRegulationsInEditorMutation();
  const [changeOrders] = useChangeRegulationsOrdersMutation();

  const showLoading =
    isLoadingOnAdd ||
    isLoadingOnEdit ||
    isLoadingOnDelete ||
    isLoadingOnAddPhoto ||
    isLoadingOnDeletePhoto;

  const [regulations, setRegulations] = useState<IRegulations[]>([]);
  const newOrdersRef = useRef<RegulationsOrdersData[]>([]);
  const [newBlock, setNewBlock] = useState<IInfoBlock>();

  const handleAddBlock = () => {
    const block = {
      id: Date.now(),
      title: '',
      text: '',
      order_id: 1,
      new_photos: []
    };
    setNewBlock(block);
  };

  const deleteBlockHandle = () => {
    if (data?.data?.length !== 0) setNewBlock(undefined);
  };

  const deleteRegulationsHandle = (deleteId: number) => {
    deleteRegulations(deleteId);
  };

  const editRegulationsHandle = (data: IInfoBlock) => {
    editRegulations({
      id: data.id,
      title: data.title,
      text: data.text,
      order_id: data.order_id,
      project_id
    });
  };

  const addRegulationsHandle = async (data: IInfoBlock) => {
    try {
      const response = await addRegulations({
        title: data.title,
        text: data.text,
        project_id
      }).unwrap();
      refetch();
      if (newBlock?.new_photos?.length) {
        const id = response.data.id;
        const project_id = response.data.project_id;
        const files = newBlock.new_photos.map((photo) => photo.photo);
        addPhoto({ data: { id, project_id }, files });
      }
    } finally {
      setNewBlock(undefined);
    }
  };

  // Only if regulation is created
  const addPhotosToExistingHandle = (id: number, photos: Blob[]) => {
    addPhoto({ data: { id, project_id }, files: photos });
  };

  // Only if regulation is created
  const addPhotoInEditorToExistingHandle = async (id: number, photos: Blob[]) => {
    const response = await addPhotoInEditorToExisting({
      data: { id, project_id },
      files: photos
    }).unwrap();
    if (response?.data?.[0]) {
      return response.data[0].url;
    }
  };

  // Only if regulation is created
  const deletePhotoFromExistingHandle = (id: number, photo_id: number) => {
    deletePhoto({ data: { id, project_id }, photo_id });
  };

  // For not-created regulation
  const addPhotosToNewHandle = (id: number, photos: Blob[]) => {
    const files = photos.map((item) => {
      return {
        id: Date.now() + Math.random(),
        photo: item
      };
    });

    const block = newBlock?.new_photos && {
      ...newBlock,
      new_photos: [...newBlock.new_photos, ...files]
    };
    setNewBlock(block);
  };

  // For not-created regulation
  const addPhotoInEditorToNewHandle = async (id: number, photos: Blob[]) => {
    const response = await addPhotoInEditortoNew({ project_id, photos }).unwrap();
    if (response?.data?.[0]) {
      return response.data[0].url;
    }
  };

  // For not-created regulation
  const deletePhotoFromNewHandle = (id: number, photo_id: number) => {
    const photos = newBlock?.new_photos?.filter((photo) => photo.id !== photo_id);
    newBlock && photos?.length && setNewBlock({ ...newBlock, new_photos: photos });
  };

  const handleDragEnd = (result: IRegulations[]) => {
    const orderedItems = regulations.map((item) => {
      const newItem = result.find((newItem) => newItem.id === item.id);
      const newIndex = newItem && result.indexOf(newItem) + 1;
      return { ID: item.id, order_id: newIndex ?? 0 };
    });

    newOrdersRef.current = orderedItems;
    setRegulations(result);
  };

  const handleChangeOrders = () => {
    newOrdersRef.current.length > 0 &&
      changeOrders({ orders: newOrdersRef.current, project_id: regulations[0].project_id });
  };

  const changedValue = (field: 'title' | 'text', blockChanges?: IRegulationsChanges) => {
    const changeType = blockChanges?.[1]
      ? field === 'text'
        ? blockChanges?.[1]
        : blockChanges?.[0]
      : blockChanges?.[0];

    const result = changeType && diff(changeType.old_value, changeType.new_value);

    if (changeType?.field === field) {
      return result;
    } else return undefined;
  };

  const createFixedValues = (block_id: number) => {
    const blockChanges = changes?.find((item) => item.regulation_id === block_id);
    const fixedValues = {
      fixedTitle: changedValue('title', blockChanges),
      fixedText: changedValue('text', blockChanges)
    };
    return fixedValues;
  };

  useEffect(() => {
    if (isError || data?.data?.length === 0) {
      handleAddBlock();
    }
    if (data?.data) {
      const sortedByOrderId = [...data.data].sort((a, b) => a.order_id - b.order_id);
      setRegulations(sortedByOrderId);
    }
  }, [data, isError]);

  return (
    <Modal title={t('projectsPage.regulations')} open={open} onClose={onClose}>
      <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
        <Reorder.Group values={regulations} onReorder={handleDragEnd} className={s.items}>
          {regulations.length
            ? regulations.map((block) => {
                const fixedValues = createFixedValues(block.id);
                const viewedBlock = { ...block, ...fixedValues };

                return (
                  <ReorderInfoBlock
                    key={block.id}
                    withoutImage
                    block={block}
                    viewedBlock={viewedBlock}
                    changeOrders={handleChangeOrders}
                    saveBlock={editRegulationsHandle}
                    removeBlock={deleteRegulationsHandle}
                    addPhoto={addPhotosToExistingHandle}
                    deletePhoto={deletePhotoFromExistingHandle}
                    addPhotoInEditor={addPhotoInEditorToExistingHandle}
                  />
                );
              })
            : null}
        </Reorder.Group>
      </RoleBasedComponent>
      <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
        {newBlock?.id && (
          <InfoBlock
            key={newBlock.id}
            newBlock
            withoutImage
            block={newBlock}
            saveBlock={addRegulationsHandle}
            removeBlock={deleteBlockHandle}
            addPhoto={addPhotosToNewHandle}
            deletePhoto={deletePhotoFromNewHandle}
            addPhotoInEditor={addPhotoInEditorToNewHandle}
          />
        )}
        <div className={s.add}>
          <Button onClick={handleAddBlock} disabled={!!newBlock?.id}>
            {t('addSection')}
          </Button>
        </div>
      </RoleBasedComponent>
      <RoleBasedComponent roles={[Roles.MANAGER]}>
        {regulations.length ? (
          regulations.map((block) => {
            const fixedValues = createFixedValues(block.id);
            const viewedBlock = { ...block, ...fixedValues };

            return (
              <InfoBlock
                key={block.id}
                block={viewedBlock}
                withoutImage
                saveBlock={editRegulationsHandle}
                removeBlock={deleteRegulationsHandle}
                addPhoto={addPhotosToExistingHandle}
                deletePhoto={deletePhotoFromExistingHandle}
                addPhotoInEditor={addPhotoInEditorToExistingHandle}
              />
            );
          })
        ) : (
          <p>{t('emptySection')}</p>
        )}
      </RoleBasedComponent>
      {showLoading && <Loading absolute />}
    </Modal>
  );
};

export default RegulationsModal;
