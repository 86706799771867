import React, { ComponentType, useId } from 'react';
import {
  default as CustomSelect,
  DropdownIndicatorProps,
  GroupBase,
  SingleValue,
  components
} from 'react-select';
import { ISelectOption, SelectProps } from './types';
import { customStyles } from './styles';
import { VerticalArrowIcon } from '../../Icons';

const DropdownIndicator: ComponentType<
  DropdownIndicatorProps<ISelectOption, false, GroupBase<ISelectOption>>
> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <VerticalArrowIcon />
    </components.DropdownIndicator>
  );
};

const Select: React.FC<SelectProps> = ({
  name,
  placeholder,
  options,
  className,
  value,
  onChange,
  styles,
  disabled,
  isSearchable = false
}) => {
  const selectId = useId();

  const handleOnChange = (newValue: SingleValue<ISelectOption>) => {
    if (newValue) {
      onChange && onChange(newValue);
    }
  };

  return (
    <CustomSelect<ISelectOption>
      id={selectId}
      components={{ DropdownIndicator }}
      className={className ?? ''}
      classNamePrefix="select"
      isSearchable={isSearchable}
      name={name}
      placeholder={placeholder ?? null}
      options={options}
      value={value ?? null}
      onChange={handleOnChange}
      styles={styles ?? customStyles}
      isDisabled={disabled}
    />
  );
};

export default Select;
