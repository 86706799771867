import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LoginFormValues } from '../../../components/modules/LoginPage/LoginForm/types';
import { IUser } from '../../../components/modules/AccountsPage/AccountsItems/types';
import { projectsApi } from '../projects/projectsApi';

const API_URL = process.env.REACT_APP_API_URL;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (build) => ({
    loginByToken: build.mutation<{ status: number }, string>({
			query: (auth_token) => ({
        url: '/api/login-by-token',
        method: 'POST',
        body: {
          auth_token
        }
      }),
    }),
    login: build.mutation<{ status: number, user: IUser, token?: string }, LoginFormValues>({
			query: (data) => ({
        url: '/login',
        method: 'POST',
				body: {
          login: data.login,
          password: data.password,
        }
      }),
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(projectsApi.util.invalidateTags(['Projects']));
      }
    }),
    logout: build.mutation<{ status: number }, void>({
      query: () => ({
        url: '/api/logout-user',
        method: 'GET'
      })
    }),
  })
});