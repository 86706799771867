import React, { useContext, useState } from 'react';
import { BackButton, Button, CreateButton } from '../components/ui/Buttons';
import { useTranslation } from 'react-i18next';
import {
  AccountsArchiveModal,
  AccountsBlock,
  AddAccountForm
} from '../components/modules/AccountsPage';
import { Roles } from '../constants';
import { RoleBasedComponent } from '../components/roles';
import {
  useAddUserMutation,
  useEditUserMutation,
  useGetUsersWithProjectsCountQuery
} from '../store/api';
import { Loading } from '../components/ui/Loading';
import { IUser } from '../components/modules/AccountsPage/AccountsItems/types';
import { Notification } from '../components/ui/Modals';
import { SearchContext } from '../context/Search/SearchContext';

const Accounts: React.FC = () => {
  const { t } = useTranslation();
  const { searchQuery } = useContext(SearchContext);
  const { data, refetch, isLoading: isLoadingOnGet } = useGetUsersWithProjectsCountQuery();
  const [addAccount, { isLoading: isLoadingOnAdd }] = useAddUserMutation();
  const [editAccount, { isLoading: isLoadingOnEdit }] = useEditUserMutation();

  const [error, setError] = useState('');
  const [errorNotification, setErrorNotification] = useState(false);
  const showLoading = isLoadingOnAdd || isLoadingOnEdit;
  const [openAccountForm, setOpenAccountForm] = useState(false);
  const [editAccountId, setEditAccountId] = useState<number | undefined>(undefined);
  const [archiveModal, setArchiveModal] = useState(false);

  const handleCloseErrorNotification = () => {
    setErrorNotification(false);
  };

  const handleOpenAccountForm = () => {
    setOpenAccountForm(true);
  };

  const handleCloseAccountForm = () => {
    setEditAccountId(undefined);
    setOpenAccountForm(false);
  };

  const handleCloseArchiveModal = () => {
    setArchiveModal(false);
  };

  const handleOpenArchiveModal = () => {
    setArchiveModal(true);
  };

  const handleAddAccount = async (
    values: Pick<
      IUser,
      'last_name' | 'first_name' | 'middle_name' | 'login' | 'password' | 'role' | 'photo'
    >
  ) => {
    try {
      await addAccount(values).unwrap();
      setOpenAccountForm(false);
    } catch (e) {
      const error = (e as { data: { error: { errors: { login: string[] } } } }).data.error.errors
        .login[0];
      setError(error);
      setErrorNotification(true);
    }
    refetch();
  };

  const handleEditAccount = async (values: IUser) => {
    try {
      await editAccount(values).unwrap();
      setOpenAccountForm(false);
    } catch (e) {
      const error = (e as { data: { error: { errors: string[] } } }).data.error.errors[0];
      setError(error);
      setErrorNotification(true);
    }
  };

  const handleSetEditAccountId = (id: number) => {
    setEditAccountId(id);
    setOpenAccountForm(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const activeAccounts = data?.users.filter((item) => item.status === 1);
  const inactiveAccounts = data?.users.filter((item) => item.status === 2);
  const admins = activeAccounts?.filter((user) => user.role === Roles.ADMIN);
  const managers = activeAccounts?.filter((user) => user.role === Roles.MANAGER);

  const admins_with_search = admins?.filter(
    (user) =>
      user?.login.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      user?.first_name?.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      user?.last_name?.toLowerCase().includes(searchQuery.toLowerCase().trim())
  );
  const managers_with_search = managers?.filter(
    (user) =>
      user?.login.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      user?.first_name?.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      user?.last_name?.toLowerCase().includes(searchQuery.toLowerCase().trim())
  );
  const nothingFound =
    !admins_with_search?.length && !managers_with_search?.length && !!searchQuery;

  return (
    <div className="accounts page-section" id="accounts">
      <div className="container">
        <div className="accounts__inner">
          <BackButton backUrl={'/'}>{t('accounts')}</BackButton>
          {!openAccountForm && (
            <div className="accounts__btns">
              <CreateButton onClick={handleOpenAccountForm}>
                {t('accountsPage.addAccount')}
              </CreateButton>
              <Button onClick={handleOpenArchiveModal}>{t('archive')}</Button>
            </div>
          )}
          {openAccountForm && (
            <AddAccountForm
              addAccount={handleAddAccount}
              editAccount={handleEditAccount}
              onClose={handleCloseAccountForm}
              editAccountId={editAccountId}
            />
          )}
          {!!activeAccounts?.length && (
            <div className="accounts__blocks">
              <RoleBasedComponent roles={[Roles.SUPERADMIN]}>
                {!!admins_with_search?.length && (
                  <AccountsBlock
                    title={t('admins')}
                    accounts={admins_with_search}
                    editAccount={handleSetEditAccountId}
                  />
                )}
              </RoleBasedComponent>
              {!!managers_with_search?.length && (
                <AccountsBlock
                  title={t('managers')}
                  accounts={managers_with_search}
                  editAccount={handleSetEditAccountId}
                  isManager
                />
              )}
              {nothingFound && <p className="empty">{t('nothingFound')}</p>}
            </div>
          )}
          {isLoadingOnGet && <Loading />}
          {showLoading && (
            <div className="loading__wrapper">
              <Loading absolute />
            </div>
          )}
        </div>
      </div>
      <Notification
        title={error}
        open={errorNotification}
        onClose={handleCloseErrorNotification}
        red
        withExclamationIcon
      />
      <AccountsArchiveModal
        accounts={inactiveAccounts?.length ? inactiveAccounts : []}
        open={archiveModal}
        onClose={handleCloseArchiveModal}
      />
    </div>
  );
};

export default Accounts;
