import { StylesConfig } from "react-select";
import { ISelectOption } from "../DefaultSelect/types";

export const customStyles: StylesConfig<ISelectOption> = {
	control: (provided) => ({
		...provided,
		boxShadow: 'none',
		borderRadius: 'none',
		borderColor: 'transparent',
		backgroundColor: 'transparent',
		minHeight: 'auto',
		cursor: 'pointer',
		padding: '2px',
		'&:hover': {
			borderColor: 'transparent',
		}
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '0 3px',
	}),
	singleValue: (provided) => ({
		...provided, 
		color: 'var(--gray)',
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: 'transparent',
		padding: '11px 15px 10px',
		color: state.isSelected ? 'var(--blue-light)' : '#000',
		cursor: 'pointer',
		transition: 'var(--transition)',
		'&:hover': {
			color: 'var(--blue)'
		},
	}),
	indicatorSeparator: (provider) => ({
		...provider,
		display: 'none'
	}),
	dropdownIndicator: (provider) => ({
		...provider,
		padding: '0',
	}),
	menu: (provider) => ({
		...provider,
		boxShadow: 'var(--box-shadow)',
		borderRadius: 'var(--border-radius)',
	}),
	placeholder: (provider) => ({
		...provider,
		color: 'var(--gray-dark)',
	}),

};