import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../ui/Inputs';
import { Button } from '../../../ui/Buttons';
import s from './AddValueForm.module.scss';
import { useFormik } from 'formik';
import { AddValueFormSpecProps, AdditionalFieldsValues } from './types';
import { requireSchema } from '../../../../utils/validate';
import { TextEditor } from '../../../ui/TextEditor';
import {
  useAddPhotoToExistingAdditionalFieldMutation,
  useDeletePhotoFromAdditionaFieldMutation,
  useEditIdenticalAdditionalFieldsMutation
} from '../../../../store/api';
import { useEditorState } from '../../../../hooks';
import { Loading } from '../../../ui/Loading';

const AddValueFormSpec: React.FC<AddValueFormSpecProps> = ({
  editedAdditionalField,
  onClose,
  onClearSelectedIds,
  selectedIds,
  project_id
}) => {
  const { t } = useTranslation();
  const [addPhotoToExisting] = useAddPhotoToExistingAdditionalFieldMutation();
  const [deletePhotoInEditor] = useDeletePhotoFromAdditionaFieldMutation();
  const [editIdenticalAdditionalFields, { isLoading: isLoadingOnEdit }] =
    useEditIdenticalAdditionalFieldsMutation();
  const [fieldInitialValues, setFieldInitialValues] = useState({
    name: '',
    text: ''
  });

  const addPhotoInEditorToRegulationsHandle = async (photos: Blob[]) => {
    const response = await addPhotoToExisting({
      id: editedAdditionalField.script_field_id,
      files: photos
    }).unwrap();
    if (response?.data?.[0]) {
      return response.data[0].url;
    }
  };
  const deletePhotoFromEditorHandle = (url: string) => {
    const startIdx = url.indexOf('uploads/');
    const result = url.substring(startIdx);
    const deleteImgId = editedAdditionalField.photos?.find((img) => img.url === result)?.id;
    deleteImgId &&
      deletePhotoInEditor({ id: editedAdditionalField.script_field_id, photo_id: deleteImgId });
  };

  const formik = useFormik({
    initialValues: fieldInitialValues,
    validationSchema: requireSchema(['name', 'text']),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: Omit<AdditionalFieldsValues, 'addtoproject'>) => {
      const newValues = Object.values(values).map(String);
      const oldValues = Object.values(fieldInitialValues).map(String);
      const isNotChanged = newValues.every((value, index) => value === oldValues[index]);
      if (!isNotChanged) {
        editIdenticalAdditionalFields({
          project_id,
          fields_id: selectedIds,
          order_id: editedAdditionalField.order_id,
          ...values
        }).then(() => {
          onClose();
          onClearSelectedIds();
        });
      } else {
        onClose();
        onClearSelectedIds();
      }
    }
  });

  const { editorState, onEditorStateChange, prepareDraft } = useEditorState(
    formik.values.text,
    (value) => formik.setFieldValue('text', value)
  );

  useEffect(() => {
    if (editedAdditionalField) {
      const values = {
        name: editedAdditionalField.name,
        text: editedAdditionalField.text,
        addtoproject: false
      };
      formik.setValues(values);
      setFieldInitialValues(values);
      onEditorStateChange(prepareDraft(editedAdditionalField.text));
    }
  }, [editedAdditionalField]);

  return (
    <form className={s.form} onSubmit={formik.handleSubmit} id="add-value-form-spec">
      <div className={s.fields}>
        <div className={s.field}>
          <Input
            name="name"
            error={!!formik.errors.name}
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder={`${t('script.value')}`}
            className={s.name}
          />
        </div>
        <div className={s.field}>
          <TextEditor
            value={editorState}
            setValue={onEditorStateChange}
            error={!!formik.errors.text}
            placeholder={`${t('description')}`}
            addPhotos={addPhotoInEditorToRegulationsHandle}
            deletePhoto={deletePhotoFromEditorHandle}
          />
        </div>
      </div>
      <div className={s.btns}>
        <Button onClick={onClose} red>
          {t('buttons.cancel')}
        </Button>
      </div>
      {isLoadingOnEdit && <Loading absolute />}
    </form>
  );
};

export default AddValueFormSpec;
