import React, { useEffect, useState } from 'react';
import { AddLessonPhotoModalProps } from './types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { AddPhotoButton } from '../../../../ui/Buttons';
import s from './AddLessonPhotoModal.module.scss';
import { Photo } from '../../../../ui/Photo';
import { Modal, YouTubeModal } from '../../../../ui/Modals';
import { getImage } from '../../../../../utils/getImage';
import {
  useAddPhotoToEducationMutation,
  useDeletePhotoFromEducationMutation
} from '../../../../../store/api';
import { VideoIcon } from '../../../../ui/Icons';

const AddLessonPhotoModal: React.FC<AddLessonPhotoModalProps> = ({
  open,
  onClose,
  lesson_id,
  photos,
  video,
  newVideo,
  newPhotos,
  setNewPhotos
}) => {
  const { t } = useTranslation();
  const [addPhotos] = useAddPhotoToEducationMutation();
  const [deletePhoto] = useDeletePhotoFromEducationMutation();
  const [videoModal, setVideoModal] = useState(false);
  const [videoId, setVideoId] = useState<string>();
  const [blobUrls, setBlobUrls] = useState<{ id: number; photo: string }[]>([]);

  const handleCloseVideoModal = () => {
    setVideoModal(false);
  };

  const handleOpenVideoModal = (id: string) => {
    setVideoModal(true);
    setVideoId(id);
  };

  const handleAddPhotos = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const photos = Array.from(target.files || []);
    if (!lesson_id) {
      const files = photos.map((item) => {
        return {
          id: Date.now() + Math.random(),
          photo: item
        };
      });
      setNewPhotos([...newPhotos, ...files]);
    } else {
      addPhotos({ id: lesson_id, files: photos });
    }
  };

  const handleDeletePhoto = (idDelete: number) => {
    if (!lesson_id) {
      const filteredPhotos = newPhotos.filter((photo) => photo.id !== idDelete);
      const newBlobUrls = blobUrls.filter((photo) => photo.id !== idDelete);
      setNewPhotos(filteredPhotos);
      setBlobUrls(newBlobUrls);
    } else {
      deletePhoto({ id: lesson_id, photo_id: idDelete });
    }
  };

  useEffect(() => {
    const newUrls = newPhotos.map((photo) => {
      if (photo.photo instanceof Blob) {
        return {
          id: photo.id,
          photo: URL.createObjectURL(photo.photo)
        };
      } else if (typeof photo.photo === 'string') {
        return {
          id: photo.id,
          photo: photo.photo
        };
      } else {
        return {
          id: photo.id,
          photo: photo.photo
        };
      }
    });

    newUrls && setBlobUrls((prev) => [...prev, ...newUrls]);

    return () => {
      newUrls?.forEach((photo) => URL.revokeObjectURL(photo.photo));
      setBlobUrls((prev) => prev.filter((url) => !newUrls?.includes(url)));
    };
  }, [newPhotos]);

  return (
    <>
      <Modal title={t('photo')} open={open} onClose={onClose}>
        <div className={s.photo}>
          <div className={s.photo__content}>
            {video && (
              <div
                className={s.imageWithFilter__wrapper}
                onClick={() => handleOpenVideoModal(video.video_id)}
              >
                <div className={cn(s.imageWithFilter, s.video)}>
                  <img src={video.image_url} className={s.imageWithFilter} alt="video-img" />
                  <VideoIcon className={s.video__icon} />
                </div>
              </div>
            )}
            {newVideo && (
              <div
                className={s.imageWithFilter__wrapper}
                onClick={() => handleOpenVideoModal(newVideo.video_id)}
              >
                <div className={cn(s.imageWithFilter, s.video)}>
                  <img src={newVideo.image_url} className={s.imageWithFilter} alt="video-img" />
                  <VideoIcon className={s.video__icon} />
                </div>
              </div>
            )}
            {!!photos?.length &&
              photos.map((img) => {
                return (
                  <div className={s.imageWithFilter__wrapper} key={img.id}>
                    <Photo
                      img={getImage(img.url)}
                      className={s.imageWithFilter}
                      isAdmin
                      onDelete={() => handleDeletePhoto(img.id)}
                    />
                  </div>
                );
              })}
            {!!blobUrls?.length &&
              blobUrls.map((img) => {
                return (
                  <div className={s.imageWithFilter__wrapper} key={img.id}>
                    <Photo
                      img={img.photo}
                      className={s.imageWithFilter}
                      isAdmin
                      onDelete={() => handleDeletePhoto(img.id)}
                    />
                  </div>
                );
              })}
            <AddPhotoButton className={s.addPhoto__btn} multi changePhoto={handleAddPhotos} />
          </div>
        </div>
      </Modal>
      <YouTubeModal videoId={videoId ?? ''} open={videoModal} onClose={handleCloseVideoModal} />
    </>
  );
};

export default AddLessonPhotoModal;
