import { additionalFieldsApi } from './additionalFields/additionalFieldsApi';
import { authApi } from './auth/authApi';
import { educationApi } from './education/educationApi';
import { importantInfoApi } from './importantInfo/importantInfoApi';
import { managerBookApi } from './managerBook/managerBookApi';
import { objectionsApi } from './objections/objectionsApi';
import { projectsApi } from './projects/projectsApi';
import { regulationsApi } from './regulations/regulationsApi';
import { scriptsApi } from './scripts/scriptsApi';
import { usersApi } from './users/usersApi';
import { scriptUpdatesApi } from './scriptUpdates/scriptUpdatesApi';
import { crossSalesApi } from './crossSales/crossSalesApi';
import { loggingApi } from './logging/loggingApi';

export const reducers = {
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [regulationsApi.reducerPath]: regulationsApi.reducer,
  [scriptsApi.reducerPath]: scriptsApi.reducer,
  [scriptUpdatesApi.reducerPath]: scriptUpdatesApi.reducer,
  [additionalFieldsApi.reducerPath]: additionalFieldsApi.reducer,
  [importantInfoApi.reducerPath]: importantInfoApi.reducer,
  [managerBookApi.reducerPath]: managerBookApi.reducer,
  [objectionsApi.reducerPath]: objectionsApi.reducer,
  [educationApi.reducerPath]: educationApi.reducer,
  [crossSalesApi.reducerPath]: crossSalesApi.reducer,
  [loggingApi.reducerPath]: loggingApi.reducer
};

export const middlewares = [
  authApi.middleware,
  usersApi.middleware,
  projectsApi.middleware,
  regulationsApi.middleware,
  scriptsApi.middleware,
  scriptUpdatesApi.middleware,
  additionalFieldsApi.middleware,
  importantInfoApi.middleware,
  managerBookApi.middleware,
  objectionsApi.middleware,
  educationApi.middleware,
  crossSalesApi.middleware,
  loggingApi.middleware
];

export const { useLoginByTokenMutation, useLoginMutation, useLogoutMutation } = authApi;

export const {
  useGetScriptsQuery,
  useGetAllScriptsQuery,
  useGetScriptQuery,
  useAddScriptMutation,
  useEditScriptMutation,
  useDeleteScriptMutation,
  useCopyScriptMutation,
  useCopyScriptToOtherProjectMutation,
  useUploadScriptPhotoMutation,
  useUploadScriptVideoMutation,
  useDeleteScriptPhotoMutation,
  useDeleteScriptVideoMutation,
  useBlockScriptMutation,
  useCloseScriptMutation,
  useChangeScriptsOrdersMutation
} = scriptsApi;

export const {
  useGetUpdatesQuery,
  useViewScriptUpdateMutation,
  useViewFieldUpdateMutation,
  useViewRegulationsUpdateMutation,
  useViewInfoUpdateMutation,
  useViewAllUpdatesMutation
} = scriptUpdatesApi;

export const {
  useGetAdditionalFieldsQuery,
  useGetAdditionalFieldQuery,
  useAddAdditionalFieldMutation,
  useEditAdditionalFieldMutation,
  useDeleteAdditionalFieldMutation,
  useAddPhotoToNewAdditionalFieldMutation,
  useAddPhotoToExistingAdditionalFieldMutation,
  useDeletePhotoFromAdditionaFieldMutation,
  useChangeAdditionalFieldsOrdersMutation,
  useGetUniqAdditionalFieldsQuery,
  useGetIdenticalAdditionalFieldsQuery,
  useEditIdenticalAdditionalFieldsMutation,
  useDeleteIdenticalAdditionalFieldsMutation
} = additionalFieldsApi;

export const {
  useGetCrossSaleQuery,
  useAddCrossSaleMutation,
  useEditCrossSaleMutation,
  useDeleteCrossSaleMutation,
  useCopyCrossSaleMutation,
  useBlockCrossSaleMutation,
  useGetProjectCrossSalesQuery,
  useGetProjectRelatedCrossSalesQuery,
  useGetScriptCrossSalesQuery,
  useChangeCrossSalesMutation,
  useChangeCrossSalesOrdersMutation,
  useAddPhotoToExistingCrossInEditorMutation,
  useAddPhotoToNewCrossInEditorMutation,
  useDeletePhotoFromCrossInEditorMutation
} = crossSalesApi;

export const {
  useGetImportantInfoQuery,
  useAddImportantInfoMutation,
  useEditImportantInfoMutation,
  useDeleteImportantInfoMutation,
  useAddImportantInfoPhotoMutation,
  useDeleteImportantInfoPhotoMutation,
  useAddPhotoToExistingInfoInEditorMutation,
  useAddPhotoToNewInfoInEditorMutation,
  useChangeInfoOrdersMutation
} = importantInfoApi;

export const {
  useGetProjectsQuery,
  useGetProjectsWithManagerSelectedQuery,
  useGetProjectQuery,
  useAddProjectMutation,
  useEditProjectMutation,
  useDeleteProjectMutation,
  useCopyProjectMutation,
  useBlockProjectMutation,
  useChangeProjectsOrdersMutation,
  useAddToManagerMutation,
  useDeleteFromManagerMutation,
  useEditCRMUrlMutation
} = projectsApi;

export const {
  useGetRegulationsQuery,
  useAddRegulationsMutation,
  useAddPhotoToRegulationsMutation,
  useDeletePhotoFromRegulationsMutation,
  useAddPhotoToExistingRegulationsInEditorMutation,
  useAddPhotoToNewRegulationsInEditorMutation,
  useEditRegulationsMutation,
  useDeleteRegulationsMutation,
  useChangeRegulationsOrdersMutation
} = regulationsApi;

export const {
  useGetUsersQuery,
  useGetUsersWithProjectsCountQuery,
  useGetUserQuery,
  useGetFullUserQuery,
  useLogoutUserMutation,
  useAddUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useUploadUserPhotoMutation,
  useDeleteMacMutation,
  useChangeLangMutation,
  useActivateUserMutation,
  useInactivateUserMutation,
  useAdminInactivateUserMutation,
  useGetMaxDaysQuery,
  useChangeMaxDaysMutation
} = usersApi;

export const {
  useGetBooksQuery,
  useAddBookMutation,
  useAddPhotoToBookMutation,
  useDeletePhotoFromBookMutation,
  useAddPhotoToExistingBookInEditorMutation,
  useAddPhotoToNewBookInEditorMutation,
  useEditBookMutation,
  useDeleteBookMutation
} = managerBookApi;

export const {
  useGetObjectionsQuery,
  useAddObjectionMutation,
  useEditObjectionMutation,
  useDeleteObjectionMutation,
  useChangeObjectionsOrdersMutation
} = objectionsApi;

export const {
  useGetEducationQuery,
  useGetLessonQuery,
  useAddEducationMutation,
  useAddPhotoToEducationMutation,
  useDeletePhotoFromEducationMutation,
  useAddPhotoToExistingEducationInEditorMutation,
  useAddPhotoToNewEducationInEditorMutation,
  useEditEducationMutation,
  useDeleteEducationMutation
} = educationApi;

export const { useGetAllLoggingQuery, useGetDetailedLoggingQuery } = loggingApi;
