import React, { useState } from 'react';
import { ScriptsCountProps } from './types';
import s from './ScriptsCount.module.scss';
import { Select } from '../../../ui/Select';
import { ISelectOption } from '../../../ui/Select/DefaultSelect/types';
import { SingleValue } from 'react-select';

const ScriptsCount: React.FC<ScriptsCountProps> = ({ total, page, perPage, onCountChange }) => {
  const options = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ];
  const [count, setCount] = useState<ISelectOption | null>({ label: '25', value: 25 });

  const handleChangeCount = (newCount: SingleValue<ISelectOption>) => {
    newCount && onCountChange(newCount.value);
    setCount(newCount);
  };

  const countLabel = total - page * perPage <= 0 ? total : page * perPage;

  return (
    <div className={s.wrapper}>
      <div className={s.title}>Скриптов на странице</div>
      <Select
        name="count"
        value={count}
        onChange={handleChangeCount}
        options={options}
        className="spec-select"
      />
      <div className={s.count}>
        {countLabel} из {total} скриптов
      </div>
    </div>
  );
};

const MemoizedScriptsCount = React.memo(ScriptsCount);

export default MemoizedScriptsCount;
