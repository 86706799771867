import React from 'react';
import LessonItem from './LessonItem';
import { LessonItemsProps } from './types';
import s from './styles.module.scss';

const LessonItems: React.FC<LessonItemsProps> = ({lessons, editLesson}) => {
  return <div className={s.items}>
		{lessons.map(lesson => {
      return <LessonItem key={lesson.id} lesson={lesson} editLesson={editLesson} />
    })}
	</div>;
}

export default LessonItems;
