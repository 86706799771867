import React from 'react';
import { LoginForm } from '../components/modules/LoginPage';
import { LogoIconDark } from '../components/ui/Icons';
import { LanguageSelect } from '../components/ui/Select';

const Login: React.FC = () => {
  return (
    <div className="login" id="login">
      <div className="change-lang">
        <LanguageSelect isSiteLangSelect />
      </div>
      <div className="login__inner">
        <LogoIconDark className="logo" />
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
