import { useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue?: T) => {
  const [storedValue, setValue] = useState<T | undefined>(() => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  });

  const setStoredValue = (value: typeof storedValue) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    localStorage.setItem(key, JSON.stringify(valueToStore));
    setValue(valueToStore);
  };

  const removeStoredValue = () => {
    setStoredValue(initialValue);
  };

  return {
    storedValue,
    setStoredValue,
    removeStoredValue
  };
};
