import React, { useContext, useState } from 'react';
import { BackButton, Button, CreateButton } from '../components/ui/Buttons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Roles, RouteNames, projectTabs } from '../constants';
import {
  useGetProjectCrossSalesQuery,
  useGetProjectQuery,
  useGetProjectRelatedCrossSalesQuery
} from '../store/api';
import { Loading } from '../components/ui/Loading';
import { RoleBasedComponent } from '../components/roles';
import { Tabs } from '../components/ui/Tabs';
import { CrossSaleItems } from '../components/modules/CrossSalePage';
import { SearchContext } from '../context/Search/SearchContext';
import { IUser } from '../components/modules/AccountsPage/AccountsItems/types';
import { RegulationsModal } from '../components/modules/ScriptPage';

const CrossSales: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const savedUser = localStorage.getItem('user');
  const user: Omit<IUser, 'password'> = savedUser && JSON.parse(savedUser);
  const managerIdent = user?.role === Roles.MANAGER;
  const project_id = Number(new URLSearchParams(location.search).get('project_id'));
  const { data: project } = useGetProjectQuery(project_id);
  const { data: crossSales, isLoading: isLoadingOnCrossSales } = useGetProjectCrossSalesQuery(
    project_id,
    { skip: managerIdent }
  );
  const { data: related_crossSales, isLoading: isLoadingOnRelatedCrossSales } =
    useGetProjectRelatedCrossSalesQuery(project_id, { skip: !managerIdent });
  const isLoading = isLoadingOnCrossSales || isLoadingOnRelatedCrossSales;
  const { searchQuery } = useContext(SearchContext);
  const loadCrossSales = crossSales || related_crossSales;
  const cross_sales_with_search = loadCrossSales?.data.filter(
    (cros_sale) =>
      cros_sale.name.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      cros_sale.internal_id.toLowerCase().includes(searchQuery.toLowerCase().trim())
  );

  const [regulationModal, setRegulationModal] = useState(false);

  const handleCreateCrossSale = () => {
    navigate(RouteNames.CROSS_SALE + `/create?project_id=${project_id}`);
  };

  const handleOpenRegulationModal = () => {
    setRegulationModal(true);
  };

  const handleCloseRegulationModal = () => {
    setRegulationModal(false);
  };

  const tabs = projectTabs(t, project_id);

  return (
    <div className="crossSales page-section" id="crossSales">
      <div className="container">
        <div className="crossSales__inner">
          <BackButton backUrl={'/projects'}>{project?.data.name}</BackButton>
          <Button onClick={handleOpenRegulationModal} className="regulations-btn" red>
            {t('projectsPage.regulations')}
          </Button>
          <Tabs tabs={tabs} defaultTab={1} />
          <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
            <CreateButton onClick={handleCreateCrossSale}>
              {t('crossSalesPage.createCrossSale')}
            </CreateButton>
          </RoleBasedComponent>
          {!!cross_sales_with_search?.length && (
            <CrossSaleItems crossSales={cross_sales_with_search} />
          )}
          {!cross_sales_with_search?.length && searchQuery && (
            <p className="empty">{t('nothingFound')}</p>
          )}
          {!loadCrossSales?.data.length && !isLoadingOnCrossSales && !searchQuery && (
            <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
              <p className="empty">{t('crossSalesPage.createFirstCrossSale')}</p>
            </RoleBasedComponent>
          )}
          {isLoading && <Loading />}
        </div>
      </div>
      <RegulationsModal
        project_id={project_id}
        open={regulationModal}
        onClose={handleCloseRegulationModal}
      />
    </div>
  );
};

export default CrossSales;
