import React from 'react';
import { Icon } from './types';

const UserIcon: React.FC<Icon> = ({ className }) => {
  return (
    <svg
      className={className ?? ''}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          d="M16.9992 7.28613C15.7984 7.28613 14.6245 7.64222 13.6261 8.30935C12.6276 8.97649 11.8494 9.92472 11.3899 11.0341C10.9304 12.1435 10.8101 13.3643 11.0444 14.542C11.2787 15.7198 11.8569 16.8016 12.706 17.6507C13.5551 18.4998 14.6369 19.0781 15.8147 19.3123C16.9924 19.5466 18.2132 19.4264 19.3226 18.9668C20.432 18.5073 21.3802 17.7291 22.0474 16.7307C22.7145 15.7322 23.0706 14.5584 23.0706 13.3576C23.0706 11.7473 22.4309 10.203 21.2923 9.06441C20.1537 7.9258 18.6094 7.28613 16.9992 7.28613Z"
          fill="#8F8F8F"
        />
        <path
          d="M17 0C13.6377 0 10.3509 0.997033 7.55531 2.86502C4.75968 4.733 2.58074 7.38804 1.29406 10.4944C0.00736581 13.6007 -0.329291 17.0189 0.326658 20.3165C0.982606 23.6142 2.6017 26.6433 4.97919 29.0208C7.35668 31.3983 10.3858 33.0174 13.6835 33.6733C16.9811 34.3293 20.3993 33.9926 23.5056 32.7059C26.612 31.4193 29.267 29.2403 31.135 26.4447C33.003 23.6491 34 20.3623 34 17C33.9949 12.4929 32.2021 8.17187 29.0151 4.98486C25.8281 1.79786 21.5071 0.00514216 17 0ZM26.7046 27.8387C26.6804 26.246 26.0314 24.7265 24.8976 23.6076C23.7638 22.4888 22.2358 21.8601 20.6429 21.8571H13.3571C11.7642 21.8601 10.2362 22.4888 9.1024 23.6076C7.96856 24.7265 7.31964 26.246 7.29544 27.8387C5.09341 25.8725 3.54054 23.2838 2.84245 20.4154C2.14436 17.547 2.33398 14.5342 3.38621 11.776C4.43844 9.01774 6.30363 6.64417 8.73481 4.96954C11.166 3.29492 14.0485 2.39825 17.0006 2.39825C19.9527 2.39825 22.8352 3.29492 25.2664 4.96954C27.6976 6.64417 29.5628 9.01774 30.615 11.776C31.6672 14.5342 31.8569 17.547 31.1588 20.4154C30.4607 23.2838 28.9066 25.8725 26.7046 27.8387Z"
          fill="#707070"
        />
      </g>
    </svg>
  );
};

export default UserIcon;
