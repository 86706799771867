import React, { useContext } from 'react';
import { AuthContext } from '../../context/Auth/AuthContext';

interface RoleBasedComponent {
  roles: number[];
  children: React.ReactNode;
}

const RoleBasedComponent: React.FC<RoleBasedComponent> = ({roles, children}) => {
	const { user } = useContext(AuthContext);
	const currentUserRole = user?.role;

	if(currentUserRole && !roles.includes(currentUserRole)) {
		return null;
	}

  return <>{children}</>;
};

export default RoleBasedComponent;
