import { createContext } from 'react';

interface ISearchContext {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

export const SearchContext = createContext<ISearchContext>({
  searchQuery: '',
  setSearchQuery: () => undefined,
});