import React, { useEffect, useRef, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Title } from '../../Title';
import { CloseFillIcon, ExclamationIcon } from '../../Icons';
import { NotificationProps } from './types';
import cl from 'classnames/bind';
import s from './Notification.module.scss';

const cx = cl.bind(s);

const modalElement = document.querySelector('#modal');

const Notification: React.FC<NotificationProps> = ({
  title,
  text,
  position,
  className,
  red,
  withExclamationIcon,
  withoutCloseIcon,
  timeOutMs,
  open,
  onClose
}) => {
  const element = useMemo(() => document.createElement('div'), []);
  const intervalId = useRef<NodeJS.Timer>();
  const [pause, setPause] = useState(false);
  const [timer, setTimer] = useState(timeOutMs ?? 5000);

  const onSetPause = (active: boolean) => () => setPause(active);

  useEffect(() => {
    if (open) {
      modalElement?.appendChild(element);
      if (!pause) {
        intervalId.current = setInterval(() => setTimer((prev) => prev - 1000), 1000);
      } else {
        clearInterval(intervalId.current);
      }

      if (timer === 0) {
        onClose();
        timeOutMs ? setTimer(timeOutMs) : setTimer(5000);
      }
      return () => {
        clearInterval(intervalId.current);
        modalElement?.removeChild(element);
      };
    }
  });

  const modalBody = (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            ease: 'linear',
            duration: 0.5
          }}
          style={{ overflow: 'hidden', position: 'absolute', zIndex: 3 }}>
          <div
            className={cx('modal', position ?? 'top-right', red && 'red', className)}
            onClick={onClose}>
            <div
              className={s.content}
              onClick={(e) => e.stopPropagation()}
              onMouseEnter={onSetPause(true)}
              onMouseLeave={onSetPause(false)}>
              <div className={s.header}>
                {withExclamationIcon && (
                  <div className={s.exclamationIcon}>
                    <ExclamationIcon />
                  </div>
                )}
                <Title className={s.title} variant="h2" title={title || 'Attention!'} />
                {!withoutCloseIcon && <button className={s.closeBtn} onClick={onClose}>
                  <CloseFillIcon className={s.close__icon} />
                </button>}
              </div>
              {text && <p className={s.text}>{text}</p>}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return createPortal(modalBody, element);
};

export default Notification;
