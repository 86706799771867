import React from "react";
import cn from "classnames";
import s from './Button.module.scss';
import { ButtonProps } from "./types";

const Button: React.FC<ButtonProps> = ({className, outlined, red, children, type='button', ...props}) => {
  return (
    <button
      className={cn(s.button, className, {
        [s.outlined]: outlined,
        [s.red]: red,
      })}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
}

export default Button;
