import React, { useEffect, useRef, useState } from 'react';
import s from './styles.module.scss';
import { CrossSaleItemsProps, ICrossSale } from './types';
import CrossSaleItem from './CrossSaleItem';
import { useBlockCrossSaleMutation, useChangeCrossSalesOrdersMutation, useCopyCrossSaleMutation, useDeleteCrossSaleMutation } from '../../../../store/api';
import { Roles, Status } from '../../../../constants';
import { Loading } from '../../../ui/Loading';
import { RoleBasedComponent } from '../../../roles';
import { CrossSalesOrdersData } from '../../../../store/api/crossSales/types';
import { Reorder } from 'framer-motion';

const CrossSaleItems: React.FC<CrossSaleItemsProps> = ({crossSales}) => {
  const [changeOrders] = useChangeCrossSalesOrdersMutation();
  const [blockedCrossSales, setBlockedCrossSales] = useState<ICrossSale[]>([]);
  const [activeCrossSales, setActiveCrossSales] = useState<ICrossSale[]>([]);
  const [deleteCrossSale, {isLoading: isLoadingOnDelete}] = useDeleteCrossSaleMutation();
  const [copyCrossSale, {isLoading: isLoadingOnCopy}] = useCopyCrossSaleMutation();
  const [blockCrossSale, {isLoading: isLoadingOnBlock}] = useBlockCrossSaleMutation();
  const showLoading = isLoadingOnDelete || isLoadingOnCopy || isLoadingOnBlock;
  const newOrdersRef = useRef<CrossSalesOrdersData[]>([]);

  const handleDeleteCrossSale = (id: number) => {
    deleteCrossSale(id);
  }

  const handleCopyCrossSale = (id: number) => {
    copyCrossSale(id);
  }

  const handleBlockCrossSale = (id: number) => {
    blockCrossSale(id);
  }

  const handleDragEnd = (result: ICrossSale[]) => {
    const orderedItems = activeCrossSales.map((item) => {
      const newItem = result.find((newItem) => newItem.id === item.id);
      const newIndex = newItem && crossSales.length - result.indexOf(newItem) + 1;
      return { ID: item.id, order_id: newIndex ?? 0 };
    });

    newOrdersRef.current = orderedItems;
    setActiveCrossSales(result);
  };

  const handleChangeOrders = () => {
    newOrdersRef.current.length > 0 && changeOrders({orders: newOrdersRef.current, project_id: crossSales[0].project_id });
  }

  useEffect(() => {
    setActiveCrossSales(crossSales.filter((item) => item.status === Status.ACTIVE).sort((a, b) => b.order_id - a.order_id));
    setBlockedCrossSales(crossSales.filter((item) => item.status === Status.BLOCKED).sort((a, b) => b.order_id - a.order_id));
  }, [crossSales]);

  return <div className={s.items}>
    <Reorder.Group values={activeCrossSales} onReorder={handleDragEnd} className={s.items}>
      {activeCrossSales.map(crossSale => {
        return <CrossSaleItem key={crossSale.id} crossSale={crossSale} changeOrders={handleChangeOrders} deleteCrossSale={handleDeleteCrossSale} copyCrossSale={handleCopyCrossSale} blockCrossSale={handleBlockCrossSale} />
      })}
    </Reorder.Group>
    <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
      <Reorder.Group values={blockedCrossSales} onReorder={handleDragEnd} className={s.items}>
        {blockedCrossSales.map(CrossSale => {
          return <CrossSaleItem key={CrossSale.id} crossSale={CrossSale} changeOrders={handleChangeOrders} deleteCrossSale={handleDeleteCrossSale} copyCrossSale={handleCopyCrossSale} blockCrossSale={handleBlockCrossSale} />
        })}
      </Reorder.Group>
    </RoleBasedComponent>
    {showLoading && <Loading absolute />}
	</div>;
}

export default CrossSaleItems;
