import { useRoutes } from 'react-router-dom';
import { routes } from '../components/routes';
import { IUser } from '../components/modules/AccountsPage/AccountsItems/types';

export const useRouterConfig = () => {
  const savedUser = localStorage.getItem('user');
  const user: Omit<IUser, 'password'> = savedUser && JSON.parse(savedUser);
  const role = user?.role ?? null;
  const config = routes(role);

  return useRoutes(config);
};