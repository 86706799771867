import React, { useEffect, useState } from 'react';
import s from './ScriptView.module.scss';
import { Title } from '../../../ui/Title';
import { ScriptViewProps } from './types';
import { AddPhotoButton } from '../../../ui/Buttons';
import { getImage } from '../../../../utils/getImage';
import { useTranslation } from 'react-i18next';

const ScriptView: React.FC<ScriptViewProps> = ({ internal_id, name, price, articul, photo, openPhotoModal }) => {
  const { t } = useTranslation();
  const [blobUrl, setBlobUrl] = useState('');
  const previewPhoto = typeof photo === 'string' ? getImage(photo) : blobUrl;
  const currency = !price?.includes('$') ? " Грн." : "";

  useEffect(() => {
    const url = photo && (photo instanceof Blob) && URL.createObjectURL(photo);
    url && setBlobUrl(url);
    return () => {
      url && URL.revokeObjectURL(url);
    };
  }, [photo]);


  return (
    <div className={s.block}>
			<div className={s.photo}>
				{
          previewPhoto
          ? <img src={previewPhoto} alt={'account-img'} onClick={openPhotoModal} />
          : <AddPhotoButton onClick={openPhotoModal} inputDisabled />
        }
			</div>
      <div className={s.info}>
        <Title title={name ? name : '-'} variant="h3" className={s.name} />
        <ul className={s.list}>
          <li className={s.list__item}>
            { price ? <><span className={s.header}>{t('price')}:</span> <span>{price + currency}</span></> : '-'}
          </li>
          { articul && <li className={s.list__item}>
           <><span className={s.header}>{t('articul')}:</span> <span>{articul}</span></>
          </li> }
          <li className={s.list__item}>
            { internal_id ? <><span className={s.header}>ID:</span> <span>{internal_id}</span></> : '-'}
          </li>
        </ul>
      </div>
    </div>
  );
};

const MemoizedScriptView = React.memo(ScriptView);

export default MemoizedScriptView;