import React from 'react';
import { Icon } from './types';

const ShortHorizontalArrowIcon: React.FC<Icon> = ({ className, path }) => {
  return (
    <svg
      className={className ?? ''}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.13125 6.88477H0V5.50977H9.13125L4.93125 1.65977L6 0.697266L12 6.19727L6 11.6973L4.93125 10.7348L9.13125 6.88477Z"
        fill="#2881B5"
        stroke="#2881B5"
        strokeWidth="0.5"
        {...path}
      />
    </svg>
  );
};

export default ShortHorizontalArrowIcon;
