import React from 'react';
import { Icon } from './types';

const CheckIcon: React.FC<Icon> = ({ className, path }) => {
  return (
    <svg
      className={className ?? ''}
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.59509 12L0 6.31185L1.39877 4.88981L5.59509 9.15592L14.6012 0L16 1.42204L5.59509 12Z"
        fill="#2881B5"
        {...path}
      />
    </svg>
  );
};

export default CheckIcon;
