import { createApi } from '@reduxjs/toolkit/query/react';
import { ManagerBookAddPhotoData, ManagerBookDeletePhotoData } from './types';
import { IManagerBook } from '../../../components/modules/ManagerBook/types';
import { configBaseQuery } from '../config';

export const managerBookApi = createApi({
  reducerPath: 'managerBookApi',
  tagTypes: ['ManagerBook'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getBooks: build.query<{ status: number; data: IManagerBook[] }, void>({
      query: () => `/api/books`,
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'ManagerBook', id })) : []
    }),
    addBook: build.mutation<
      { status: number; data: IManagerBook },
      Pick<IManagerBook, 'title' | 'text'>
    >({
      query: (body) => ({
        url: 'api/book/add',
        method: 'POST',
        body
      }),
      invalidatesTags: ['ManagerBook']
    }),
    addPhotoToBook: build.mutation<{ status: number; data: IManagerBook }, ManagerBookAddPhotoData>(
      {
        query: (data) => {
          const formData = new FormData();
          formData.append('id', data.id.toString());
          data.files.forEach((file) => {
            formData.append('files[]', file);
          });
          return {
            url: `/api/book/${data.id}/add_photos`,
            method: 'POST',
            body: formData
          };
        },
        invalidatesTags: ['ManagerBook']
      }
    ),
    deletePhotoFromBook: build.mutation<
      { status: number; data: IManagerBook },
      ManagerBookDeletePhotoData
    >({
      query: (data) => ({
        url: `/api/book/${data.id}/remove_photo`,
        method: 'POST',
        body: {
          id: data.id,
          photo_id: data.photo_id
        }
      }),
      invalidatesTags: ['ManagerBook']
    }),
    addPhotoToNewBookInEditor: build.mutation<{ status: number; data: { url: string }[] }, Blob[]>({
      query: (photos) => {
        const formData = new FormData();
        photos.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/book/add_photos_in_editor`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['ManagerBook']
    }),
    addPhotoToExistingBookInEditor: build.mutation<
      { status: number; data: { url: string }[] },
      ManagerBookAddPhotoData
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('id', data.id.toString());
        data.files.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/book/${data.id}/add_photos_in_editor`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['ManagerBook']
    }),
    editBook: build.mutation<
      { status: number; data: IManagerBook },
      Omit<IManagerBook, 'photos' | 'photos_in_text'>
    >({
      query: (body) => ({
        url: `api/book/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['ManagerBook']
    }),
    deleteBook: build.mutation<{ status: number; message: string }, number>({
      query: (id) => ({
        url: `/api/book/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ManagerBook']
    })
  })
});
