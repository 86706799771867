import { createContext } from 'react';
import { IUser } from '../../components/modules/AccountsPage/AccountsItems/types';

interface IAuthContext {
  user: Omit<IUser, 'password'> | null,
  login: (user: Omit<IUser, 'password'>) => void;
  logout: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  login: () => undefined,
  logout: () => undefined
});