import React from 'react';
import { Icon } from './types';

const CloseFillIcon: React.FC<Icon> = ({ className, path }) => {
  return (
    <svg
      className={className ?? ''}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.0703127 11.9043L5.1416 6.83301L0.0703127 1.76172L1.76074 0.0712888L6.83203 5.14258L11.9033 0.0712888L13.5937 1.76172L8.52246 6.83301L13.5937 11.9043L11.9033 13.5947L6.83203 8.52344L1.76074 13.5947L0.0703127 11.9043Z"
        fill="#2881B5"
        {...path}
      />
    </svg>
  );
};

export default CloseFillIcon;
