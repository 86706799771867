import { TFunction } from 'i18next';
import { Roles } from '../constants';

export const getRoleName = (
  role: number | null,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  switch (role) {
    case Roles.MANAGER:
      return t(`manager`);
    case Roles.ADMIN:
      return t(`admin`);
		case Roles.SUPERADMIN:
      return t(`superAdmin`);
    default:
      return null;
  }
};
