import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../ui/Inputs';
import s from './AddLessonForm.module.scss';
import { useFormik } from 'formik';
import { AddLessonFormProps, AddLessonValues } from './types';
import { requireSchema } from '../../../../utils/validate';
import { Button } from '../../../ui/Buttons';
import LessonView from '../LessonView/LessonView';
import { TextEditor } from '../../../ui/TextEditor';
import AddLessonPhotoModal from '../Modals/AddLessonPhotoModal/AddLessonPhotoModal';
import { useEditorState } from '../../../../hooks';
import { ILessonVideo } from '../LessonItems/types';
import { getImage } from '../../../../utils/getImage';
import {
  useAddPhotoToExistingEducationInEditorMutation,
  useAddPhotoToNewEducationInEditorMutation,
  useDeletePhotoFromEducationMutation,
  useGetLessonQuery
} from '../../../../store/api';
import { Loading } from '../../../ui/Loading';

const AddLessonForm: React.FC<AddLessonFormProps> = ({
  addLesson,
  editLesson,
  onClose,
  editedLessonId
}) => {
  const { t } = useTranslation();
  const editedLessonRes = useGetLessonQuery(editedLessonId, { skip: !editedLessonId });
  const editedLesson = useMemo(() => editedLessonRes.data?.data, [editedLessonRes]);
  const [addPhotoInEditortoNew] = useAddPhotoToNewEducationInEditorMutation();
  const [addPhotoInEditorToExisting] = useAddPhotoToExistingEducationInEditorMutation();
  const [deletePhotoFromEditor] = useDeletePhotoFromEducationMutation();
  const [photoModal, setPhotoModal] = useState(false);
  const [newPhotos, setNewPhotos] = useState<{ id: number; photo: Blob }[]>([]);
  const [newVideo, setNewVideo] = useState<Omit<ILessonVideo, 'id' | 'order'>>();

  const previewPhoto =
    editedLesson?.videos?.[0]?.image_url ??
    (editedLesson?.photos?.[0]?.url
      ? getImage(editedLesson.photos[0].url)
      : (newVideo?.image_url ?? newPhotos?.[0]?.photo ?? undefined));

  const handleOpenPhotoModal = () => {
    setPhotoModal(true);
  };

  const handleClosePhotoModal = () => {
    setPhotoModal(false);
  };

  const handleLessonTitleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isUrl = value.includes('v=');

    if (isUrl) {
      const videoId = value.split('v=')[1];

      const imageUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
      setNewVideo({ video_id: videoId, image_url: imageUrl, video_url: value });

      fetch(
        `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=snippet`
      )
        .then((response) => response.json())
        .then((data) => {
          formik.setFieldValue('title', data.items[0].snippet.title);
          formik.setFieldValue('text', data.items[0].snippet.description);
          onEditorStateChange(prepareDraft(data.items[0].snippet.description));
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      formik.setFieldValue('title', value);
    }
  };

  const addPhotoInEditorHandle = async (files: Blob[]) => {
    if(editedLessonId) {
      const response =
        await addPhotoInEditorToExisting({ id: editedLessonId, files }).unwrap();
        if (response.data[0]) {
        return response.data[0].url;
      }
    } else {
      const response = await addPhotoInEditortoNew(files).unwrap();
      if (response?.data?.[0]) {
        return response.data[0].url;
      }
    }
  };

  const deletePhotoFromEditorHandle = (url: string) => {
    const startIdx = url.indexOf('uploads/');
    const result = url.substring(startIdx);
    if(editedLesson) {
      const deleteImgId = editedLesson.photos_in_text?.find((img) => img.url === result)?.id;
      deleteImgId && deletePhotoFromEditor({id: editedLesson.id, photo_id: deleteImgId});
    }
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      text: ''
    },
    validationSchema: requireSchema(['title', 'text']),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: AddLessonValues) => {
      if (editedLesson) {
        editLesson({ ...editedLesson, ...values });
      } else {
        const photos = newPhotos.map((item) => item.photo);
        addLesson(values, photos, newVideo);
      }
      onClose();
    }
  });

  const { editorState, onEditorStateChange, prepareDraft } = useEditorState(
    formik.values.text,
    (value) => formik.setFieldValue('text', value)
  );

  useEffect(() => {
    if (editedLesson) {
      formik.setValues({
        title: editedLesson.title ? editedLesson.title : '',
        text: editedLesson.text ? editedLesson.text : ''
      });
      onEditorStateChange(prepareDraft(editedLesson.text));
    }
  }, [editedLesson]);

  return (
    <div className={s.body}>
      <div className={s.view}>
        <LessonView
          title={formik.values.title}
          text={formik.values.text}
          photo={previewPhoto}
          openPhotoModal={handleOpenPhotoModal}
        />
      </div>
      <form className={s.form} onSubmit={formik.handleSubmit}>
        <div className={s.fields}>
          <div className={s.field}>
            <Input
              name="title"
              error={!!formik.errors.title}
              onChange={handleLessonTitleChange}
              value={formik.values.title}
              placeholder={`${t('educationPage.link')}`}
              className={s.name}
            />
          </div>
          <div className={s.info}>
            <div className={s.field}>
              <TextEditor
                value={editorState}
                setValue={onEditorStateChange}
                error={!!formik.errors.text}
                placeholder={`${t('description')}`}
                addPhotos={addPhotoInEditorHandle}
                deletePhoto={deletePhotoFromEditorHandle}
              />
            </div>
          </div>
        </div>
        <div className={s.btns}>
          <Button type="submit">{editedLesson ? t('buttons.edit') : t('buttons.save')}</Button>
          <Button onClick={onClose} red>
            {t('buttons.cancel')}
          </Button>
        </div>
      </form>
      {editedLessonRes?.isLoading && <Loading absolute />}
      <AddLessonPhotoModal
        open={photoModal}
        onClose={handleClosePhotoModal}
        lesson_id={editedLesson?.id}
        photos={editedLesson?.photos}
        video={editedLesson?.videos?.[0]}
        newVideo={newVideo}
        newPhotos={newPhotos}
        setNewPhotos={setNewPhotos}
      />
    </div>
  );
};

export default AddLessonForm;
