import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/Buttons';
import { Title } from '../components/ui/Title';
import { linkNames } from '../constants';
import { useTranslation } from 'react-i18next';
import { ManagerBook } from '../components/modules/ManagerBook';
import { LanguageSelect } from '../components/ui/Select';

const DashBoard: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openManagerBook, setOpenManagerBook] = useState(false);

  const handleOpenManagerBook = () => {
    setOpenManagerBook(true);
  };

  const handleCloseManagerBook = () => {
    setOpenManagerBook(false);
  };

  const linkHandler = (link: string) => {
    navigate(link);
  };

  return (
    <div className="dashboard" id="dashboard">
      <div className='menu-bg'></div>
      <div className="change-lang">
        <LanguageSelect isSiteLangSelect />
      </div>
      <div className="dashboard__inner">
        <Title title={t('dashboard')} variant="h1" textAlign="center" />
        <ul className="dashboard__list">
          {linkNames.map((link, index) => {
            return (
              <li key={index} className="dashboard__list-item">
                {link.name === 'managerBook' ? (
                  <Button onClick={handleOpenManagerBook}>{t(link.name)}</Button>
                ) : (
                  <Button onClick={() => linkHandler(link.href)}>{t(link.name)}</Button>
                )}
              </li>
            );
        
          })}
        </ul>
      </div>
      <ManagerBook open={openManagerBook} onClose={handleCloseManagerBook} />
    </div>
  );
};

export default DashBoard;
