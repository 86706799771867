import React from 'react';
import { Accordion } from '../../../ui/Accordion';
import { AddValueFormSpec } from '../../CreateScriptPage';
import { AdditionalFieldSpecProps } from './types';
import s from './AdditionalFields.module.scss';
import { Checkbox } from '../../../ui/Inputs';

const AdditionalFieldSpec: React.FC<AdditionalFieldSpecProps> = ({
  field,
  selectedIds,
  project_id,
  editModeId,
  onSelect,
  onClearSelectedIds,
  onSetEditMode,
  onCancelEditMode
}) => {
  const { script_field_id, name, text, script_name } = field;

  const handleSetEditMode = () => {
    onSetEditMode(script_field_id);
  };

  const handleSelect = () => {
    onSelect(script_field_id);
  };

  return (
    <div className={s.additionalFieldSpec}>
      {editModeId !== script_field_id ? (
        <Accordion
          title={`${name} (${script_name})`}
          content={text}
          onEdit={handleSetEditMode}
          onRemove={() => null}
          withoutDelete
        />
      ) : (
        <AddValueFormSpec
          editedAdditionalField={field}
          onClose={onCancelEditMode}
          onClearSelectedIds={onClearSelectedIds}
          selectedIds={selectedIds}
          project_id={project_id}
        />
      )}
      <Checkbox
        className={s.checkbox}
        name="field"
        checked={selectedIds.includes(field.script_field_id)}
        onChange={handleSelect}
      />
    </div>
  );
};

export default AdditionalFieldSpec;
