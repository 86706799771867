import React, { useState } from 'react';
import { LoggingTableProps } from './types';
import s from './LoggingTable.module.scss';
import { Button } from '../../../ui/Buttons';
import { OrderIcon, ShortHorizontalArrowIcon } from '../../../ui/Icons';
import ChangeModal from '../ChangeModal/ChangeModal';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useLocalStorage } from '../../../../hooks';

const LoggingTable: React.FC<LoggingTableProps> = ({
  changes,
  changeOptions,
  entityOptions,
  orderScript,
  orderDate,
  changeOrderScript,
  changeOrderDate
}) => {
  const { t } = useTranslation();
  const { storedValue: savedViewedId, setStoredValue } = useLocalStorage('viewedId');
  const [changeId, setChangeId] = useState<number | null>(null);
  const [openInfo, setOpenInfo] = useState(false);
  const [viewedId, setViewedId] = useState<number | null>(Number(savedViewedId));

  const handleOpenInfo = (id: number) => {
    setStoredValue(id);
    setViewedId(id);
    setChangeId(id);
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  return (
    <table className={s.table}>
      <thead>
        <tr>
          <th>
            <div className={s.specFlex}>
              <span>{t('dataLoggingPage.script')}</span>
              <div className={s.orderIcons} onClick={changeOrderScript}>
                <OrderIcon
                  className={cn(s.orderIcon, s.orderTop, { [s.active]: orderScript === 'ASC' })}
                />
                <OrderIcon
                  className={cn(s.orderIcon, s.orderBottom, { [s.active]: orderScript === 'DESC' })}
                />
              </div>
            </div>
          </th>
          <th>{t('dataLoggingPage.admin')}</th>
          <th>
            <div className={s.specFlex}>
              <span>{t('dataLoggingPage.time')}</span>
              <div className={s.orderIcons} onClick={changeOrderDate}>
                <OrderIcon
                  className={cn(s.orderIcon, s.orderTop, { [s.active]: orderDate === 'DESC' })}
                />
                <OrderIcon
                  className={cn(s.orderIcon, s.orderBottom, { [s.active]: orderDate === 'ASC' })}
                />
              </div>
            </div>
          </th>
          <th>{t('dataLoggingPage.IPandMAC')}</th>
          <th>{t('dataLoggingPage.entityType')}</th>
          <th>{t('dataLoggingPage.changesType')}</th>
          <th>{t('dataLoggingPage.info')}</th>
        </tr>
      </thead>
      <tbody>
        {changes?.map((item) => {
          return (
            <tr key={item.change_id}>
              <td>
                {+item.entity_type === 3 ? (
                  <Link
                    to={`/script/${item.script.id}?project_id=${item.project.id}`}
                    className={s.scriptName}
                  >
                    {t('projectsPage.regulations')}
                  </Link>
                ) : (
                  <Link
                    to={`/script/${item.script.id}?project_id=${item.project.id}`}
                    className={s.scriptName}
                  >
                    {item.script.name}
                  </Link>
                )}
              </td>
              <td>{item.user.name}</td>
              <td>{item.date}</td>
              <td>
                <div>{item.user.ip}</div>
                <div>{item.user.mac}</div>
              </td>
              <td>{entityOptions.find((option) => option.value === +item.entity_type)?.label}</td>
              <td
                className={cn(
                  s.type,
                  { [s.green]: item.change_type === 1 },
                  { [s.red]: item.change_type === 3 },
                  { [s.yellow]: item.change_type === 4 },
                  { [s.violet]: item.change_type === 5 }
                )}
              >
                <span>
                  {changeOptions.find((option) => option.value === item.change_type)?.label}
                </span>
              </td>
              <td className={s.more}>
                {item.change_type !== 4 && item.change_type !== 5 && (
                  <Button
                    onClick={() => handleOpenInfo(item.change_id)}
                    className={cn(s.more__btn, { [s.viewed]: item.change_id === viewedId })}
                    outlined
                  >
                    <span>{t('dataLoggingPage.moreDetails')}</span>
                    <ShortHorizontalArrowIcon className={s.arrow} />
                  </Button>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
      {changeId && <ChangeModal changeId={changeId} open={openInfo} onClose={handleCloseInfo} />}
    </table>
  );
};

export default LoggingTable;
