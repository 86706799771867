import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountsArchiveModalProps } from './types';
import { Modal } from '../../../ui/Modals';
import AccountsItems from '../AccountsItems/AccountsItems';
import DaysSetting from './DaysSetting';

const AccountsArchiveModal: React.FC<AccountsArchiveModalProps> = ({ accounts, open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal title={t('archive')} open={open} onClose={onClose}>
      <DaysSetting />
      {accounts.length ? (
        <AccountsItems accounts={accounts} editAccount={() => null} isArchivePage />
      ) : (
        <p className="empty" style={{ marginBottom: '45px' }}>
          {t('accountsPage.emptyArchive')}
        </p>
      )}
    </Modal>
  );
};

export default AccountsArchiveModal;
