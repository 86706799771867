import React from 'react';
import { Icon } from './types';

const OrderIcon: React.FC<Icon> = ({ className, path }) => {
  return (
    <svg
      className={className ?? ''}
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.39043 0.488043C4.19027 0.23784 3.80973 0.23784 3.60957 0.488043L0.649878 4.18765C0.387973 4.51503 0.621059 5 1.04031 5H6.95969C7.37894 5 7.61203 4.51503 7.35012 4.18765L4.39043 0.488043Z"
        fill="#4C4C4C"
        {...path}
      />
    </svg>
  );
};

export default OrderIcon;
