import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../../ui/Buttons';
import cn from 'classnames';
import s from './ScriptBlock.module.scss';
import { ExclamationIcon } from '../../../ui/Icons';
import { ScriptBlockProps } from './types';
import { useTranslation } from 'react-i18next';
import defaultPhoto from '../../../../assets/images/default.jpg';
import { getImage } from '../../../../utils/getImage';
import { useNavigate } from 'react-router-dom';
import { RoleBasedComponent } from '../../../roles';
import { Roles } from '../../../../constants';
import { useViewAllUpdatesMutation } from '../../../../store/api';
import { YesNoModal } from '../../../ui/Modals';

const ScriptBlock: React.FC<ScriptBlockProps> = ({
  script,
  scriptUpdates,
  additional_fields,
  setShowField,
  setShowRegulations,
  setShowInfo,
  openInfo,
  openPhoto,
  openRegulations
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, project_id, price, articul, internal_id, photos } = script;
  const { script_changes, script_fields_changes, important_changes, regulation_changes } =
    scriptUpdates || {
      script_changes: [],
      script_fields_changes: [],
      important_changes: [],
      regulation_changes: []
    };
  const [viewAllUpdates] = useViewAllUpdatesMutation();
  const changesBtnRef = useRef<HTMLDivElement>(null);
  const changesContentRef = useRef<HTMLUListElement>(null);
  const [showChanges, setShowChanges] = useState(false);
  const currency = !price?.includes('$') ? ' Грн.' : '';
  const previewPhoto = photos?.length ? getImage(photos[0]?.url) : defaultPhoto;
  const [fieldsChanges, setFieldsChanges] = useState(script_fields_changes);
  const [regultationsChanges, setRegultationsChanges] = useState(regulation_changes);
  const [infoChanges, setInfoChanges] = useState(important_changes);
  const [viewAllModal, setViewAllModal] = useState(false);

  const changedPriceItem = script_changes?.find((item) => item.field === 'price');
  const changedPrice = changedPriceItem && (
    <span style={{ color: 'rgb(58, 154, 58)' }}>
      {changedPriceItem.new_value + (!changedPriceItem.new_value?.includes('$') ? ' Грн.' : '')}
    </span>
  );
  const changedArticulItem = script_changes?.find((item) => item.field === 'articul');
  const changedArticul = changedArticulItem && (
    <span style={{ color: 'rgb(58, 154, 58)' }}>{changedArticulItem.new_value}</span>
  );
  const changedInternalIdItem = script_changes?.find((item) => item.field === 'internal_id');
  const changedInternalId = changedInternalIdItem && (
    <span style={{ color: 'rgb(58, 154, 58)' }}>{changedInternalIdItem.new_value}</span>
  );

  const handleOpenInfo = () => {
    openInfo(true);
  };

  const handleOpenPhoto = () => {
    openPhoto(true);
  };

  const handleOpenRegulations = () => {
    openRegulations(true);
  };

  const handleEditScript = () => {
    navigate(`/script/edit/${id}?project_id=${project_id}`);
  };

  const handleShowChanges = () => {
    setShowChanges(true);
  };

  const handleViewFieldUpdate = (id: number, update_id: number) => {
    setShowField(id, update_id);
    const filteredFieldsChanges = fieldsChanges?.filter((change) => change.script_field_id !== id);
    setFieldsChanges(filteredFieldsChanges);
  };

  const handleViewInfoUpdate = () => {
    openInfo(true);
    setInfoChanges([]);
    setShowInfo(id);
  };

  const handleViewRedulationsUpdate = () => {
    openRegulations(true);
    setRegultationsChanges([]);
    setShowRegulations(project_id);
  };

  const handleViewAllUpdates = () => {
    viewAllUpdates(id);
    setViewAllModal(false);
  };

  const handleOpenViewAllModal = () => {
    setViewAllModal(true);
  };

  const handleCloseViewAllNoModal = () => {
    setViewAllModal(false);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    const path = e.composedPath && e.composedPath();
    if (changesBtnRef.current && !path.includes(changesBtnRef.current)) {
      setShowChanges(false);
    }
  };

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = defaultPhoto;
  };

  useEffect(() => {
    setFieldsChanges(script_fields_changes);
    setRegultationsChanges(regulation_changes);
    setInfoChanges(important_changes);
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [script_fields_changes, regulation_changes, important_changes]);

  return (
    <div className={s.block}>
      <div className={s.photo__wrapper} onClick={handleOpenPhoto}>
        <img src={previewPhoto} onError={handleImageError} alt="script-img" />
      </div>
      <div className={s.info}>
        {!!photos?.length && <p className={s.photo__count}>1/{photos.length}</p>}
        {(!!script_fields_changes?.length ||
          !!important_changes?.length ||
          !!regulation_changes?.length) && (
          <div className={s.changes} onClick={handleShowChanges} ref={changesBtnRef}>
            <p>{t('scriptPage.changeInScript')}</p>
            <ExclamationIcon />
            <ul
              className={cn(s.changes__list, { [s.active]: showChanges })}
              ref={changesContentRef}
            >
              <li className={s.changes__item}>{t('scriptPage.changeInScript')}:</li>
              {fieldsChanges?.map((item) => {
                const field_name = additional_fields.find(
                  (field) => field.id === item.script_field_id
                )?.name;
                return (
                  <li
                    key={item.script_field_id}
                    className={cn(s.changes__item, s.field)}
                    onClick={() =>
                      handleViewFieldUpdate(item.script_field_id, item.script_field_update_id)
                    }
                  >
                    <span>{field_name}</span>
                    <span className={s.user}>({item.user_first_name})</span>
                  </li>
                );
              })}
              {!!infoChanges?.length && (
                <li
                  key={'important_changes'}
                  className={s.changes__item}
                  onClick={handleViewInfoUpdate}
                >
                  <span>{t('scriptPage.info')}</span>
                  <span className={s.user}>({infoChanges[0].user_first_name})</span>
                </li>
              )}
              {!!regultationsChanges?.length && (
                <li
                  key={'regulation_changes'}
                  className={s.changes__item}
                  onClick={handleViewRedulationsUpdate}
                >
                  <span>{t('projectsPage.regulations')}</span>
                  <span className={s.user}>({regultationsChanges[0].user_first_name})</span>
                </li>
              )}
              {(!!script_fields_changes?.length ||
                !!important_changes?.length ||
                !!regulation_changes?.length) && (
                <Button className={s.changes__btn} onClick={handleOpenViewAllModal} outlined>
                  {t('checkAllUpdates')}
                </Button>
              )}
            </ul>
          </div>
        )}
        <ul className={s.list}>
          <li className={s.list__item}>
            <span className={s.header}>{t('price')}:</span>{' '}
            <span>{changedPrice ?? price + currency}</span>
          </li>
          {articul && (
            <li className={s.list__item}>
              <span className={s.header}>{t('articul')}:</span>{' '}
              <span>{changedArticul ?? articul}</span>
            </li>
          )}
          <li className={s.list__item}>
            <span className={s.header}>ID:</span> <span>{changedInternalId ?? internal_id}</span>
          </li>
        </ul>
        <div className={s.functions}>
          <div className={s.function}>
            <Button onClick={handleOpenInfo}>{t('scriptPage.info')}</Button>
          </div>
          <div className={s.function}>
            <Button onClick={handleOpenRegulations}>{t('projectsPage.regulations')}</Button>
          </div>
          <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
            <div className={s.function}>
              <Button onClick={handleEditScript}>{t('buttons.edit')}</Button>
            </div>
          </RoleBasedComponent>
        </div>
      </div>
      <YesNoModal
        title={`${t('areYouShureToCheckAllUpdates')}?`}
        open={viewAllModal}
        onClose={handleCloseViewAllNoModal}
        onAgree={handleViewAllUpdates}
      />
    </div>
  );
};

export default ScriptBlock;
