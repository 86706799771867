import React from 'react';
import cn from 'classnames';
import s from './BackButton.module.scss';
import { BackButtonProps } from './types';
import { useNavigate } from 'react-router-dom';
import { HorizontalArrowIcon } from '../../Icons';

const BackButton: React.FC<BackButtonProps> = ({
  backUrl,
  goBack,
  className,
  children,
  ...props
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (goBack) {
      navigate(-1);
    }
    if (backUrl) {
      navigate(backUrl);
    }
  };

  return (
    <button className={cn(s.button, className)} onClick={handleBack} {...props}>
      <HorizontalArrowIcon className={s.backIcon} />
      <span>{children}</span>
    </button>
  );
};

export default BackButton;
