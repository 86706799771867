import { StylesConfig } from 'react-select';
import { ISelectOption } from './types';

export const customStyles: StylesConfig<ISelectOption> = {
  control: (provided) => ({
    ...provided,
    boxShadow: 'var(--box-shadow)',
    borderRadius: 'var(--border-radius)',
    backgroundColor: 'var(--white-obj)',
    borderColor: 'transparent',
    padding: '2px 5px 1px',
    cursor: 'pointer'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    padding: '11px 15px 10px',
    color: state.isSelected ? 'var(--blue-light)' : '#4C4C4C',
    cursor: 'pointer',
    transition: 'var(--transition)',
    '&:hover': {
      color: 'var(--blue)'
    }
  }),
  indicatorSeparator: (provider) => ({
    ...provider,
    display: 'none'
  }),
  dropdownIndicator: (provider) => ({
    ...provider,
    padding: '0 9px 0 0'
  }),
  singleValue: (provider) => ({
    ...provider,
    color: 'var(--gray-dark)',
  }),
	input: (provider) => ({
    ...provider,
    color: 'var(--gray-dark)',
    '&::placeholder': {
      color: 'var(--gray-dark)'
    }
  }),
  menu: (provider) => ({
    ...provider,
    boxShadow: 'var(--box-shadow)',
    borderRadius: 'var(--border-radius)'
  }),
  placeholder: (provider) => ({
    ...provider,
    color: 'var(--gray-dark)'
  })
};
