import React from 'react';
import { Icon } from './types';
import cn from 'classnames';
import s from './Icon.module.scss';

const CRMIcon: React.FC<Icon> = ({ className, path }) => {
  return (
    <svg
      className={cn(s.icon, className)}
      width="17"
      height="17"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="m1.999,10.5v4c0,.827.673,1.5,1.5,1.5s1.5-.673,1.5-1.5h2c0,1.93-1.57,3.5-3.5,3.5S-.001,16.43-.001,14.5v-4C-.001,8.57,1.569,7,3.499,7s3.5,1.57,3.5,3.5h-2c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5,1.5Zm11.285,2.631l1.217,4.869h-2.135l-1.008-4.036c-.119.012-.235.036-.357.036h-1v4h-2V7h3c1.93,0,3.5,1.57,3.5,3.5,0,1.053-.478,1.988-1.217,2.631Zm-2.283-1.131c.827,0,1.5-.673,1.5-1.5s-.673-1.5-1.5-1.5h-1v3h1Zm12.334-3.703c-.053-.914-.586-1.297-1.225-1.297-.424,0-.818.314-1.043.831l-1.434,2.887-1.451-2.887c-.224-.517-.619-.831-1.043-.831-.639,0-1.172.383-1.224,1.297l-.672,9.703h2.005l.461-6.656,1.934,3.849,1.896-3.816.449,6.623h2.005l-.658-9.703Z"
        {...path}
      />
    </svg>
  );
};

export default CRMIcon;
