import React, { useState } from 'react';
import { AddLessonForm, LessonItems } from '../components/modules/EducationPage';
import { BackButton, CreateButton } from '../components/ui/Buttons';
import { RoleBasedComponent } from '../components/roles';
import { useTranslation } from 'react-i18next';
import { Roles } from '../constants';
import { useAddEducationMutation, useEditEducationMutation, useGetEducationQuery } from '../store/api';
import { ILesson } from '../components/modules/EducationPage/LessonItems/types';
import { ILessonVideo } from '../components/modules/EducationPage/LessonItems/types';
import { Loading } from '../components/ui/Loading';

const Education: React.FC = () => {
  const { t } = useTranslation();
  const { data, refetch, isLoading: isLoadingOnGet } = useGetEducationQuery();
  const [addLesson, { isLoading: isLoadingOnAdd }] = useAddEducationMutation();
  const [editLesson, { isLoading: isLoadingOnEdit }] = useEditEducationMutation();
  const showLoading = isLoadingOnAdd || isLoadingOnEdit;
  const [editedLessonId, setEditedLessonId] = useState<number>();
  const [openLessonForm, setOpenLessonForm] = useState(false);

  const handleCloseLessonForm = () => {
    setEditedLessonId(undefined);
    setOpenLessonForm(false);
  };

  const handleOpenLessonForm = () => {
    setOpenLessonForm(true);
  };

  const handleAddLesson = async (values: Pick<ILesson, 'title' | 'text'>,  photos?: Blob[], video?: Omit<ILessonVideo, "id" | 'order'>) => {
		await addLesson({...values, photos, video});
		refetch();
	}

	const handleEditLesson = (values: Omit<ILesson, "photos" | "videos">) => {
		editLesson(values);
	}

  const handleSetEditLesson = (id: number) => {
    setOpenLessonForm(true);
    setEditedLessonId(id);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="education page-section" id="education">
      <div className="container">
        <div className="education__inner">
          <BackButton backUrl={'/'}>{t('education')}</BackButton>
          <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
            {!openLessonForm && (
              <CreateButton onClick={handleOpenLessonForm}>
                {t('educationPage.addLesson')}
              </CreateButton>
            )}
            {openLessonForm && (
              <AddLessonForm addLesson={handleAddLesson} editLesson={handleEditLesson} onClose={handleCloseLessonForm} editedLessonId={editedLessonId} />
            )}
          </RoleBasedComponent>
          { !!data?.data?.length && <LessonItems lessons={data.data} editLesson={handleSetEditLesson} />}
          { isLoadingOnGet && <Loading />}
          { showLoading && <div className='loading__wrapper'><Loading absolute /></div>}
        </div>
      </div>
    </div>
  );
};

export default Education;
