import { createApi } from '@reduxjs/toolkit/query/react';
import { scriptsApi } from '../scripts/scriptsApi';
import { IScriptUpdate } from '../../../components/modules/ScriptPage/ScriptBlock/types';
import { ViewUpdateData } from './types';
import { configBaseQuery } from '../config';

export const scriptUpdatesApi = createApi({
  reducerPath: 'scriptUpdatesApi',
  tagTypes: ['ScriptUpdate'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getUpdates: build.query<{ status: number; data: IScriptUpdate }, number | undefined>({
      query: (id) => `api/script/${id}/get-updates`,
      providesTags: (result) =>
        result ? [{ type: 'ScriptUpdate', id: result.data.script_id }] : []
    }),
    viewScriptUpdate: build.mutation<{ status: number; message: string }, ViewUpdateData>({
      query: (data) => ({
        url: `api/script/${data.id}/update-viewed`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ScriptUpdate'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Scripts', 'Script']));
      }
    }),
    viewFieldUpdate: build.mutation<{ status: number; message: string }, ViewUpdateData>({
      query: (data) => ({
        url: `api/script-field/${data.id}/update-viewed`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ScriptUpdate'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Scripts', 'Script']));
      }
    }),
    viewInfoUpdate: build.mutation<{ status: number; message: string }, number>({
      query: (script_id) => ({
        url: `api/important/view-updates`,
        method: 'PUT',
        body: { script_id }
      }),
      invalidatesTags: ['ScriptUpdate'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Scripts', 'Script']));
      }
    }),
    viewRegulationsUpdate: build.mutation<{ status: number; message: string }, number>({
      query: (project_id) => ({
        url: `api/regulation/view-updates`,
        method: 'PUT',
        body: { project_id }
      }),
      invalidatesTags: ['ScriptUpdate'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Scripts', 'Script']));
      }
    }),
    viewAllUpdates: build.mutation<{ status: number; message: string }, number>({
      query: (id) => ({
        url: `api/script/${id}/all-updates-viewed`,
        method: 'PUT'
      }),
      invalidatesTags: ['ScriptUpdate'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Scripts', 'Script']));
      }
    })
  })
});
