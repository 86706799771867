import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IScript,
  IScriptPhoto,
  IScriptsPagination
} from '../../../components/modules/ScriptPage/ScriptBlock/types';
import {
  AddScriptData,
  CopyToOtherProjectData,
  DeletePhotoData,
  DeleteVideoData,
  GetAllScriptsData,
  GetScriptResponse,
  GetScriptsResponse,
  ScriptsOrdersData,
  UploadPhotoData,
  UploadVideoData
} from './types';
import { scriptUpdatesApi } from '../scriptUpdates/scriptUpdatesApi';
import { configBaseQuery } from '../config';
import { loggingApi } from '../logging/loggingApi';

export const scriptsApi = createApi({
  reducerPath: 'scriptsApi',
  tagTypes: ['Scripts', 'Script'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getScripts: build.query<{ status: number; data: GetScriptsResponse }, number>({
      query: (id) => `/api/project/${id}/scripts`,
      providesTags: (result) =>
        result ? result.data.scripts.map(({ id }) => ({ type: 'Scripts', id })) : []
    }),
    getAllScripts: build.query<
      { status: number; error: boolean; data: IScript[]; pagination: IScriptsPagination },
      GetAllScriptsData
    >({
      query: (data) =>
        `/api/scripts/all${data.page ? `?page=${data.page}` : ''}${
          data.query ? `&query=${data.query}` : ''
        }`,
      providesTags: (result) =>
        result && result.status !== 404
          ? result.data.map(({ id }) => ({ type: 'Scripts', id }))
          : []
    }),
    getScript: build.query<{ status: number; data: GetScriptResponse }, number | undefined>({
      query: (id) => `/api/script/${id}`,
      providesTags: (result) => (result ? [{ type: 'Script', id: result.data.id }] : [])
    }),
    addScript: build.mutation<{ status: number; data: IScript }, AddScriptData>({
      query: (data) => {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('price', data.price);
        formData.append('internal_id', data.internal_id);
        formData.append('project_id', data.project_id.toString());
        data.articul && formData.append('articul', data.articul);
        data?.photos?.forEach((photo) => {
          formData.append(`files[]`, photo);
        });
        data?.videos?.forEach((video, index) => {
          formData.append(`videos[${index}][video_url]`, video.video_url);
          formData.append(`videos[${index}][video_id]`, video.video_id);
          formData.append(`videos[${index}][image_url]`, video.image_url);
        });
        return {
          url: '/api/script/add',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Scripts'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(loggingApi.util.invalidateTags(['Logging']));
      }
    }),
    editScript: build.mutation<
      { status: number; data: IScript },
      Omit<IScript, 'photos' | 'videos' | 'status'>
    >({
      query: (body) => {
        return {
          url: `/api/script/${body.id}`,
          method: 'PUT',
          body
        };
      },
      invalidatesTags: ['Scripts', 'Script'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
        dispatch(loggingApi.util.invalidateTags(['Logging']));
      }
    }),
    deleteScript: build.mutation<{ status: number; message: string }, number>({
      query: (id) => ({
        url: `/api/script/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Scripts'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(loggingApi.util.invalidateTags(['Logging']));
      }
    }),
    copyScript: build.mutation<{ status: number; data: IScript }, number>({
      query: (id) => ({
        url: `/api/script/${id}/copy`,
        method: 'POST'
      }),
      invalidatesTags: ['Scripts']
    }),
    copyScriptToOtherProject: build.mutation<
      { status: number; data: IScript },
      CopyToOtherProjectData
    >({
      query: (data) => ({
        url: `/api/script/${data.id}/copy-to-new-project`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Scripts']
    }),
    uploadScriptPhoto: build.mutation<
      { status: number; data: { script: IScript; photos: IScriptPhoto[] } },
      UploadPhotoData
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('id', data.script_id.toString());
        data.files.forEach((photo) => {
          formData.append(`files[]`, photo);
        });
        return {
          url: `api/script/${data.script_id}/upload_photos`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Scripts', 'Script']
    }),
    uploadScriptVideo: build.mutation<
      { status: number; data: { script: IScript } },
      UploadVideoData
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('id', data.script_id.toString());
        formData.append(`videos[0][video_url]`, data.video_url);
        formData.append(`videos[0][video_id]`, data.video_id);
        formData.append(`videos[0][image_url]`, data.image_url);

        return {
          url: `api/script/${data.script_id}/upload_video`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Script']
    }),
    deleteScriptPhoto: build.mutation<{ status: number; message: string }, DeletePhotoData>({
      query: (data) => ({
        url: `/api/script/${data.script_id}/delete_photo`,
        method: 'POST',
        body: {
          id: data.script_id,
          image_id: data.photo_id
        }
      }),
      invalidatesTags: ['Scripts', 'Script']
    }),
    deleteScriptVideo: build.mutation<{ status: number; message: string }, DeleteVideoData>({
      query: (data) => ({
        url: `/api/script/${data.script_id}/delete_video`,
        method: 'POST',
        body: {
          id: data.script_id,
          video_id: data.video_id
        }
      }),
      invalidatesTags: ['Script']
    }),
    blockScript: build.mutation<{ status: number; data: IScript }, number>({
      query: (id) => ({
        url: `/api/script/${id}/block`,
        method: 'PUT'
      }),
      invalidatesTags: ['Scripts']
    }),
    closeScript: build.mutation<{ status: number; data: IScript }, number>({
      query: (id) => ({
        url: `/api/script/${id}/trash`,
        method: 'POST'
      }),
      invalidatesTags: ['Scripts'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(loggingApi.util.invalidateTags(['Logging']));
      }
    }),
    changeScriptsOrders: build.mutation<
      { status: number; data: GetScriptsResponse },
      { orders: ScriptsOrdersData[]; project_id: number }
    >({
      query: (data) => {
        return {
          url: `/api/scripts/orders`,
          method: 'POST',
          body: {
            order: data.orders,
            project_id: data.project_id
          }
        };
      },
      async onQueryStarted(response, { dispatch, queryFulfilled }) {
        const { data: updatedScripts } = await queryFulfilled;
        dispatch(
          scriptsApi.util.updateQueryData('getScripts', response.project_id, (draft) => {
            Object.assign(draft, updatedScripts);
          })
        );
      }
    })
  })
});
