import React, { useEffect, useState } from 'react';
import s from './LessonView.module.scss';
import { Title } from '../../../ui/Title';
import { LessonViewProps } from './types';
import { AddPhotoButton } from '../../../ui/Buttons';
import { getLimitedText } from '../../../../utils/getLimitedText';
import cn from 'classnames';

const LessonView: React.FC<LessonViewProps> = ({ title, text, photo, openPhotoModal }) => {
  const [blobUrl, setBlobUrl] = useState('');

  useEffect(() => {
    const url = photo && photo instanceof Blob && URL.createObjectURL(photo);
    url && setBlobUrl(url);
    return () => {
      url && URL.revokeObjectURL(url);
    };
  }, [photo]);

  return (
    <div className={s.block}>
      <div className={s.photo}>
        {photo ? (
          <img
            src={blobUrl ? blobUrl : typeof photo === 'string' ? photo : ''}
            onClick={openPhotoModal}
            alt={'lesson-img'}
          />
        ) : (
          <AddPhotoButton inputDisabled onClick={openPhotoModal} />
        )}
      </div>
      <div className={s.info}>
        <Title title={title ? title : '-'} variant="h3" className={s.title} />
        <div className={cn(s.text, 'htmlView')}>
          {text ? <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: getLimitedText(text) }} /> : '-'}
        </div>
      </div>
    </div>
  );
};

const MemoizedLessonView = React.memo(LessonView);

export default MemoizedLessonView;