import React from 'react';
import { Icon } from './types';

const VerticalArrowIcon: React.FC<Icon> = ({ className, path }) => {
  return (
    <svg className={className ?? ''} width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1L5.52778 5.59317C6.0625 6.13561 6.9375 6.13561 7.47222 5.59317L12 1"
        stroke="#2882B5"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...path}
      />
    </svg>
  );
};

export default VerticalArrowIcon;
