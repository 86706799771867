import React from 'react';
import { useSwiper } from 'swiper/react';
import { SliderArrowIcon } from '../../Icons';
import s from "./styles.module.scss";
import { SliderArrowProps } from './types';

const SliderNextArrow: React.FC<SliderArrowProps> = ({className}) => {
  const swiper = useSwiper();

  return (
    <button onClick={() => swiper.slideNext()}>
      <SliderArrowIcon className={`${s.arrow} ${s.next} ${className ?? ''}`}/>
    </button>
  );
};

export default SliderNextArrow;