import React from 'react';
import { Icon } from './types';
import cn from 'classnames';
import s from './Icon.module.scss';

const EditIcon: React.FC<Icon> = ({ className, path }) => {
  return (
    <svg
      className={cn(s.icon, className)}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7563 2.63399L10.3828 0.244089C10.2259 0.0877588 10.0137 0 9.79244 0C9.57118 0 9.35893 0.0877588 9.20208 0.244089L0.787496 8.66139L0.0192258 11.9829C-0.00727699 12.1044 -0.00636846 12.2302 0.0218849 12.3512C0.0501383 12.4722 0.105023 12.5854 0.182529 12.6825C0.260035 12.7795 0.358205 12.858 0.469868 12.9122C0.58153 12.9664 0.703863 12.9949 0.827932 12.9956C0.885742 13.0015 0.943993 13.0015 1.0018 12.9956L4.35389 12.226L12.7563 3.81678C12.9124 3.65966 13 3.44703 13 3.22538C13 3.00374 12.9124 2.79111 12.7563 2.63399ZM3.94954 11.4969L0.807714 12.1571L1.52342 9.07051L7.81919 2.78791L10.2453 5.21832L3.94954 11.4969ZM10.7871 4.63097L8.36103 2.20056L9.76817 0.799031L12.1539 3.22944L10.7871 4.63097Z"
        fill="#707070"
        {...path}
      />
    </svg>
  );
};

export default EditIcon;
