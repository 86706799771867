export default {
  options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'image', 'colorPicker', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough']
  },
  list: {
    options: ['unordered', 'ordered']
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
  }
};
