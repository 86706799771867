import React from 'react';
import { Title } from '../components/ui/Title';
import { useTranslation } from 'react-i18next';

const Forbidden = () => {
  const { t } = useTranslation();

  return (
    <div className="forbidden page-section" id="forbidden">
      <Title
        className="forbidden__title"
        title={t('accessDenied')}
        variant={'h1'}
        textAlign="center"
      />
    </div>
  );
};

export default Forbidden;
