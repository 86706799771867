import { createApi } from '@reduxjs/toolkit/query/react';
import { AddLessonData, LessonAddPhotoData, LessonDeletePhotoData } from './types';
import { ILesson } from '../../../components/modules/EducationPage/LessonItems/types';
import { configBaseQuery } from '../config';

export const educationApi = createApi({
  reducerPath: 'educationApi',
  tagTypes: ['Education', 'Lesson'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getEducation: build.query<{ status: number; data: ILesson[] }, void>({
      query: () => '/api/educations',
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'Education', id })) : []
    }),
    getLesson: build.query<{ status: number; data: ILesson }, number | undefined>({
      query: (id) => `/api/education/${id}`,
      providesTags: (result) => (result ? [{ type: 'Lesson', id: result.data.id }] : [])
    }),
    addEducation: build.mutation<{ status: number; data: ILesson }, AddLessonData>({
      query: (data) => {
        const formData = new FormData();
        formData.append('text', data.text);
        formData.append('title', data.title);

        data?.photos?.forEach((photo) => {
          formData.append(`files[]`, photo);
        });

        if (data.video) {
          formData.append('videos[0][video_id]', data.video.video_id);
          formData.append('videos[0][image_url]', data.video.image_url);
          formData.append('videos[0][video_url]', data.video.video_url);
        }

        return {
          url: 'api/education/add',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Education']
    }),
    addPhotoToEducation: build.mutation<{ status: number; data: ILesson }, LessonAddPhotoData>({
      query: (data) => {
        const formData = new FormData();
        formData.append('id', data.id.toString());
        data.files.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/education/${data.id}/add_photos`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Education', 'Lesson']
    }),
    deletePhotoFromEducation: build.mutation<
      { status: number; data: ILesson },
      LessonDeletePhotoData
    >({
      query: (data) => ({
        url: `/api/education/${data.id}/remove_photo`,
        method: 'POST',
        body: {
          id: data.id,
          photo_id: data.photo_id
        }
      }),
      invalidatesTags: ['Education', 'Lesson']
    }),
    addPhotoToNewEducationInEditor: build.mutation<
      { status: number; data: { url: string }[] },
      Blob[]
    >({
      query: (photos) => {
        const formData = new FormData();
        photos.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/education/add_photos_in_editor`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Education']
    }),
    addPhotoToExistingEducationInEditor: build.mutation<
      { status: number; data: { url: string }[] },
      LessonAddPhotoData
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('id', data.id.toString());
        data.files.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/education/${data.id}/add_photos_in_editor`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Education']
    }),
    editEducation: build.mutation<
      { status: number; data: ILesson },
      Omit<ILesson, 'photos' | 'video' | 'photos_in_text'>
    >({
      query: (body) => ({
        url: `api/education/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Education', 'Lesson']
    }),
    deleteEducation: build.mutation<{ status: number; message: string }, number>({
      query: (id) => ({
        url: `/api/education/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Education']
    })
  })
});
