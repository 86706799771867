import React, { useState } from 'react';
import s from './ToggleThemeInput.module.scss';
import cn from 'classnames';
import { ToggleThemeInputProps } from './types';

const ToggleThemeInput: React.FC<ToggleThemeInputProps> = ({
  checked,
  toggleTheme,
  className,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChecked = () => {
    setIsChecked((prev) => !prev);
    toggleTheme();
  };

  return (
    <div className={cn(s.themeInput__wrapper, className ?? '')}>
      <input
        id="themeInput"
        name="themeInput"
        type="checkbox"
        className={cn(s.themeInput, { [s.isChecked]: isChecked })}
        onChange={handleChecked}
        checked={isChecked}
        {...props}
      />
      <label htmlFor="themeInput" className={s.themeInput__label}>
        <span></span>
      </label>
    </div>
  );
};

export default ToggleThemeInput;
