import React, { useState } from 'react';
import cn from 'classnames';
import s from './Tabs.module.scss';
import { TabsProps } from './types';
import { useNavigate } from 'react-router-dom';

const Tabs: React.FC<TabsProps> = ({ tabs, defaultTab }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    navigate(tabs[index].link);
  };

  return (
    <div className={s.tabs}>
      <ul className={s.tabs__titles}>
        {tabs.map((item, index) => {
          return (
            <li
              key={index}
              className={cn(s.tabs__title, { [s.active]: index === activeTab })}
              onClick={() => handleTabClick(index)}>
              {item.title}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
