import React, { useState } from 'react';
import { YesNoModal } from '../../../ui/Modals';
import { Reorder, useDragControls } from 'framer-motion';
import { ObjectionsItemProps } from './types';
import { Accordion } from '../../../ui/Accordion';
import { useDeleteObjectionMutation } from '../../../../store/api';

const ObjectionsItem: React.FC<ObjectionsItemProps> = ({
  objection,
  changeOrders,
  editObjection,
  withoutFuncs
}) => {
  const { id, content } = objection;
  const [deleteObjection] = useDeleteObjectionMutation();
  const [deleteModal, setDeleteModal] = useState(false);
  const dragControls = useDragControls();
  const lang = localStorage.getItem('i18nextLng');
  const itemContent = content.find((content) => content.lang === lang);
  const deletedObjectionContent = content.find((content) => content.lang === lang);
  const deletedObjectionTitle = deletedObjectionContent?.title ?? objection?.content[0].title;

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleEditObjection = () => {
    editObjection(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteAdditionalObjection = () => {
    deleteObjection(id);
    setDeleteModal(false);
  };

  return (
    <Reorder.Item
      value={objection}
      dragListener={false}
      dragControls={dragControls}
      onDragEnd={changeOrders}
    >
      <Accordion
        title={itemContent?.title ?? content[0].title}
        content={itemContent?.text ?? content[0].text}
        onEdit={handleEditObjection}
        onRemove={handleOpenDeleteModal}
        withoutFuncs={withoutFuncs}
        dragControls={dragControls}
        isDragNDrop
      />
      <YesNoModal
        isDelete
        title={deletedObjectionTitle ?? ''}
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        onAgree={handleDeleteAdditionalObjection}
      />
    </Reorder.Item>
  );
};

export default ObjectionsItem;
