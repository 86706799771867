import React, { useContext, useState } from 'react';
import cn from 'classnames';

// Import Swiper settings
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper';
import { A11y } from 'swiper';

// Import Swiper styles
import 'swiper/css';

import s from './InfoBlockSlider.module.scss';

import { InfoBlockSliderProps } from './types';
import { Photo } from '../../Photo';
import { SliderNextArrow, SliderPrevArrow } from '../../Sliders';
import { AddPhotoButton } from '../../Buttons';
import { ThemeContext } from '../../../../context/Theme/ThemeContext';
import { Roles } from '../../../../constants';
import { getImage } from '../../../../utils/getImage';
import { AuthContext } from '../../../../context/Auth/AuthContext';

const InfoBlockSlider: React.FC<InfoBlockSliderProps> = ({
  slides,
  onAddPhotos,
  onDeletePhoto
}) => {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);
  const isAdmin = user?.role === Roles.ADMIN || user?.role === Roles.SUPERADMIN;
  const [isLastSlide, setIsLastSlide] = useState(false);

  const handleSlideChange = (swiper: SwiperType) => {
    if (swiper.isEnd) {
      setIsLastSlide(true);
    } else {
      setIsLastSlide(false);
    }
  };

  return (
    <div className={cn(s.slider, { [s.dark]: theme === 'dark' }, { [s.ending]: isLastSlide })}>
      <Swiper modules={[A11y]} slidesPerView={4} onSlideChangeTransitionEnd={handleSlideChange}>
        {isAdmin && (
          <SwiperSlide>
            <AddPhotoButton className={s.addPhotoBtn} changePhoto={onAddPhotos} multi />
          </SwiperSlide>
        )}
        {slides.map((slide) => {
          return (
            <SwiperSlide key={slide.id} className={s.swiperSlide}>
              <Photo
                img={getImage(slide.url)}
                isAdmin={isAdmin}
                onDelete={() => onDeletePhoto(slide.id)}
              />
            </SwiperSlide>
          );
        })}
        <SliderPrevArrow />
        <SliderNextArrow />
      </Swiper>
    </div>
  );
};

export default InfoBlockSlider;
