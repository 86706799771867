import React, { useEffect, useState } from 'react';
import s from './styles.module.scss';
import { Title } from '../../../ui/Title';
import { useTranslation } from 'react-i18next';
import { CrossSaleBlocksProps } from './types';
import { Reorder } from 'framer-motion';
import { ICrossSale } from '../CrossSaleItems/types';
import ReorderCrossSaleBlock from './ReorderCrossSaleBlock';

const getSortedCrossSales = (cross_sales: ICrossSale[], choosen_cross_sales: number[]) => {
  const selectedItems = cross_sales.filter((item) => choosen_cross_sales.includes(item.id));
  const unselectedItems = cross_sales.filter((item) => !choosen_cross_sales.includes(item.id));

  const sortedSelectedItems = selectedItems.sort((a, b) => {
    return choosen_cross_sales.indexOf(a.id) - choosen_cross_sales.indexOf(b.id);
  });

  return [...sortedSelectedItems, ...unselectedItems];
};

const CrossSaleBlocks: React.FC<CrossSaleBlocksProps> = ({
  cross_sales,
  choosen_cross_sales,
  changeCrossSales
}) => {
  const { t } = useTranslation();
  const [crossSalesCopy, setCrossSalesCopy] = useState<ICrossSale[]>([]);

  const handleCrossSale = (id: number) => {
    const isItemSelected = choosen_cross_sales.includes(id);
    isItemSelected
      ? changeCrossSales([...choosen_cross_sales.filter((item) => item !== id)])
      : changeCrossSales([...choosen_cross_sales, id]);
  };

  const handleReorder = (result: ICrossSale[]) => {
    const lockedItems = crossSalesCopy.filter((item) => !choosen_cross_sales.includes(item.id));
    const reorderedSelectedItems = result.filter((item) => choosen_cross_sales.includes(item.id));

    const updatedItems = crossSalesCopy.map((item) => {
      const locked = lockedItems.find((locked) => locked.id === item.id);
      if (locked) return locked;
      const nextReordered = reorderedSelectedItems.shift();
      if (nextReordered) {
        return nextReordered;
      }
      return item;
    });

    setCrossSalesCopy(updatedItems);
  };

  const handleDragEnd = () => {
    const updatedChoosenCrossSales = crossSalesCopy
      .filter((item) => choosen_cross_sales.includes(item.id))
      .map((item) => item.id);

    changeCrossSales([...updatedChoosenCrossSales]);
  };

  useEffect(() => {
    const sortedElements = getSortedCrossSales(cross_sales, choosen_cross_sales);
    setCrossSalesCopy(sortedElements);
  }, [choosen_cross_sales]);

  return (
    <div className={s.body}>
      <Title title={t('scriptPage.addCrossSale')} variant="h3" className={s.body__title} />
      <Reorder.Group values={crossSalesCopy} onReorder={handleReorder} className={s.blocks}>
        {crossSalesCopy.map((block) => {
          return (
            <ReorderCrossSaleBlock
              key={block.id}
              cross_sale={block}
              onClick={handleCrossSale}
              selected={choosen_cross_sales.includes(block.id)}
              handleDragEnd={handleDragEnd}
            />
          );
        })}
      </Reorder.Group>
    </div>
  );
};

export default CrossSaleBlocks;
