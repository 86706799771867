import React, { useContext, useState } from 'react';
import { BackButton } from '../components/ui/Buttons';
import { ScriptItems } from '../components/modules/ScriptPage';
import { useGetAllScriptsQuery } from '../store/api';
import { Loading } from '../components/ui/Loading';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../components/ui/Pagination';
import { SearchContext } from '../context/Search/SearchContext';
import { useDebounce } from '../hooks';
import cn from 'classnames';

const AllScripts: React.FC = () => {
  const { t } = useTranslation();
  const { searchQuery } = useContext(SearchContext);
  const deferredSearch = useDebounce(searchQuery);
  const startSearch = deferredSearch.length > 2 ? deferredSearch : undefined;
  const [page, setPage] = useState(1);

  const { data: scripts, isLoading: isLoadingOnScripts } = useGetAllScriptsQuery({
    page,
    query: startSearch
  });
  const { status, data, pagination } = scripts || {};
  const isPagination = pagination && pagination.total / pagination.per_page > 1;

  const handleChangePage = (page: number) => {
    setPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="all-scripts page-section" id="all-scripts">
      <div className="container">
        <div className="all-scripts__inner">
          <BackButton backUrl={'/projects'}>{t('allScripts')}</BackButton>
          {!!data?.length && <ScriptItems scripts={data} isAllScriptsPage />}
          <div
            className={cn('all-scripts__pagination', {
              ['all-scripts__pagination--hide']: !isPagination
            })}
          >
            <Pagination
              itemsCount={pagination?.total ?? 0}
              itemsPerPage={pagination?.per_page ?? 0}
              onPageChange={handleChangePage}
            />
          </div>
          {status === 404 && <p className="empty">{t('nothingFound')}</p>}
          {isLoadingOnScripts && <Loading />}
        </div>
      </div>
    </div>
  );
};

export default AllScripts;
