import React from 'react';
import { Modal } from '../../../ui/Modals';
import { useTranslation } from 'react-i18next';
import { AccountBlockModalProps } from './types';
import DaysBlocking from './DaysBlocking';
import s from './AccountBlockModal.module.scss';

const AccountBlockModal: React.FC<AccountBlockModalProps> = ({
  userName,
  userId,
  open,
  onClose
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={`${t('areYouShureToBlock')} “${userName}”?`}
      titleCentered
      withoutCloseIcon
      open={open}
      onClose={onClose}
      contentClassName={s.modal}
      bodyClassName={s.body}
    >
      <DaysBlocking userId={userId} onClose={onClose} />
    </Modal>
  );
};

export default AccountBlockModal;
