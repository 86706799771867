import React, { useEffect, useState } from 'react';
import s from './AccountView.module.scss';
import { Title } from '../../../ui/Title';
import { AccountViewProps } from './types';
import { AddPhotoButton } from '../../../ui/Buttons';
import { getImage } from '../../../../utils/getImage';

const AccountView: React.FC<AccountViewProps> = ({ last_name, first_name, middle_name, role, login, password, photo, changePhoto }) => {

  const fullName = `${last_name ? last_name + ' ' : ''}${first_name ? first_name + ' ' : ''}${middle_name ? middle_name : ''}`
	const withoutName = !last_name && !first_name && !middle_name;
  const [blobUrl, setBlobUrl] = useState('');

  useEffect(() => {
    const url = photo && (photo instanceof Blob) && URL.createObjectURL(photo);
    url && setBlobUrl(url);
    return () => {
      url && URL.revokeObjectURL(url);
    };
  }, [photo]);

  return (
    <div className={s.block}>
			<div className={s.photo}>
				{ photo ? <>
            <img src={blobUrl ? blobUrl : (typeof photo === 'string' ? getImage(photo) : '')} alt={'account-img'} />
            <input key={blobUrl ?? photo} type="file" accept="image/jpeg, image/jpg, image/png, image/webp, image/svg, image/gif" onChange={changePhoto} />
          </> : <AddPhotoButton changePhoto={changePhoto} />}
			</div>
      <div className={s.info}>
        <Title title={withoutName ? '-' : fullName} variant="h3" className={s.title} />
        <ul className={s.list}>
          <li className={s.list__item}>
            {role ? role : '-'}
          </li>
          <li className={s.list__item}>
            {login ? login : '-'}
          </li>
          <li className={s.list__item}>
            {password ? password : '-'}
          </li>
        </ul>
      </div>
    </div>
  );
};

const MemoizedAccountView = React.memo(AccountView);

export default MemoizedAccountView;
