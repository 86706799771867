import React, { useContext, useState } from 'react';
import cn from 'classnames';

// Import Swiper settings
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper';
import { A11y } from 'swiper';

// Import Swiper styles
import 'swiper/css';

import s from './InfoBlockSlider.module.scss';

import { InfoBlockSliderForNewBlockProps } from './types';
import { Photo } from '../../Photo';
import { SliderNextArrow, SliderPrevArrow } from '../../Sliders';
import { AddPhotoButton } from '../../Buttons';
import { ThemeContext } from '../../../../context/Theme/ThemeContext';

const InfoBlockSliderForNewBlock: React.FC<InfoBlockSliderForNewBlockProps> = ({
  slides,
  onAddPhotos,
  onDeletePhoto
}) => {
  const { theme } = useContext(ThemeContext);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const handleSlideChange = (swiper: SwiperType) => {
    if (swiper.isEnd) {
      setIsLastSlide(true);
    } else {
      setIsLastSlide(false);
    }
  };

  return (
    <div className={cn(s.slider, { [s.dark]: theme === 'dark' }, { [s.ending]: isLastSlide })}>
      <Swiper modules={[A11y]} slidesPerView={4} onSlideChangeTransitionEnd={handleSlideChange}>
        <SwiperSlide>
          <AddPhotoButton className={s.addPhotoBtn} changePhoto={onAddPhotos} multi />
        </SwiperSlide>
        {slides.map((slide) => {
          return (
            <SwiperSlide key={slide.id} className={s.swiperSlide}>
              <Photo img={slide.photo} isAdmin onDelete={() => onDeletePhoto(slide.id)} />
            </SwiperSlide>
          );
        })}
        <SliderPrevArrow />
        <SliderNextArrow />
      </Swiper>
    </div>
  );
};

export default InfoBlockSliderForNewBlock;
