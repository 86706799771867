import { TFunction } from 'i18next';

export const pluralizeDays = (
  days: number,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return [t('day'), t('days1'), t('days2')][
    days % 100 > 4 && days % 100 < 20 ? 2 : cases[Math.min(days % 10, 5)]
  ];
};
