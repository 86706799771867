import React from 'react';
import { Roles } from '../../constants';

interface ManagerContent {
  role: number;
  children: React.ReactNode;
}

const ManagerContent: React.FC<ManagerContent> = ({role, children}) => {
	if(role !== Roles.MANAGER) {
		return null;
	}

  return <>{children}</>;
};

export default ManagerContent;
