import React, { useContext, useEffect, useState } from 'react';
import { TextEditor } from '../../TextEditor';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { requireSchema } from '../../../../utils/validate';
import { RoleBasedComponent } from '../../../roles';
import { Photo } from '../../Photo';
import InfoBlockSlider from '../Slider/InfoBlockSlider';
import { IInfoBlock, IInfoBlockPhoto, InfoBlockProps } from './types';
import cn from 'classnames';
import s from './InfoBlock.module.scss';
import { AddPhotoButton, Button } from '../../Buttons';
import { EditIcon, TrashCanIcon } from '../../Icons';
import { YesNoModal } from '../../Modals';
import { Roles } from '../../../../constants';
import InfoBlockSliderForNewBlock from '../Slider/InfoBlockSliderForNewBlock';
import { getImage } from '../../../../utils/getImage';
import { useEditorState } from '../../../../hooks';
import { AuthContext } from '../../../../context/Auth/AuthContext';

const InfoBlock: React.FC<InfoBlockProps> = ({
  block,
  newBlock,
  withoutEdit,
  withoutImage,
  additionalFunction,
  saveBlock,
  removeBlock,
  addPhoto,
  deletePhoto,
  addPhotoInEditor
}) => {
  const { t } = useTranslation();
  const { id, title, text, order_id, photos, new_photos, photos_in_text, fixedTitle, fixedText } =
    block;
  const { user } = useContext(AuthContext);
  const isAdmin = user?.role === Roles.ADMIN || user?.role === Roles.SUPERADMIN;
  const [blobUrls, setBlobUrls] = useState<{ id: number; photo: string }[]>([]);

  const [readOnly, setReadOnly] = useState(text ? true : false);
  const [openModal, setOpenModal] = useState(false);

  const handleReadOnly = () => {
    setReadOnly((prev) => !prev);
  };

  const handleRemoveBlock = () => {
    removeBlock(id);
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const formik = useFormik({
    initialValues: { title, text },
    validationSchema: requireSchema(['title', 'text']),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: Pick<IInfoBlock, 'title' | 'text'>) => {
      saveBlock({ id, order_id, ...values });
      setReadOnly(true);
    }
  });

  const { editorState: titleState, onEditorStateChange: onChangeTitleState } = useEditorState(
    formik.values.title,
    (value) => formik.setFieldValue('title', value)
  );

  const { editorState: textState, onEditorStateChange: onChangeTextState } = useEditorState(
    formik.values.text,
    (value) => formik.setFieldValue('text', value)
  );

  const sliderByRole = (photos: IInfoBlockPhoto[]) => {
    if (user?.role === Roles.MANAGER && photos.length > 4) {
      return (
        <InfoBlockSlider
          slides={photos}
          onAddPhotos={handleAddPhotos}
          onDeletePhoto={handleDeletePhoto}
        />
      );
    } else if (isAdmin && photos.length > 3) {
      return (
        <InfoBlockSlider
          slides={photos}
          onAddPhotos={handleAddPhotos}
          onDeletePhoto={handleDeletePhoto}
        />
      );
    } else return null;
  };

  const sliderForNewBlock = (photos: { id: number; photo: string }[]) => {
    if (photos.length > 3) {
      return (
        <InfoBlockSliderForNewBlock
          slides={photos}
          onAddPhotos={handleAddPhotos}
          onDeletePhoto={handleDeletePhoto}
        />
      );
    }
  };

  const handleAddPhotos = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const files = Array.from(target.files || []);
    addPhoto(id, files);
  };

  const handleDeletePhoto = (photo_id: number) => {
    deletePhoto(id, photo_id);
    if (newBlock) {
      const newBlobUrls = blobUrls.filter((photo) => photo.id !== photo_id);
      setBlobUrls(newBlobUrls);
    }
  };

  const handleAddPhotoInEditor = (files: Blob[]) => {
    const response = addPhotoInEditor(id, files);
    return response;
  };

  const handleDeletePhotoInEditor = (url: string) => {
    const startIdx = url.indexOf('uploads/');
    const result = url.substring(startIdx);
    const deleteImgId = photos_in_text?.find((img) => img.url === result)?.id;
    deleteImgId && deletePhoto(id, deleteImgId);
  };

  useEffect(() => {
    const newUrls = new_photos?.map((photo) =>
      photo.photo instanceof Blob
        ? { id: photo.id, photo: URL.createObjectURL(photo.photo) }
        : { id: 0, photo: '' }
    );
    newUrls && setBlobUrls((prev) => [...prev, ...newUrls]);

    return () => {
      newUrls?.forEach((photo) => URL.revokeObjectURL(photo.photo));
      setBlobUrls((prev) => prev.filter((url) => !newUrls?.includes(url)));
    };
  }, [new_photos]);

  return (
    <div className={s.info}>
      {!withoutImage && (
        <div className={s.images}>
          {(photos?.length
            ? sliderByRole(photos)
            : blobUrls?.length && sliderForNewBlock(blobUrls)) || (
            <>
              {!!photos?.length &&
                photos?.map((img) => {
                  return (
                    <div className={s.image__wrapper} key={img.id}>
                      <Photo
                        img={getImage(img.url)}
                        isAdmin={isAdmin}
                        onDelete={() => handleDeletePhoto(img.id)}
                      />
                    </div>
                  );
                })}
              {!!blobUrls?.length &&
                blobUrls?.map((img) => {
                  return (
                    <div className={s.image__wrapper} key={img.id}>
                      <Photo img={img.photo} isAdmin onDelete={() => handleDeletePhoto(img.id)} />
                    </div>
                  );
                })}
              <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
                <AddPhotoButton className={s.addPhotoBtn} changePhoto={handleAddPhotos} multi />
              </RoleBasedComponent>
            </>
          )}
        </div>
      )}
      <form className={s.content} onSubmit={formik.handleSubmit}>
        <RoleBasedComponent roles={[Roles.MANAGER]}>
          <div className={cn(s.content__title, 'htmlView')}>
            <div
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: fixedTitle ?? title }}
            />
          </div>
          <div className={cn(s.content__text, 'htmlView')}>
            <div
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: fixedText ?? text }}
            />
          </div>
        </RoleBasedComponent>
        <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
          {!readOnly ? (
            <>
              <div className={s.form__title}>
                <TextEditor
                  value={titleState}
                  setValue={onChangeTitleState}
                  error={!!formik.errors.title}
                  placeholder={`${t('title')}`}
                  toolbarHidden={readOnly}
                  readOnly={readOnly}
                  withoutImageUpload
                />
              </div>
              <TextEditor
                value={textState}
                setValue={onChangeTextState}
                error={!!formik.errors.text}
                placeholder={`${t('description')}`}
                toolbarHidden={readOnly}
                readOnly={readOnly}
                addPhotos={handleAddPhotoInEditor}
                deletePhoto={handleDeletePhotoInEditor}
              />
            </>
          ) : (
            <>
              <div className={cn(s.content__title, s.spec, 'htmlView')}>
                <div
                  style={{ whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={{ __html: fixedTitle ?? title }}
                />
              </div>
              <div className={cn(s.content__text, s.spec, 'htmlView')}>
                <div
                  style={{ whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={{ __html: fixedText ?? text }}
                />
              </div>
            </>
          )}
          {!withoutEdit && (
            <ul className={s.functions}>
              {additionalFunction}
              {!newBlock && (
                <li className={cn(s.function, s.edit)} onClick={handleReadOnly}>
                  <EditIcon />
                </li>
              )}
              <li className={cn(s.function, s.remove)} onClick={handleOpenModal}>
                <TrashCanIcon />
              </li>
            </ul>
          )}
          {!readOnly && (
            <Button className={s.form__btn} type="submit">
              {t('buttons.save')}
            </Button>
          )}
        </RoleBasedComponent>
      </form>
      <YesNoModal
        title={t('section')}
        open={openModal}
        isDelete
        onClose={handleCloseModal}
        onAgree={handleRemoveBlock}
      />
    </div>
  );
};

export default InfoBlock;
