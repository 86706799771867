import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BackButton, Button, CreateButton } from '../components/ui/Buttons';
import { AddScriptForm, AddValueForm } from '../components/modules/CreateScriptPage';
import { AdditionalFields } from '../components/modules/ScriptPage';
import {
  useAddAdditionalFieldMutation,
  useChangeCrossSalesMutation,
  useEditAdditionalFieldMutation,
  useGetAdditionalFieldsQuery,
  useGetProjectCrossSalesQuery,
  useGetProjectQuery,
  useGetScriptCrossSalesQuery,
  useGetScriptQuery
} from '../store/api';
import { IAdditionalField } from '../components/modules/ScriptPage/AdditionalFields/types';
import { Loading } from '../components/ui/Loading';
import { CrossSaleBlocks } from '../components/modules/CrossSalePage';
import { Status } from '../constants';
import { AddAdditionalFieldData } from '../store/api/additionalFields/types';

const EditScript: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const project_id = Number(new URLSearchParams(location.search).get('project_id'));
  const { data: project } = useGetProjectQuery(project_id);
  const { data: all_cross_sales } = useGetProjectCrossSalesQuery(project_id);
  const { data: choosen_cross_sales, refetch: choosenCrossSalesRefetch } =
    useGetScriptCrossSalesQuery(Number(id));
  const { data: script, isLoading: isLoadingOnScript } = useGetScriptQuery(Number(id));
  const { data: additional_fields, refetch: additionalFieldsRefetch } = useGetAdditionalFieldsQuery(
    Number(id)
  );
  const [addAdditionalField] = useAddAdditionalFieldMutation();
  const [editAdditionalField] = useEditAdditionalFieldMutation();

  const possibleCrossSales = all_cross_sales?.data
    .filter((item) => item.status !== Status.BLOCKED)
    .sort((a, b) => b.order_id - a.order_id);
  const [changeCrossSales] = useChangeCrossSalesMutation();
  const [crossSalesCopy, setCrossSalesCopy] = useState<number[]>([]);

  const [openAdditionalFieldForm, setOpenAdditionalFieldForm] = useState(false);
  const [isCrossSalesChanged, setIsCrossSalesChanged] = useState(false);

  const handleCloseAdditionalFieldForm = () => {
    setOpenAdditionalFieldForm(false);
  };

  const handleOpenAdditionalFieldForm = () => {
    setOpenAdditionalFieldForm(true);
  };

  const handleCancelCreate = () => {
    navigate(`/scripts?project_id=${project_id}`);
  };

  const handleChangeCrossSales = (crossSales: number[]) => {
    setCrossSalesCopy(crossSales);
  };

  const handleSubmitCrossSales = () => {
    const isNotChanged =
      choosen_cross_sales?.data.length === crossSalesCopy?.length &&
      choosen_cross_sales?.data?.every((value, index) => value.id === crossSalesCopy?.[index]);
    if (!isNotChanged && crossSalesCopy) {
      setIsCrossSalesChanged(true);
      changeCrossSales({ script_id: Number(id), cross_sales: crossSalesCopy }).then(() =>
        choosenCrossSalesRefetch()
      );
    } else {
      setIsCrossSalesChanged(false);
    }
  };

  const handleAddAdditionalField = (values: AddAdditionalFieldData) => {
    addAdditionalField(values).then(() => additionalFieldsRefetch());
  };

  const handleEditAdditionalField = (values: Omit<IAdditionalField, 'photos'>) => {
    editAdditionalField(values);
  };

  useEffect(() => {
    const crossSalesIds = choosen_cross_sales?.data.map((item) => item.id);
    crossSalesIds && setCrossSalesCopy(crossSalesIds);
  }, [choosen_cross_sales]);

  if (isLoadingOnScript) {
    return (
      <div className="edit-script page-section" id="edit-script">
        <Loading />
      </div>
    );
  }

  return (
    <div className="edit-script page-section" id="edit-script">
      <div className="container">
        <div className="edit-script__inner">
          <BackButton goBack>{project?.data.name}</BackButton>
          <div className="edit-script__edit">
            <AddScriptForm
              project_id={project_id}
              script={script?.data}
              isCrossSalesChanged={isCrossSalesChanged}
            />
          </div>
          {!!possibleCrossSales?.length && (
            <div className="edit-script__crossSales">
              <CrossSaleBlocks
                cross_sales={possibleCrossSales}
                choosen_cross_sales={crossSalesCopy}
                changeCrossSales={handleChangeCrossSales}
              />
            </div>
          )}
          {openAdditionalFieldForm && (
            <div className="edit-script__add-values">
              <AddValueForm
                fieldsLength={additional_fields?.data.length}
                addAdditionalField={handleAddAdditionalField}
                editAdditionalField={() => null}
                onClose={handleCloseAdditionalFieldForm}
                script_id={Number(id)}
              />
            </div>
          )}
          {!openAdditionalFieldForm && (
            <CreateButton onClick={handleOpenAdditionalFieldForm}>
              {t('script.addValue')}
            </CreateButton>
          )}
          {!!additional_fields?.data.length && (
            <AdditionalFields
              additional_fields={additional_fields.data}
              editAdditionalField={handleEditAdditionalField}
            />
          )}
          <div className="edit-script__btns">
            <Button form="script-form" type="submit" onClick={handleSubmitCrossSales}>
              {t('buttons.save')}
            </Button>
            <Button onClick={handleCancelCreate} red>
              {t('buttons.cancel')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditScript;
