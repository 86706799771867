import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IObjection,
  IObjectionContent
} from '../../../components/modules/ObjectionsPage/ObjectionsItems/types';
import { ObjectionsOrdersData } from './types';
import { configBaseQuery } from '../config';

export const objectionsApi = createApi({
  reducerPath: 'objectionsApi',
  tagTypes: ['Objections'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getObjections: build.query<{ status: number; data: IObjection[] }, void>({
      query: () => `/api/arguments`,
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'Objections', id })) : []
    }),
    addObjection: build.mutation<{ status: number; data: IObjection }, IObjectionContent[]>({
      query: (data) => ({
        url: 'api/argument/add',
        method: 'POST',
        body: {
          argument: data
        }
      }),
      invalidatesTags: ['Objections']
    }),
    editObjection: build.mutation<{ status: number; data: IObjection }, IObjection>({
      query: (data) => ({
        url: `api/argument/${data.id}`,
        method: 'PUT',
        body: {
          id: data.id,
          argument: data.content,
          order_id: data.order_id
        }
      }),
      invalidatesTags: ['Objections']
    }),
    deleteObjection: build.mutation<{ status: number; message: string }, number>({
      query: (id) => ({
        url: `/api/argument/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Objections']
    }),
    changeObjectionsOrders: build.mutation<
      { status: number; data: IObjection[] },
      ObjectionsOrdersData[]
    >({
      query: (orders) => {
        return {
          url: `/api/arguments/orders`,
          method: 'POST',
          body: {
            order: orders
          }
        };
      },
      async onQueryStarted(response, { dispatch, queryFulfilled }) {
        const { data: updatedObjections } = await queryFulfilled;
        dispatch(
          objectionsApi.util.updateQueryData('getObjections', undefined, (draft) => {
            Object.assign(draft, updatedObjections);
          })
        );
      }
    })
  })
});
