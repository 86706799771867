import React, { useEffect, useState } from 'react';
import { ThemeContext } from './ThemeContext';
import { Themes } from '../../constants';

interface ThemeProviderProps {
  children: React.ReactNode;
}

const getTheme = () => {
  const theme = localStorage.getItem('theme');
  if (!theme) {
    localStorage.setItem('theme', Themes.LIGHT);
    return Themes.LIGHT;
  } else {
    return theme;
  }
};

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState(getTheme);
  const browserTheme: HTMLMetaElement | null = document.querySelector('meta[name="theme-color"]');
	const rootVariables: HTMLElement | null = document.querySelector(':root');
	const body = document.body;
	body.classList.add(theme);

  const getVariablesColor = (color: string) => {
    if(rootVariables) return getComputedStyle(rootVariables).getPropertyValue(color);
  }

  function toggleTheme() {
    if (theme === Themes.LIGHT) {
      setTheme(Themes.DARK);
			body.classList.replace(Themes.LIGHT, Themes.DARK);
      if(!!browserTheme && !!rootVariables) {
				browserTheme.content = String(getVariablesColor("--themeDark"))
			}
    } else {
      setTheme(Themes.LIGHT);
			body.classList.replace(Themes.DARK, Themes.LIGHT);
      if(!!browserTheme && !!rootVariables) {
				browserTheme.content = String(getVariablesColor("--themeLight"))
			}
    }
  }

  useEffect(() => {
    const refreshTheme = () => {
      localStorage.setItem('theme', theme);
    };

    refreshTheme();
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        toggleTheme
      }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider };