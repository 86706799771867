import React from 'react';
import { Icon } from './types';
import cn from 'classnames';
import s from './Icon.module.scss';

const ShuffleIcon: React.FC<Icon> = ({ className, path }) => {
  return (
    <svg className={cn(s.icon, className)} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0022084 5.15442C0.00230441 4.7304 0.296408 4.20989 0.660317 3.99097L7.02219 0.163879C7.38562 -0.0547542 7.97421 -0.0547542 8.33831 0.164647L14.7024 3.99903C15.0659 4.21796 15.3604 4.73866 15.3603 5.1645L15.3576 12.8078C15.3575 13.2325 15.0629 13.7551 14.7001 13.9748L8.32448 17.8352C7.96144 18.0551 7.37361 18.055 7.00999 17.8343L0.656764 13.9758C0.293816 13.7554 -0.000191942 13.2333 9.40203e-08 12.8091L0.0022084 5.15442ZM1.34032 4.5328L7.66157 8.06233L13.6882 4.57898L7.6514 1.03419L1.34032 4.5328ZM0.932624 5.67513C0.932624 5.83144 0.830365 12.575 0.830365 12.575C0.8271 12.7868 0.971223 13.0471 1.15366 13.1572L7.06953 16.7274L7.12397 9.29434C7.12397 9.29434 0.932624 5.51871 0.932624 5.67513ZM8.24027 16.7113L14.0889 12.9992C14.3128 12.8571 14.4944 12.5273 14.4944 12.2619V5.6372L8.24027 9.23913V16.7113Z"
        fill="#707070"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.86869 9.39799C2.40954 9.66319 1.80078 9.46856 1.50908 8.96341C1.21747 8.45826 1.35334 7.83376 1.81249 7.56865C2.27175 7.30345 2.8805 7.49808 3.17211 8.00323C3.46381 8.50838 3.32795 9.13288 2.86869 9.39799ZM5.94127 11.4144C5.48211 11.6796 4.87336 11.4849 4.58166 10.9798C4.29005 10.4746 4.42592 9.85014 4.88507 9.58503C5.34433 9.31983 5.95308 9.51446 6.24469 10.0196C6.53639 10.5248 6.40053 11.1493 5.94127 11.4144ZM2.86869 12.9507C2.40954 13.2159 1.80078 13.0212 1.50908 12.5161C1.21747 12.0109 1.35334 11.3864 1.81249 11.1213C2.27175 10.8561 2.8805 11.0507 3.17211 11.5559C3.46381 12.061 3.32795 12.6856 2.86869 12.9507ZM5.94127 14.967C5.48211 15.2322 4.87336 15.0376 4.58166 14.5325C4.29005 14.0273 4.42592 13.4028 4.88507 13.1377C5.34433 12.8725 5.95308 13.0671 6.24469 13.5723C6.53639 14.0774 6.40053 14.7019 5.94127 14.967ZM13.4535 7.47263C12.9943 7.20743 12.3856 7.40206 12.0939 7.90721C11.8023 8.41236 11.9381 9.03686 12.3973 9.30197C12.8566 9.56717 13.4653 9.37254 13.757 8.86739C14.0487 8.36224 13.9128 7.73774 13.4535 7.47263ZM11.8944 10.2572C11.4352 9.99195 10.8265 10.1866 10.5348 10.6917C10.2432 11.1969 10.379 11.8214 10.8382 12.0865C11.2975 12.3517 11.9062 12.1571 12.1978 11.6519C12.4895 11.1468 12.3537 10.5223 11.8944 10.2572ZM10.2621 12.9457C9.80293 12.6805 9.19418 12.8751 8.90247 13.3802C8.61087 13.8854 8.74673 14.5099 9.20589 14.775C9.66514 15.0402 10.2739 14.8456 10.5655 14.3404C10.8572 13.8353 10.7213 13.2108 10.2621 12.9457ZM7.74065 3.32129C7.13084 3.32129 6.63644 3.79418 6.63644 4.37749C6.63644 4.9608 7.13084 5.43369 7.74065 5.43369C8.35046 5.43369 8.84486 4.9608 8.84486 4.37749C8.84486 3.79418 8.35046 3.32129 7.74065 3.32129Z"
        fill="#707070"
        {...path}
      />
    </svg>
  );
};

export default ShuffleIcon;
