import React, { useState } from 'react';
import { Input } from '../../../ui/Inputs';
import { useTranslation } from 'react-i18next';
import { useEditCRMUrlMutation } from '../../../../store/api';
import { Loading } from '../../../ui/Loading';
import { CRMModalContentProps } from './types';

const CRMModalContent: React.FC<CRMModalContentProps> = ({ project_id, url }) => {
  const { t } = useTranslation();
  const [editCRMUrl, { isLoading: editCRMUrlLoading }] = useEditCRMUrlMutation();
  const [CRMUrl, setCRMUrl] = useState(url ? url : '');

  const handleChangeCRMUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCRMUrl(value);
  };

  const handleSaveCRMUrl = () => {
    if (!!CRMUrl.trim() && CRMUrl !== url) editCRMUrl({ ID: project_id, crm_url: CRMUrl });
  };

  return (
    <div>
      <Input
        name="crm-url"
        value={CRMUrl}
        onChange={handleChangeCRMUrl}
        onBlur={handleSaveCRMUrl}
        placeholder={`${t('enterCRMUrl')}`}
      />
      {editCRMUrlLoading && <Loading absolute />}
    </div>
  );
};

export default CRMModalContent;
